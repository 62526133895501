import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { Depositos } from 'src/app/api/depositos';
import { Conciliacion, Estacionalidad, EstacionalidadDesgloce } from 'src/app/api/estacionalidad';
import { EstacionalidadRestcService } from 'src/app/client/estacionalidad/estacionalidad-restc.service';

@Injectable({
  providedIn: 'root'
})
export class EstacionalidadPsService extends BaseProcess<EstacionalidadRestcService>{

  client: EstacionalidadRestcService;

  constructor(httpClient: HttpClient, crest: EstacionalidadRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  porcentajeIva(): Observable<number> {
    return this.client.porcentajeIva();
  }

  desgloce(idEstacionalidad: number): Observable<EstacionalidadDesgloce> {
    return this.client.desgloce(idEstacionalidad);
  }

  conciliar(item: Conciliacion): Observable<Estacionalidad[]> {
    return this.client.conciliar(item);
  }

  actualizarEstacionalidades(items: Estacionalidad[]): Observable<Estacionalidad[]> {
    return this.client.actualizarEstacionalidades(items);
  }

}
