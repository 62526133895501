import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { MontoVirtualRestcService } from '../../client/monto-virtual/monto-virtual-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MontoVirtualPsService extends BaseProcess<MontoVirtualRestcService> {

  constructor(httpClient: HttpClient, crest: MontoVirtualRestcService) {
    super(httpClient, crest);
  }
}
