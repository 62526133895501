import { formatCurrency } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { EstacionalidadDesgloce } from 'src/app/api/estacionalidad';
import { EstacionalidadPsService } from 'src/app/procs/estacionalidad/estacionalidad-ps.service';
import { DistribucionExterna, DistribucionInterna, Reserva } from 'src/app/api/distribucion';
import { ColaboradorExternoPsService } from 'src/app/procs/colaborador/colaborador-externo-ps.service';
import { ColaboradorExterno } from 'src/app/api/colaborador-externo';
import { EmpleadoNombre } from 'src/app/api/cliente';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { DistribucionExternaRestcService } from 'src/app/client/distribucion/distribucion-externa-restc.service';
import { DistribucionInternaRestcService } from 'src/app/client/distribucion/distribucion-interna-restc.service';
import { TipoColaboradorExterno } from 'src/app/api/constantes/tipo-colaborador-externo';
import { DistribucionRestcService } from '../../../client/distribucion/distribucion-restc.service';
import { ReservaRestcService } from '../../../client/reserva/reserva-restc.service';

@Component({
  selector: 'axks-distribucion-registro',
  templateUrl: './distribucion-registro.component.html',
  styleUrls: ['./distribucion-registro.component.scss']
})
export class DistribucionRegistroComponent extends BaseView implements OnInit {

  @Input() distExtReg: DistribucionExterna[];
  @Input() distIntReg: DistribucionInterna[];
  @Input() idEstacionalidad: number;

  @Output() hideDialog = new EventEmitter<boolean>();

  distColabExt: DistribucionExterna[];
  distDerechPiso: DistribucionExterna[];

  profile = this.getProfile() as ProfileC;

  colabExternos: ColaboradorExterno[];
  colabDerechoPiso: ColaboradorExterno[];
  empleados: EmpleadoNombre[];

  desgloce: EstacionalidadDesgloce;
  @Input() showDialog = false as boolean;

  montoEstacionalidad: string;
  montoComision: string;
  montoImpuestos: string;
  montoFEE: string;
  montoDistribuir: string;
  montoReserva: string;

  mes: string;
  listaFechasPago: String[] = [];

  existeDistExt: boolean;
  existeDistInt: boolean;
  existePagosReserva: boolean;

  showDialogAdeudos: boolean = false;
  listaAdeudos: Reserva[];
  distExtParaReserva: DistribucionExterna;
  tipoColabParaReserva: number;
  tipoColaborador = TipoColaboradorExterno;
  adeudoSelect: Reserva;
  nuevaReserva: number;
  acumladoReserva: number = 0;
  avisoDist: boolean = false;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private client: EstacionalidadPsService,
    private clientColabExt: ColaboradorExternoPsService,
    private clientEmpleado: EmpleadoRestcService,
    private clientDistInt: DistribucionInternaRestcService,
    private clientDistExt: DistribucionExternaRestcService,
    private confirmationService: ConfirmationService,
    private clientDist: DistribucionRestcService,
    private clientReserva: ReservaRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    this.showProgress = true;

    // Desactivar al desplegar
    if (!this.showDialog) {
      this.showDialog = true;
    }

    if (!this.desgloce) {
      this.desgloce = {};
    }

    if (!this.distExtReg) {
      this.distExtReg = [];
    }

    this.initDesgloceEstacionalidad();
    this.initCatColabDerechoPiso();
    this.initCatColabExterno();
    this.initCatEmpleados();
    this.cargarAdeudos();

    setTimeout(() => {
      this.initPoblarDistribucion();
    }, 4000);
  }

  initPoblarDistribucion() {

    // Se pobla la distribucion interna en caso de existir una, sino simplemente se inicializa el arreglo que la contendra.
    let filtroDistInt = {
      idEstacionalidad: {
        id: this.idEstacionalidad
      }
    } as DistribucionInterna;
    this.clientDistInt.search(filtroDistInt).subscribe(
      (data) => {
        let datos = [] as DistribucionInterna[]
        datos = data;
        if (datos.length != 0) {
          this.distIntReg = data
          this.existeDistInt = true;
        }
        else {
          this.distIntReg = [];
          this.existeDistInt = false;
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'Error',
          summary: 'Error',
          detail: 'No se pudo obtener la distribucion interna de la estacionalidad'
        })
      }
    );

    // Se pobla la distribucion externa en caso de existir una, sino simplemente se inicializa el arreglo que la contendra.
    this.clientDistExt.porEstacionalidad(this.idEstacionalidad).subscribe(
      (data) => {
        this.distColabExt = [];
        this.distDerechPiso = [];
        let datos = [] as DistribucionExterna[];
        datos = data;
        if (datos.length != 0) {
          for (let datItem of datos) {
            datItem.esNuevo = false;
            if (datItem.idColabExterno.idTipoColabExterno.id == TipoColaboradorExterno.DERECHO_PISO) {
              this.distDerechPiso.push(datItem);
            }
            else {
              this.distColabExt.push(datItem);
            }
          }

          this.existeDistExt = true;
        }
        else {
          this.distColabExt = [];
          this.distDerechPiso = [];
          this.existeDistExt = false;
        }

        this.showProgress = false;
      },
      (error) => {
        this.showProgress = false;
      }
    );
  }

  formatoMoneda(montoEstacionalidad: number, montoComision: number, montoImpuestos: number, montoFEE: number, montoDistribuir: number, montoReserva: number) {
    this.montoEstacionalidad = formatCurrency(montoEstacionalidad, "es-MX", "$ ", "MXN");
    this.montoComision = formatCurrency(montoComision, "es-MX", "$ ", "MXN");
    this.montoImpuestos = formatCurrency(montoImpuestos, "es-MX", "$ ", "MXN");
    this.montoFEE = formatCurrency(montoFEE, "es-MX", "$ ", "MXN");
    this.montoDistribuir = formatCurrency(montoDistribuir, "es-MX", "$ ", "MXN");
    this.montoReserva = formatCurrency(montoReserva, "es-MX", "$ ", "MXN");
  }

  initCatColabExterno() {
    let filtroColab = {
      idTipoColabExterno: {
        id: TipoColaboradorExterno.COLABORADOR_EXTERNO
      }
    } as ColaboradorExterno;

    this.clientColabExt.find(filtroColab).subscribe(
      (data) => {
        this.colabExternos = data;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo encontrar los colaboradores externos', detail: '' })
      }
    )
  }

  initCatColabDerechoPiso() {
    let filtroColab = {
      idTipoColabExterno: {
        id: TipoColaboradorExterno.DERECHO_PISO
      }
    } as ColaboradorExterno;

    this.clientColabExt.find(filtroColab).subscribe(
      (data) => {
        this.colabDerechoPiso = data;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo encontrar los colaboradores externos', detail: '' });
      }
    )
  }

  initCatEmpleados() {
    this.clientEmpleado.empleadosByEmpresa(this.profile.empresa).subscribe(
      (data) => {
        this.empleados = data;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo encontrar el catalogo de empleados', detail: '' });
      }
    );
  }

  initDesgloceEstacionalidad() {
    this.client.desgloce(this.idEstacionalidad).subscribe(
      (data) => {
        this.desgloce = data;

        this.initFechasProgPago();

        this.formatoMoneda(this.desgloce.montoEstacionalidad,
          this.desgloce.montoComision,
          this.desgloce.montoImpuestos,
          this.desgloce.montoFEE,
          this.desgloce.montoDistribuir,
          this.desgloce.montoReserva);

        this.formatoMes(this.desgloce.mes);

        this.nuevaReserva = this.desgloce.montoReserva;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo encontrar el desgloce de la estacionalidad', detail: '' })
      }
    )
  }

  formatoMes(item: number): string {
    let mes: string;
    switch (item) {
      case 1: {
        return this.mes = 'Enero';
      }
      case 2: {
        return this.mes = 'Febrero';
      }
      case 3: {
        return this.mes = 'Marzo';
      }
      case 4: {
        return this.mes = 'Abril';
      }
      case 5: {
        return this.mes = 'Mayo';
      }
      case 6: {
        return this.mes = 'Junio';
      }
      case 7: {
        return this.mes = 'Julio';
      }
      case 8: {
        return this.mes = 'Agosto';
      }
      case 9: {
        return this.mes = 'Septiembre';
      }
      case 10: {
        return this.mes = 'Octubre';
      }
      case 11: {
        return this.mes = 'Noviembre';
      }
      case 12: {
        return this.mes = 'Diciembre';
      }
      default: {
        return this.mes = 'Error';
      }
    }
  }

  /**
   * Funcion para insertar en el arreglo de los items un nuevo elemento.
   */
  insertarDistColabExt() {

    this.distColabExt.push({
      esNuevo: true,
      idColabExterno: {},
      idEstacionalidad: {
        id: this.idEstacionalidad
      }
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitarDistColabExt(item: DistribucionExterna) {
    this.distColabExt.splice(this.distColabExt.indexOf(item), 1);
    this.cambiarValorReserva();
  }

  /**
  * Funcion para insertar en el arreglo de los items un nuevo elemento.
  */
  insertarDistDerechPiso() {

    this.distDerechPiso.push({
      esNuevo: true,
      idColabExterno: {},
      idEstacionalidad: {
        id: this.idEstacionalidad
      }
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitarDistDerechPiso(item: DistribucionExterna) {
    this.distDerechPiso.splice(this.distDerechPiso.indexOf(item), 1);
    this.cambiarValorReserva();
  }

  /**
   * Funcion para insertar en el arreglo de los items un nuevo elemento.
   */
  insertarDistInt() {

    this.distIntReg.push({
      idEstacionalidad: {
        id: this.idEstacionalidad
      }
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitarDistInt(item: DistribucionExterna) {
    this.distIntReg.splice(this.distIntReg.indexOf(item), 1);
  }

  /**
   * Funcion que manda a llamar el servicio de guardado de las distribuciones.
   * 
   */
  guardarDist() {
    this.distExtReg = [];

    let montoDerecPiso = 0 as number;
    let montoColabExt = 0 as number;
    let montoEmpleados = 0 as number;

    let faltaInformacion: boolean = false;

    // Se obtiene el monto total de los derecho de piso
    if (this.distDerechPiso.length >= 1) {
      for (let item of this.distDerechPiso) {
        if (!item.esReserva) {
          if (!item.idColabExterno.id && !item.monto && !item.fecPagoProg) {
            faltaInformacion = true;
          }
          montoDerecPiso += parseFloat(item.monto.toFixed(2));
        }
        else {
          if (!item.idColabExterno.id && !item.monto) {
            faltaInformacion = true;
          }
          montoDerecPiso += parseFloat(item.monto.toFixed(2));
        }
      }
    }
    montoDerecPiso = parseFloat(montoDerecPiso.toFixed(2));

    // Se obtiene el monto total de los colaboradores externos
    if (this.distColabExt.length >= 1) {
      for (let item of this.distColabExt) {
        if (!item.esReserva) {
          if (!item.idColabExterno.id && !item.monto && !item.fecPagoProg) {
            faltaInformacion = true;
          }
          montoColabExt += parseFloat(item.monto.toFixed(2));
        }
        else {
          if (!item.idColabExterno.id && !item.monto) {
            faltaInformacion = true;
          }
          montoColabExt += parseFloat(item.monto.toFixed(2));
        }
      }
    }
    montoColabExt = parseFloat(montoColabExt.toFixed(2));

    // Se obtiene el monto total de la distribución interna
    if (this.distIntReg.length >= 1) {
      for (let item of this.distIntReg) {
        if (!item.idEmpleado && !item.monto) {
          faltaInformacion = true;
        }
        montoEmpleados += parseFloat(item.monto.toFixed(2));
      }
    }
    montoEmpleados = parseFloat(montoEmpleados.toFixed(2));

    if (faltaInformacion) {
      return this.warningMessage("Información faltante", "Favor de revisar que no falten datos");
    }

    let montoSumado = montoDerecPiso + montoColabExt + montoEmpleados;
    montoSumado = parseFloat(montoSumado.toFixed(2));

    if (parseFloat(this.desgloce.montoDistribuir.toFixed(2)) == montoSumado) {

      let reservasNoDesbordadas = true;

      for (let item of this.distDerechPiso) {
        this.distExtReg.push(item);
      }

      for (let item of this.distColabExt) {
        this.distExtReg.push(item);
      }

      // Se valida que los montos que son reserva no se pasen de cada una de las deudas
      for (let itemD of this.listaAdeudos) {
        let sumado = 0.0;
        for (let itemDE of this.distExtReg) {
          if (itemDE.idReserva == itemD.id) {
            sumado += parseFloat(itemDE.monto.toFixed(2));
          }
        }

        if (parseFloat(sumado.toFixed(2)) > parseFloat(itemD.monto.toFixed(2))) {
          reservasNoDesbordadas = false;
        }
      }

      if (reservasNoDesbordadas == true) {
        this.showProgress = true;

        // Se manda a llamar el servicio para registar las distribuciones externas
        this.clientDistExt.registrarDistribucion(this.distExtReg, this.idEstacionalidad).subscribe(

          // En caso de que el servicio proceda correctamente
          (data) => {
            this.messageService.add({ severity: 'success', summary: 'Distribucion externa guardada correctamente.', detail: '' });
          },
          // En caso de que el servicio mande algun error de respuesta
          (error) => {
            this.messageService.add({ key: "msgError", severity: 'error', summary: 'No se pudo registrar la distribucion externa.', detail: '' });
            this.showProgress = false;
          }
        );

        // Se manda a llamar el servicio para registar las distribuciones internas
        this.clientDistInt.registrarDistribucion(this.distIntReg, this.idEstacionalidad).subscribe(

          // En caso de que el servicio proceda correctamente
          (data) => {
            this.messageService.add({ severity: 'success', summary: 'Distribucion interna guardada correctamente.', detail: '' });
            this.hideDialog.emit(false);
            this.showProgress = false;
          },
          // En caso de que el servicio mande algun error de respuesta
          (error) => {
            this.messageService.add({ key: "msgError", severity: 'error', summary: 'No se pudo registrar la distribucion interna.', detail: '' });
            this.showProgress = false;
          }
        );
      }
      else {
        this.warningMessage("Montos de deuda", "Los montos destinados para las reservas de adeudo se pasan del monto de alguna deuda, favor de revisar la distribución");
      }
    }
    else {
      this.messageService.add({
        key: 'msgSuma',
        life: 10000,
        severity: 'warn',
        summary: 'Atencion.',
        detail: 'La suma de los montos distribuidos no coincide con el monto disponible para distribuir, favor de verificar.'
      });
    }
  }

  ocultarDialog() {
    this.hideDialog.emit(false);
  }

  initFechasProgPago() {
    let fecIni = new Date();
    fecIni.setDate(1);
    fecIni.setMonth(this.desgloce.mes - 1); // 0 - Enero : 11 - Diciembre
    fecIni.setFullYear(this.desgloce.anio);
    fecIni.setMonth(fecIni.getMonth() + 1, 0);
    fecIni.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

    let fecFin = new Date(fecIni);
    fecFin.setMonth(fecFin.getMonth() + 6, 1);
    fecFin.setDate(fecFin.getDate() - 1);

    while (fecIni < fecFin) {
      if (fecIni.getDate() <= 15) {
        this.listaFechasPago.push(fecIni.getFullYear() + "-" + ((fecIni.getMonth() + 1) < 10 ? "0" + (fecIni.getMonth() + 1) : (fecIni.getMonth() + 1)) + "-" + "15");
        fecIni.setDate(1);
        fecIni.setMonth(fecIni.getMonth() + 1);
        fecIni.setDate(fecIni.getDate() - 1);
      }
      else {
        this.listaFechasPago.push(fecIni.getFullYear() + "-" + ((fecIni.getMonth() + 1) < 10 ? "0" + (fecIni.getMonth() + 1) : (fecIni.getMonth() + 1)) + "-" + fecIni.getDate());
        fecIni.setDate(1);
        fecIni.setMonth(fecIni.getMonth() + 1);
        fecIni.setDate(15);
      }
    }

  }

  borrarDistribuciones() {

    this.confirmationService.confirm({

      header: 'Confirmar operación',
      message: '¿Estas seguro de querer borrar toda la distribución de la estacionalidad?' +
        ' Una vez realizado no se podra deshacer la operación y se tendra que volver a generar toda la distribución.',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Borrar',
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
      rejectLabel: 'Cancelar',
      rejectIcon: 'none',
      accept: () => {
        if (this.idEstacionalidad != null || this.idEstacionalidad != undefined) {

          this.showProgress = true;

          this.clientDist.deleteByIdEstacionalidad(this.idEstacionalidad).subscribe(
            (data) => {
              this.showProgress = false;
              this.successMessage(data.message, data.details);
              setTimeout(() => {
                this.initPoblarDistribucion();
              }, 100);
            },
            (error) => {
              this.showProgress = false;
              this.errorMessage(error.error.message, error.error.details);
            }
          );
        }
        else {
          this.errorMessage("Error", "No hay referencia a la estacionalidad para borrar la distribución");
        }
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;
        }
      }
    });
  }

  mostrarAdeudos(event: any, item: DistribucionExterna, tipoColaborador: number) {
    this.adeudoSelect = {};
    if (event.checked == true) {
      this.showDialogAdeudos = true;
      this.distExtParaReserva = item;
      this.tipoColabParaReserva = tipoColaborador;
    }
    else {
      /* this.acumladoReserva -= item.monto;
      this.nuevaReserva += item.monto; */
      item.idReserva = null;
    }
  }

  cargarAdeudos() {
    this.listaAdeudos = [];
    this.clientReserva.adeudosPorLider(this.profile.idEmpleado).subscribe(
      (data) => {
        this.listaAdeudos = data;
      },
      (error) => {
        this.errorMessage("Error", error.error.message);
      }
    );
  }

  seleccionarAdeudo(event) {

    let montoDeuda = parseFloat(event.data.montoDeuda.toFixed(2));

    if (this.distExtParaReserva.monto > montoDeuda) {
      this.messageService.add({
        summary: "Atención",
        detail: "El monto que desea aplicar a la deuda sobrepasa el monto que se debe. Debe ser menor o igual a lo que aun se debe",
        severity: "info",
        life: 5000
      });
      return;
    }

    this.distExtParaReserva.idReserva = event.data.id;

    if (this.tipoColabParaReserva == TipoColaboradorExterno.DERECHO_PISO) {
      this.distDerechPiso.indexOf(this.distExtParaReserva);
    }
    else {
      this.distColabExt.indexOf(this.distExtParaReserva);
    }

    this.showDialogAdeudos = false;

    this.cambiarValorReserva();
  }

  ocultarDialogAdeudos() {
    this.showDialogAdeudos = false;
    if (!this.adeudoSelect.id && !this.distExtParaReserva.idReserva) {
      this.distExtParaReserva.esReserva = false;
      if (this.tipoColabParaReserva == TipoColaboradorExterno.DERECHO_PISO) {
        this.distDerechPiso.indexOf(this.distExtParaReserva);
      }
      else {
        this.distColabExt.indexOf(this.distExtParaReserva);
      }
    }
  }

  modificarReserva(event: any, esReserva: boolean) {

  }

  cambiarValorReserva() {
    this.acumladoReserva = 0;
    for (let itemDP of this.distDerechPiso) {
      if (itemDP.esReserva == true && itemDP.esNuevo == true) {
        this.acumladoReserva += itemDP.monto;
      }
    }

    for (let itemDP of this.distColabExt) {
      if (itemDP.esReserva == true && itemDP.esNuevo == true) {
        this.acumladoReserva += itemDP.monto;
      }
    }

    this.montoReserva = formatCurrency(this.desgloce.montoReserva - this.acumladoReserva, "es-MX", "$ ", "MXN");
  }
}
