import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DistribucionRestcService } from '../../../client/distribucion/distribucion-restc.service';
import { DistribucionExterna, FiltroPagoTerceros, EstadoPagoTerceros } from '../../../api/distribucion';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { Lider } from 'src/app/api/lider';
import { EstatusPagoTerceros } from 'src/app/api/constantes/estatus-pago-terceros';

@Component({
  selector: 'axks-pago-a-terceros',
  templateUrl: './pago-a-terceros.component.html',
  styleUrls: ['./pago-a-terceros.component.scss']
})
export class PagoATercerosComponent extends BaseView implements OnInit {

  filtro: FiltroPagoTerceros;

  fechaInicial: string;
  fechaFinal: string;
  yearRange: string;

  items: DistribucionExterna[];
  profile = this.getProfile() as ProfileC;

  showDialog: boolean;
  referenciaPago: string;
  itemSelect: DistribucionExterna;
  lideres: Lider[] = [];
  estadosPago: EstadoPagoTerceros[] = [];
  sumadoTotal: number;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientDist: DistribucionRestcService,
    private clientEmpleado: EmpleadoRestcService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.filtro) {
      this.filtro = {};
    }
    this.initYearRange();
    this.initCatLideres();
    this.initEstadosPago();
    this.configFechaInicio(new Date());
    this.configFechaFin(new Date());
    setTimeout(() => {
      this.obtenerPagoTerceros();
    }, 500);

    this.showDialog = false;
    this.sumadoTotal = 0.00;
  }

  initCatLideres() {
    this.clientEmpleado.catalogoLideres(this.profile.empresa).subscribe(
      (data) => {
        this.lideres = data;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error Catalogo Lideres',
          detail: 'No se pudo obtener el catalogo de lideres',
          severity: 'error'
        });
      }
    );
  }

  initEstadosPago() {
    this.estadosPago = [
      { nombreEstadoPago: 'Pagado', idEstadoPago: EstatusPagoTerceros.PAGADO },
      { nombreEstadoPago: 'Por pagar', idEstadoPago: EstatusPagoTerceros.POR_PAGAR }
    ];
  }

  obtenerPagoTerceros() {
    if (this.filtro.fechaInicio && this.filtro.fechaFin) {
      if (this.filtro.fechaInicio < this.filtro.fechaFin) {
        this.sumadoTotal = 0.00;
        this.showProgress = true;
        this.clientDist.obtenerPagoTerceros(this.filtro).subscribe(
          (data) => {
            this.items = data;
            if (this.items.length == 0) {
              this.infoMessage("Sin registros", "No hay pagos a terceros disponibles para este periodo de tiempo");
              this.sumadoTotal = 0.00;
            }
            else {
              this.items.forEach(item => {
                this.sumadoTotal += item.monto
              });
            }
            this.showProgress = false;
          },
          (error) => {
            this.errorMessage("Error", "No se pudo consultar los pagos a terceros.");
            this.showProgress = false;
          }
        );
      }
      else {
        this.warningMessage("Consulta invalida", "La fecha final debe ser posterior a la fecha final");
      }
    }
    else {
      this.infoMessage("Falta de información", "No se ha seleccionado un periodo de tiempo para consultar");
    }
  }

  initYearRange() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 30) + ':' + (year + 5);
  }

  /**
  * Metodo para configurar las fecha inicial.
  */
  configFechaInicio(event) {
    // Se genera la fecha inicial del evento recuperado del componente.
    let fecIni = new Date(event);
    fecIni.setDate(1);

    // Se recuperan las fechas con formato
    this.filtro.fechaInicio = formatDate(fecIni, 'yyyy-MM-dd', 'es-MX', 'America/Mexico_City');
  }

  /**
   * Metodo para configurar la fecha final.
   */
  configFechaFin(event) {
    let fecFin = new Date(event);
    fecFin.setDate(1);
    fecFin.setMonth(fecFin.getMonth() + 1);
    fecFin.setDate(fecFin.getDate() - 1);

    // Se recuperan las fechas con formato
    this.filtro.fechaFin = formatDate(fecFin, 'yyyy-MM-dd', 'es-MX', 'America/Mexico_City');
  }

  aplicarPago(idDistExterna: number) {
    if (this.referenciaPago && this.referenciaPago.trim() != "") {
      this.showProgress = true;
      this.clientDist.aplicarPagoTercero(idDistExterna, this.profile.idEmpleado, this.referenciaPago).subscribe(
        (data) => {
          this.showProgress = false;
          this.showDialog = false;
          this.referenciaPago = null;
          this.successMessage("Pago aplicado", "Se aplico el pago correctamente");
          setTimeout(() => {
            this.obtenerPagoTerceros();
          }, 100);
        },
        (error) => {
          this.errorMessage("Error", "No se pudo aplicar el pago");
          this.showProgress = false;
        }
      );
    }
    else {
      this.infoMessage("Información faltante", "Favor de ingresar una referencia de pago valida");
    }
  }

  mostrarDialog(item: DistribucionExterna) {
    this.itemSelect = item;
    this.showDialog = true;
  }

}
