import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { EmpleadoNombre } from 'src/app/api/cliente';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';
import { Lider } from '../../api/lider';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoPsService extends BaseProcess<EmpleadoRestcService>{

  client: EmpleadoRestcService;

  constructor(httpClient: HttpClient, crest: EmpleadoRestcService) { 
    super(httpClient, crest);

    this.client = crest;
  }

  empleadosContratoActivo(idEmpresa: number) : Observable<EmpleadoNombre[]>{
    return this.client.empleadosContratoActivo(idEmpresa);
  }

  catalogoLideres(idEmpresa: number): Observable<Lider[]>{
    return this.client.catalogoLideres(idEmpresa);
  }
}
