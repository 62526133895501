<div class="contenedor-progress-spinner" *ngIf="showProgress">
    <p-progressSpinner strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="!showProgress">

    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <br>
            <span class="p-float-label">
                <p-dropdown inputId="fl-cliente" [(ngModel)]="item.idEmpresaCliente.id" [autoDisplayFirst]="false"
                    [options]="catEmpClientes" filterBy="nombre" [filter]=true optionLabel="nombre" optionValue="id"
                    showClear="true" [style]="{'width':'95%'}" (onChange)="guardarIdEmpresa($event)"
                    [baseZIndex]="20000" appendTo="body"></p-dropdown>
                <label for="fl-cliente">Empresa Cliente</label>
            </span>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <br>
            <br>
            <span class="p-float-label">
                <p-dropdown inputId="fl-cliente" [(ngModel)]="clienteModel.id" [autoDisplayFirst]="false"
                    [options]="catClientes" filterBy="nombre" [filter]=true optionLabel="nombre" optionValue="id"
                    [style]="{'width':'95%'}" [baseZIndex]="20000" appendTo="body">
                </p-dropdown>
                <label for="fl-cliente">Cliente solicitante</label>
            </span>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
            [style]="{'justify-content': 'right', 'display': 'flex', 'margin-top': '50px'}">
            <br>
            <br>
            <p-button (onClick)="editarCliente()" [loading]="isLoading" label="Editar cliente" icon="pi pi-save">
            </p-button>
        </div>
    </div>

    <p-toast key="msgErrorEmp"></p-toast>
    <p-toast key="msgErrorClient"></p-toast>
</ng-container>