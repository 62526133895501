import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { Anexo } from 'src/app/api/anexo';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { EmpleadoNombre } from 'src/app/api/cliente';
import { AnexoPsService } from 'src/app/procs/anexo/anexo-ps.service';
import { Participante } from 'src/app/api/participante';
import { ParticipantePsService } from '../../../procs/participante/participante-ps.service';

@Component({
  selector: 'axks-edit-participantes-form',
  templateUrl: './edit-participantes-form.component.html',
  styleUrls: ['./edit-participantes-form.component.scss']
})
export class EditParticipantesFormComponent extends BaseView implements OnInit {

  @Input() idAnexo: number;
  @Input() profile: ProfileC;

  loadAnexo: boolean = false;
  item: Anexo;
  catEmpleados: EmpleadoNombre[];
  isLoading: boolean;

  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() recargarDetalle = new EventEmitter<boolean>();

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoPsService,
    private clientAnexo: AnexoPsService,
    private clientPartic: ParticipantePsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        estacionalidades: [],
        participantes: [],
        idCliente: {},
        idEmpresaCliente: {},
        idLiderReg: {},
        estatusAnexo: {}
      };
    }

    if (!this.profile) {
      this.profile = this.getProfile();
    }

    this.initCatEmpleados();
    this.initDetalleAnexo();
  }

  initCatEmpleados() {
    this.showProgress = true;
    this.clientEmp.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data
        this.showProgress = false;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error catalogo empleados',
          detail: 'No se pudo obtener el catalogo de empleados con contrato activo',
          severity: 'error'
        });
        this.showProgress = false;
      }
    );
  }

  initDetalleAnexo() {
    this.loadAnexo = false;
    this.clientAnexo.detail(this.idAnexo).subscribe(
      (data) => {
        this.item = data as Anexo;
        this.loadAnexo = true;
      },
      (error) => {
        this.loadAnexo = true;
        this.errorMessage("Error", "No se pudo recuperar los datos");
      }
    );
  }

  /**
   * Funcion para insertar en el arreglo de los items un nuevo elemento.
   */
  insertar() {

    this.item.participantes.push({
      id: {
        idAnexo: this.idAnexo,
        idEmpleado: {}
      }
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitar(item: Participante) {
    this.item.participantes.splice(this.item.participantes.indexOf(item), 1);
  }

  /**
   * Funcion para actualizar los participantes.
   */
  guardarParticipantes() {
    this.isLoading = true;
    this.clientPartic.actualizarParticipantes(this.item.participantes, this.profile.idEmpleado).subscribe(
      (data) => {
        this.messageService.add({ severity: 'success', summary: 'Información actualizada.', detail: 'Participantes actualizados correctamente' });
        this.isLoading = false;
        this.hideDialog.emit(false);
        this.recargarDetalle.emit(true);
      },
      (error) => {
        this.messageService.add({ key: "msgError", severity: 'error', summary: 'Error de actualización', detail: 'No se pudo editar los participantes' });
        this.isLoading = false;
      }
    );
  }

}
