import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DistribucionInterna } from 'src/app/api/distribucion';

@Injectable({
  providedIn: 'root'
})
export class DistribucionInternaRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msDistribucionInt;
    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }
  processBody(res: any) {
    return res || {};
  }

  registrarDistribucion(items: DistribucionInterna[], idEstacionalidad: number): Observable<DistribucionInterna[]> {
    let path = this.getBaseEndpoint();

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("idEstacionalidad", idEstacionalidad.toString());

    return this.httpClient.post(path, items, { headers, params }) as Observable<DistribucionInterna[]>;
  }

}
