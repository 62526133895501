import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { GraficaPsService } from '../../../procs/grafica/grafica-ps.service';
import { ParticipacionDetalle } from '../../../api/grafica';
import { DistMontoVirtualPsService } from '../../../procs/dist-monto-virtual/dist-monto-virtual-ps.service';
import { DistMontoVirtual } from '../../../api/monto-virtual';

@Component({
  selector: 'axks-dialog-desgloce-partic',
  templateUrl: './dialog-desgloce-partic.component.html',
  styleUrls: ['./dialog-desgloce-partic.component.scss']
})
export class DialogDesgloceParticComponent extends BaseView implements OnInit, OnChanges {

  @Input() showDialog: boolean;
  @Input() idEmpleado: number;
  @Input() mes: number;
  @Input() anio: number;

  @Output() hideDialog = new EventEmitter<boolean>();

  items: ParticipacionDetalle[];
  itemsVirtuales: DistMontoVirtual[];
  showAnexos: boolean = false;
  showMontosVirtuales: boolean = false;
  loadAnexos: boolean = false;
  loadVirtuales: boolean = false;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientGrafica: GraficaPsService,
    private clientDistMontoVirtual: DistMontoVirtualPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showDialog == true) {
      this.getDetalleParticipaciones();
      this.getDetalleDistMontosVirtuales();
    }
  }

  ngOnInit(): void {
    if (!this.items) {
      this.items = [
        {
          anexo: {
            estacionalidades: [],
            estatusAnexo: {},
            idCliente: {},
            idEmpresaCliente: {},
            idLiderReg: {},
            participantes: []
          },
          empleado: {},
          participacion: {}
        }
      ];
    }

    if (!this.itemsVirtuales) {
      this.itemsVirtuales = [
        {
          idMontoVirtual: {}
        }
      ];
    }
  }

  getDetalleParticipaciones() {
    this.loadAnexos = true;
    this.clientGrafica.getDetalleParticipaciones(this.idEmpleado, this.mes, this.anio).subscribe(
      (data) => {
        this.items = data;
        if (this.items.length > 0) {
          this.showAnexos = true;
        }
        else {
          this.showAnexos = false;
        }

        this.loadAnexos = false;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error detalle participaciones',
          detail: 'No se pudo obtener el detalle de las participaciones',
          severity: 'error'
        });

        this.loadAnexos = false;
      }
    );
  }

  getDetalleDistMontosVirtuales() {
    this.loadVirtuales = true;
    let filtro = {
      idEmpleado: this.idEmpleado,
      mes: this.mes,
      anio: this.anio
    } as DistMontoVirtual;
    this.clientDistMontoVirtual.find(filtro).subscribe(
      (data) => {
        this.itemsVirtuales = data;
        if (this.itemsVirtuales.length > 0) {
          for (let itemV of this.itemsVirtuales) {
            itemV.fecDist = itemV.mes + "/" + itemV.anio;
          }
          this.showMontosVirtuales = true;
        }
        else {
          this.showMontosVirtuales = false;
        }

        this.loadVirtuales = false;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error detalle participaciones',
          detail: 'No se pudo obtener el detalle de los montos virtuales.',
          severity: 'error'
        });

        this.loadVirtuales = false;
      }
    );
  }

  ocultarCard() {
    this.showAnexos = false;
    this.showMontosVirtuales = false;
    this.hideDialog.emit(false);
  }

}
