import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo, EstatusAnexo } from 'src/app/api/anexo';
import { Empresa } from 'src/app/api/cliente';
import { Estacionalidad, EstacionalidadReg, EstatusEstacionalidad } from 'src/app/api/estacionalidad';
import { AnexoPsService } from 'src/app/procs/anexo/anexo-ps.service';
import { EmpresaClientePsService } from 'src/app/procs/cliente/empresa/empresa-cliente-ps.service';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { EstacionalidadPsService } from 'src/app/procs/estacionalidad/estacionalidad-ps.service';
import { EstatusAnexoPsService } from 'src/app/procs/estatus-anexo/estatus-anexo-ps.service';
import { Lider } from '../../../api/lider';
import { EstatusEstacionalidadPsService } from '../../../procs/estatus-estacionalidad/estatus-estacionalidad-ps.service';
import { estatusEstacionalidad } from 'src/app/api/constantes/estatus-estacionalidad';

@Component({
  selector: 'axks-estacionalidad-gestion',
  templateUrl: './estacionalidad-gestion.component.html',
  styleUrls: ['./estacionalidad-gestion.component.scss']
})
export class EstacionalidadGestionComponent extends BaseMainComponent<EstacionalidadPsService> implements OnInit {

  catProyectos: Anexo[];
  catEmpresasClientes: Empresa[];
  catLideres: Lider[];
  catEstatusAnexo: EstatusAnexo[];
  catEstatusEstacionalidad: EstatusEstacionalidad[];

  sumadoSubTotal: number;
  sumadoTotal: number;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientEst: EstacionalidadPsService,
    private clientEstAnexos: EstatusAnexoPsService,
    private clientEmpCliente: EmpresaClientePsService,
    private clientEmpleado: EmpleadoPsService,
    private clientAnexo: AnexoPsService,
    private clientEstEstacionalidad: EstatusEstacionalidadPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.sumadoSubTotal = 0;
    this.sumadoTotal = 0;

    this.initCatProyectos();
    this.initCatEmpresasClientes();
    this.initCatLideres();
    this.initCatEstatusAnexo();
    this.initCatEstatusEstacionalidad();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        anexo: {
          idEmpresaCliente: {},
          idLiderReg: {},
          idCliente: {},
          estatusAnexo: {}
        }
      } as Estacionalidad,
      minimumItem: {
        anexo: {
          idEmpresaCliente: {},
          idLiderReg: {},
          idCliente: {},
          estatusAnexo: {}
        }
      } as Estacionalidad,
      registerItem: {
      } as EstacionalidadReg
    } as ComponentItem;
    return empty;
  }

  getService(): EstacionalidadPsService {
    return this.clientEst;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { field: 'id', header: 'Num. Estacionalidad', inAll: true },
      { field: 'anexo.nombre', header: 'Proyecto', inAll: true },
      { field: 'anexo.numAnexo', header: 'Num. Anexo', inAll: true },
      { field: 'anexo.cvPresupuestal', header: 'Clave presupuestal', inAll: true },
      { field: 'anexo.estatusAnexo.estatusAnexo', header: 'Estatus proyecto', inDetail: true },
      { field: 'anexo.idEmpresaCliente.nombre', header: 'Cliente', inAll: true },
      { field: 'anexo.idLiderReg.nombreCompleto', header: 'Lider', inDetail: true },
      { field: 'fechaEstacionalidad', header: 'Fecha de cobro', inDetail: true, formatFunction: this.formatoFecha },
      { field: 'subtotal', header: 'Monto', inAll: true, formatFunction: this.formatoMoneda },
      { field: 'porcIva', header: 'Porcentaje IVA', inDetail: true, formatFunction: this.formatoPorcentaje },
      { field: 'total', header: 'Total', inDetail: true, formatFunction: this.formatoMoneda },
      { field: 'estatusEstacionalidad', header: 'Estatus estacionalidad', inAll: true }
    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Estacionalidades';
  }

  cancel(event: any): void {
    this.sumadoSubTotal = 0.0;
    this.sumadoTotal = 0.0;
    super.cancel(event);
  }

  formatoFecha(value: any) {
    let pipe = new DatePipe('es-MX');
    return pipe.transform(value, "MMMM yyyy", 'America/Mexico_City');
  }

  formatoMoneda(value: number) {
    let pipe = new CurrencyPipe('es-MX');
    return pipe.transform(value, "MXN", "$");
  }

  formatoPorcentaje(value: number) {
    return value + "%";
  }

  initCatProyectos() {
    let filter = {} as Anexo;
    this.clientAnexo.find(filter).subscribe(
      (data) => {
        this.catProyectos = data;
      },
      (error) => {
        this.errorMessage("Error Proyectos", "No se pudo obtener la lista de proyectos");
      }
    );
  }

  initCatEmpresasClientes() {
    let filter = {} as Empresa;
    this.clientEmpCliente.find(filter).subscribe(
      (data) => {
        this.catEmpresasClientes = data;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error Empresas Clientes',
          detail: 'No se pudo obtener la lista de empresas clientes.',
          severity: 'error'
        });
      }
    );
  }

  initCatLideres() {
    this.clientEmpleado.catalogoLideres(this.profile.empresa).subscribe(
      (data) => {
        this.catLideres = data;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error Catalogo Lideres',
          detail: 'No se pudo obtener el catalogo de lideres',
          severity: 'error'
        });
      }
    );
  }

  initCatEstatusAnexo() {
    let filtro = {} as EstatusAnexo;
    this.clientEstAnexos.find(filtro).subscribe(
      (data) => {
        this.catEstatusAnexo = data;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo obtener el catalogo de estatus de anexo'
        })
      }
    );
  }

  initCatEstatusEstacionalidad() {
    this.catEstatusEstacionalidad = [];

    let filtro = {} as EstatusEstacionalidad;
    this.clientEstEstacionalidad.find(filtro).subscribe(
      (data) => {
        let items = data as EstatusEstacionalidad[];
        items.forEach(i => {
          switch (i.id) {
            case estatusEstacionalidad.POR_COBRAR:
            case estatusEstacionalidad.EN_PROCESO_DE_PAGO:
            case estatusEstacionalidad.CONCILIADO:
              this.catEstatusEstacionalidad.push(i);
              break;
          }
        })
      },
      (error) => {
        this.errorMessage("Error", "No se pubo otener el catalogo de estatus de estacionalidad");
      }
    );
  }

}
