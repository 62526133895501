import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo, EstatusAnexo } from 'src/app/api/anexo';
import { Cliente, ClienteCat, Empresa } from 'src/app/api/cliente';
import { Lider } from 'src/app/api/lider';
import { ClientePsService } from 'src/app/procs/cliente/cliente-ps.service';
import { EmpresaClientePsService } from 'src/app/procs/cliente/empresa/empresa-cliente-ps.service';
import { EstatusAnexoPsService } from 'src/app/procs/estatus-anexo/estatus-anexo-ps.service';

@Component({
  selector: 'axks-mis-anexos-form',
  templateUrl: './mis-anexos-form.component.html',
  styleUrls: ['./mis-anexos-form.component.scss']
})
export class MisAnexosFormComponent extends BaseView implements OnInit {

  @Input() item: Anexo = {
    estacionalidades: [],
    estatusAnexo: {},
    participantes: [],
    idEmpresaCliente: {},
    idLiderReg: {}
  };

  catClientes: ClienteCat[] = [];
  clientes: Cliente[];
  yearRange: any;
  catEstatusAnexo: EstatusAnexo[];
  catEmpresasClientes: Empresa[];
  catLideres: Lider[] = [];

  @Input() profile: ProfileC;

  op = OperationMode;
  catEstadosDistribucion = ["Sin distribuir", "Parcialmente distribuido", "Distribuido"];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientPs: ClientePsService,
    private clientEstAnexos: EstatusAnexoPsService,
    private clientEmpCliente: EmpresaClientePsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {
        estacionalidades: [],
        estatusAnexo: {}
      } as Anexo
    }

    if (!this.catClientes) {
      this.catClientes = [];
    }

    if (!this.catEstatusAnexo) {
      this.catEstatusAnexo = [];
    }

    this.initCatalogoClientes();
    this.initDates();
    this.initCatEstatusAnexo();
    this.initCatEmpresasClientes();
  }

  initCatEmpresasClientes() {
    let filter = {} as Empresa;
    this.clientEmpCliente.find(filter).subscribe(
      (data) => {
        this.catEmpresasClientes = data;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error Empresas Clientes',
          detail: 'No se pudo obtener la lista de empresas clientes.',
          severity: 'error'
        });
      }
    );
  }

  initCatalogoClientes() {
    let filter = {
      idEmpleado: {
        id: this.profile.idEmpleado
      }
    } as Cliente;
    this.clientPs.find(filter).subscribe(
      (data) => {
        this.clientes = data;
        for (let item of this.clientes) {
          this.catClientes.push({
            id: item.id,
            nombre: item.idPersona.nombre + " " + item.idPersona.apellidoPaterno + " " + item.idPersona.apellidoMaterno + " - " + item.idEmpresa.nombre
          })
        }
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo encontrar los clientes', detail: '' })
      }
    );
  }

  asignarIdCliente(event: any) {
    this.item.idCliente.id = event.value;
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 10) + ':' + (year + 10);
  }

  initCatEstatusAnexo() {
    let filtro = {} as EstatusAnexo;
    this.clientEstAnexos.find(filtro).subscribe(
      (data) => {
        this.catEstatusAnexo = data;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo obtener el catalogo de estatus de anexo'
        })
      }
    );
  }

}
