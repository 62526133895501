import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DistribucionExterna } from '../../api/distribucion';

@Injectable({
  providedIn: 'root'
})
export class DistribucionExternaRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msDistribucionExt;
    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }
  processBody(res: any) {
    return res || {};
  }

  registrarDistribucion(items: DistribucionExterna[], idEstacionalidad: number): Observable<DistribucionExterna[]> {
    let path = this.getBaseEndpoint();

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("idEstacionalidad", idEstacionalidad.toString());

    return this.httpClient.post(path, items, { headers, params }) as Observable<DistribucionExterna[]>;
  }

  porEstacionalidad(idEstacionalidad: number): Observable<DistribucionExterna[]> {
    let path = this.getBaseEndpoint() + "por-estacionalidad/" + idEstacionalidad;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<DistribucionExterna[]>;
  }

}
