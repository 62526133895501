import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { ClienteRestcService } from 'src/app/client/cliente/cliente-restc.service';

@Injectable({
  providedIn: 'root'
})
export class ClientePsService extends BaseProcess<ClienteRestcService>{

  constructor(protected httpClient: HttpClient, 
    crest: ClienteRestcService) { 
    super(httpClient, crest);
  }
}
