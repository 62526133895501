import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnexoComponent } from './view/anexo/anexo/anexo.component';
import { HomeComponent } from './view/home/home.component';
import { ColaboradorExternoComponent } from './view/colaborador/colaborador-externo/colaborador-externo/colaborador-externo.component';
import { EstacionalidadFormComponent } from './view/estacionalidad/estacionalidad-form/estacionalidad-form.component';
import { ConciliacionFormComponent, } from './view/conciliacion/conciliacion-form/conciliacion-form.component';
import { ReversadoComponent } from './view/conciliacion/reversado-conciliacion/reversado/reversado.component';
import { DistribucionRegistroComponent } from './view/distribucion/distribucion-registro/distribucion-registro.component';
import { GraficaFormComponent } from './view/grafica/grafica-form/grafica-form.component';
import { GraficaAdminComponent } from './view/grafica/grafica-administrativa/grafica-admin/grafica-admin.component';
import { BonoComponent } from './view/bono/bono/bono.component';
import { MontoVirtualRegComponent } from './view/monto-virtual/monto-virtual-reg/monto-virtual-reg.component';
import { PagoATercerosComponent } from './view/distribucion/pago-a-terceros/pago-a-terceros.component';
import { EstacionalidadGestionComponent } from './view/estacionalidad-gestion/estacionalidad-gestion/estacionalidad-gestion.component';
import { MisAnexosComponent } from './view/anexo/mis-anexos/mis-anexos.component';
import { GraficaLideresComponent } from './view/grafica/grafica-lideres/grafica-lideres/grafica-lideres.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'anexos', component: AnexoComponent },
  { path: 'mis-anexos', component: MisAnexosComponent },
  { path: 'estacionalidades', component: EstacionalidadGestionComponent },
  { path: 'colaborador-externo', component: ColaboradorExternoComponent },
  { path: 'estacionalidad', component: EstacionalidadFormComponent },
  { path: 'conciliacion', component: ConciliacionFormComponent },
  { path: 'reversado-conciliacion', component: ReversadoComponent },
  { path: 'distribucion', component: DistribucionRegistroComponent },
  { path: 'grafica-empleado', component: GraficaFormComponent },
  { path: 'grafica-admin', component: GraficaAdminComponent },
  { path: 'grafica-lider', component: GraficaLideresComponent },
  { path: 'bono', component: BonoComponent },
  { path: 'monto-virtual', component: MontoVirtualRegComponent },
  { path: 'pago-a-terceros', component: PagoATercerosComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
