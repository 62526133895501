import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { EstatusAnexoRestcService } from 'src/app/client/estatus-anexo/estatus-anexo-restc.service';

@Injectable({
  providedIn: 'root'
})
export class EstatusAnexoPsService extends BaseProcess<EstatusAnexoRestcService>{

  constructor(httpClient: HttpClient, crest: EstatusAnexoRestcService) { 
    super(httpClient, crest);
  }
}
