<!-- <p-progressSpinner *ngIf="showProgress || !loadContent"
    [style]="{width: '50px', height: '50px', 'z-index': '1000', 'position':'absolute'}"
    styleClass="axks-progress-spinner" strokeWidth="8">
</p-progressSpinner> -->

<div class="progress container" *ngIf="showProgress || !loadContent">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">

    <!-- {{item.item | json}} -->
    <!-- {{profile | json}} -->

    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar " [addNewButton]="enableRegisterButton"
        searchLabel="Buscar " [searchButton]="true" [sectionTitle]="getComponentTitle()">
    </axks-operations-bar>

    <div class="p-grid" *ngIf="showDetail()">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <axks-detail-tab class="axks-detail-tab-80" [data]="workItem()" [tabDefs]="tabDefs"
                [colsDefinition]="colDefinition" [detailFields]="detailFields">
            </axks-detail-tab>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-6">
            <br>
            <br>
            <axks-estacionalidad-form [showDialog]="showCard" [montoTotal]="workItem().monto" [idAnexo]="workItem().id"
                [profile]="profile" (hideDialog)="disableCardEst($event)" (recargarDetalle)="recargarDetalle($event)"
                [enableButtonGuardarEstac]="enableButtonFinalEstac">
            </axks-estacionalidad-form>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-6" *ngIf="showDialogDist">
            <br>
            <br>
            <axks-distribucion-registro [profile]="profile" [idEstacionalidad]="idEstacionalidad"
                (hideDialog)="disableDialogDist($event)">
            </axks-distribucion-registro>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-6" *ngIf="showDialogMain">
            <br>
            <br>
            <axks-dialog-main [showDialog]="showDialogMain" [numeroBloque]="numeroBloque" [item]="workItem()"
                [operationMode]="operationMode" [profile]="profile" (hideDialog)="disableDialogMain($event)"
                [soloEditaAnexo]="soloEditaAnexo" (recargarDetalle)="recargarDetalle($event)"
                [idAnexo]="getIdOfSelectedItem()">
            </axks-dialog-main>
        </div>
    </div>

    <axks-mis-anexos-form #cmpForm *ngIf="showForm()" [item]="workItem()" [profile]="profile" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-' + operationMode">
    </axks-mis-anexos-form>

    <axks-estacionalidad-form [showDialog]="showCardReg" [items]="workItem().estacionalidades"
        [montoTotal]="workItem().monto" [items]="workItem().estacionalidades" [profile]="profile"
        (hideDialog)="disableCardRegEst($event)" (recargarDetalle)="recargarDetalle($event)"
        (itemsOut)="llenarEstacReg($event)" [enableButtonAgregarEstac]="enableButtonFinalEstac">
    </axks-estacionalidad-form>

    <axks-participante-form [showDialog]="showDialogPartic" [items]="workItem().participantes"
        [enableButtonAgregarPartic]="enableButtonAgregarPartc" (hideDialog)="disableCardParticipantes($event)"
        (itemsOut)="llenarParticipantesReg($event)" [profile]="profile" [idAnexo]="workItem().id">
    </axks-participante-form>

    <axks-actions-bar (save)="guardar($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" [printButton]="false" [editButton]="false" [deleteButton]="false" saveLabel="Guardar"
        cancelLabel="Cancelar" cleanLabel="Limpiar" [extraButtons]="extraButtons">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>