import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Grafica, Meses } from '../../../api/grafica';
import { GraficaPsService } from '../../../procs/grafica/grafica-ps.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { EmpleadoPsService } from '../../../procs/empleado/empleado-ps.service';

@Component({
  selector: 'axks-grafica-form',
  templateUrl: './grafica-form.component.html',
  styleUrls: ['./grafica-form.component.scss']
})
export class GraficaFormComponent extends BaseView implements OnInit, OnChanges {

  @Input() item: Grafica;
  @Input() empleado: number;
  @Input() anio: number;
  @Input() modoAdmin: boolean = false;
  @Input() nombreEmpleado: string;

  mesPartic: number;
  anioPartic: number;
  showDetallePart: boolean;
  showData: boolean;
  showImage: boolean = false;

  selectorEstilo: string;
  selectorEstiloBono: string;
  cursorStyle: string;

  profile: ProfileC = this.getProfile();
  meses: Grafica[];
  siguienteAnio: number;
  rangeYears: any[];

  rojo: string = '#E53935';
  verde: string = '#239B56';

  colorTotalAnexos: string;
  colorPorCubrir: string;
  colorExcedente: string;

  colorFebreroQuin1: string;
  colorFebreroQuin2: string;
  colorFebreroSueldo: string;
  colorFebreroTotal: string;

  colorMarzoQuin1: string;
  colorMarzoQuin2: string;
  colorMarzoSueldo: string
  colorMarzoTotal: string;

  colorAbrilQuin1: string;
  colorAbrilQuin2: string;
  colorAbrilSueldo: string
  colorAbrilTotal: string;

  colorMayoQuin1: string;
  colorMayoQuin2: string;
  colorMayoSueldo: string;
  colorMayoTotal: string;

  colorJunioQuin1: string;
  colorJunioQuin2: string;
  colorJunioSueldo: string
  colorJunioTotal: string;

  colorJulioQuin1: string;
  colorJulioQuin2: string;
  colorJulioSueldo: string
  colorJulioTotal: string;

  colorAgostoQuin1: string;
  colorAgostoQuin2: string;
  colorAgostoSueldo: string;
  colorAgostoTotal: string;

  colorSeptiembreQuin1: string;
  colorSeptiembreQuin2: string;
  colorSeptiembreSueldo: string;
  colorSeptiembreTotal: string;

  colorOctubreQuin1: string;
  colorOctubreQuin2: string;
  colorOctubreSueldo: string;
  colorOctubreTotal: string;

  colorNoviembreQuin1: string;
  colorNoviembreQuin2: string;
  colorNoviembreSueldo: string;
  colorNoviembreTotal: string;

  colorDiciembreQuin1: string;
  colorDiciembreQuin2: string;
  colorDiciembreSueldo: string;
  colorDiciembreTotal: string;

  colorEneroQuin1: string;
  colorEneroQuin2: string;
  colorEneroSueldo: string;
  colorEneroTotal: string;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientGrafica: GraficaPsService,
    private clientEmp: EmpleadoPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.modoAdmin == true) {
      this.initCargaGrafica(this.empleado);
    }
    else {
      this.initCargaGrafica(this.profile.idEmpleado);
    }
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        quincena1: {},
        quincena2: {},
        sueldos: {},
        participaciones: {},
        totales: {}
      }
    }
    this.showData = false;
    this.anio = new Date().getFullYear();
    this.siguienteAnio = this.anio + 1;
    this.rangeYears = [];

    if (this.modoAdmin == true) {
      this.initCargaGrafica(this.empleado);
    }
    else {
      this.initCargaGrafica(this.profile.idEmpleado);
    }
    this.initRangeYears();

    this.selectorEstilo = "estilo-cursor-fuera-elemento";
    this.selectorEstiloBono = "estilo-cursor-fuera-label-bono";
    this.showDetallePart = false;
  }

  initRangeYears() {
    let currentYear = new Date().getFullYear();
    for (let itemYear = currentYear; itemYear > currentYear - 5; itemYear--) {
      this.rangeYears.push({ label: itemYear });
    }
  }

  initImagenInicial() {
    this.showImage = true;
    setTimeout(() => {
      this.showImage = false;
    }, 3000);
  }

  initCargaGrafica(idEmpleado: number) {
    this.showProgress = true;
    this.siguienteAnio = this.anio + 1;
    this.clientGrafica.getGrafica(idEmpleado, this.anio).subscribe(
      (data) => {
        this.item = data;
        this.meses = [
          {
            quincena1: data.quincena1,
            quincena2: data.quincena2,
            sueldos: data.sueldos,
            participaciones: data.participaciones,
            totales: data.totales
          }
        ];

        // Definimos color al monto Total Anexos
        if (this.item.totalAnexos <= 0) {
          this.colorTotalAnexos = this.rojo;
        }
        else {
          this.colorTotalAnexos = this.verde;
        }

        // Definimos color al monto Por Cubrir
        if (this.item.porCubrir > 0) {
          this.colorPorCubrir = this.rojo;
        }
        else {
          this.colorPorCubrir = this.verde;
        }

        // Definimos color al monto Excedente
        if (this.item.excedente > 0) {
          this.colorExcedente = this.verde;
        }
        else {
          this.colorExcedente = this.rojo;
        }

        for (let itemMes of this.meses) {
          // Febrero
          this.colorFebreroQuin1 = (itemMes.quincena1.febrero < 0 ? this.rojo : this.verde);
          this.colorFebreroQuin2 = (itemMes.quincena2.febrero < 0 ? this.rojo : this.verde);
          this.colorFebreroSueldo = (itemMes.sueldos.febrero < 0 ? this.rojo : this.verde);
          this.colorFebreroTotal = (itemMes.totales.febrero < 0 ? this.rojo : this.verde);

          // Marzo
          this.colorMarzoQuin1 = (itemMes.quincena1.marzo < 0 ? this.rojo : this.verde);
          this.colorMarzoQuin2 = (itemMes.quincena2.marzo < 0 ? this.rojo : this.verde);
          this.colorMarzoSueldo = (itemMes.sueldos.marzo < 0 ? this.rojo : this.verde);
          this.colorMarzoTotal = (itemMes.totales.marzo < 0 ? this.rojo : this.verde);

          // Abril
          this.colorAbrilQuin1 = (itemMes.quincena1.abril < 0 ? this.rojo : this.verde);
          this.colorAbrilQuin2 = (itemMes.quincena2.abril < 0 ? this.rojo : this.verde);
          this.colorAbrilSueldo = (itemMes.sueldos.abril < 0 ? this.rojo : this.verde);
          this.colorAbrilTotal = (itemMes.totales.abril < 0 ? this.rojo : this.verde);

          // Mayo
          this.colorMayoQuin1 = (itemMes.quincena1.mayo < 0 ? this.rojo : this.verde);
          this.colorMayoQuin2 = (itemMes.quincena2.mayo < 0 ? this.rojo : this.verde);
          this.colorMayoSueldo = (itemMes.sueldos.mayo < 0 ? this.rojo : this.verde);
          this.colorMayoTotal = (itemMes.totales.mayo < 0 ? this.rojo : this.verde);

          // Junio
          this.colorJunioQuin1 = (itemMes.quincena1.junio < 0 ? this.rojo : this.verde);
          this.colorJunioQuin2 = (itemMes.quincena2.junio < 0 ? this.rojo : this.verde);
          this.colorJunioSueldo = (itemMes.sueldos.junio < 0 ? this.rojo : this.verde);
          this.colorJunioTotal = (itemMes.totales.junio < 0 ? this.rojo : this.verde);

          // Julio
          this.colorJulioQuin1 = (itemMes.quincena1.julio < 0 ? this.rojo : this.verde);
          this.colorJulioQuin2 = (itemMes.quincena2.julio < 0 ? this.rojo : this.verde);
          this.colorJulioSueldo = (itemMes.sueldos.julio < 0 ? this.rojo : this.verde);
          this.colorJulioTotal = (itemMes.totales.julio < 0 ? this.rojo : this.verde);

          // Agosto
          this.colorAgostoQuin1 = (itemMes.quincena1.agosto < 0 ? this.rojo : this.verde);
          this.colorAgostoQuin2 = (itemMes.quincena2.agosto < 0 ? this.rojo : this.verde);
          this.colorAgostoSueldo = (itemMes.sueldos.agosto < 0 ? this.rojo : this.verde);
          this.colorAgostoTotal = (itemMes.totales.agosto < 0 ? this.rojo : this.verde);

          // Septiembre
          this.colorSeptiembreQuin1 = (itemMes.quincena1.septiembre < 0 ? this.rojo : this.verde);
          this.colorSeptiembreQuin2 = (itemMes.quincena2.septiembre < 0 ? this.rojo : this.verde);
          this.colorSeptiembreSueldo = (itemMes.sueldos.septiembre < 0 ? this.rojo : this.verde);
          this.colorSeptiembreTotal = (itemMes.totales.septiembre < 0 ? this.rojo : this.verde);

          // Octubre
          this.colorOctubreQuin1 = (itemMes.quincena1.octubre < 0 ? this.rojo : this.verde);
          this.colorOctubreQuin2 = (itemMes.quincena2.octubre < 0 ? this.rojo : this.verde);
          this.colorOctubreSueldo = (itemMes.sueldos.octubre < 0 ? this.rojo : this.verde);
          this.colorOctubreTotal = (itemMes.totales.octubre < 0 ? this.rojo : this.verde);

          // Noviembre
          this.colorNoviembreQuin1 = (itemMes.quincena1.noviembre < 0 ? this.rojo : this.verde);
          this.colorNoviembreQuin2 = (itemMes.quincena2.noviembre < 0 ? this.rojo : this.verde);
          this.colorNoviembreSueldo = (itemMes.sueldos.noviembre < 0 ? this.rojo : this.verde);
          this.colorNoviembreTotal = (itemMes.totales.noviembre < 0 ? this.rojo : this.verde);

          // Diciembre
          this.colorDiciembreQuin1 = (itemMes.quincena1.diciembre < 0 ? this.rojo : this.verde);
          this.colorDiciembreQuin2 = (itemMes.quincena2.diciembre < 0 ? this.rojo : this.verde);
          this.colorDiciembreSueldo = (itemMes.sueldos.diciembre < 0 ? this.rojo : this.verde);
          this.colorDiciembreTotal = (itemMes.totales.diciembre < 0 ? this.rojo : this.verde);

          // Enero
          this.colorEneroQuin1 = (itemMes.quincena1.enero < 0 ? this.rojo : this.verde);
          this.colorEneroQuin2 = (itemMes.quincena2.enero < 0 ? this.rojo : this.verde);
          this.colorEneroSueldo = (itemMes.sueldos.enero < 0 ? this.rojo : this.verde);
          this.colorEneroTotal = (itemMes.totales.enero < 0 ? this.rojo : this.verde);
        }

        this.showData = true;
        this.showProgress = false;
        // Mostramos animacion en caso de que tenga bono
        /* if (this.item.bono.montoBono > 0) {
            this.initImagenInicial();
        } */
      },
      (error) => {
        this.showData = true;
        this.showProgress = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error carga de grafica',
          detail: 'No se pudo obtener el detalle de la grafica'
        });
      }
    );
  }

  consultarGrafica() {
    if (this.modoAdmin == true) {
      this.initCargaGrafica(this.empleado);
    }
    else {
      this.initCargaGrafica(this.profile.idEmpleado);
    }
  }

  mostrarDesglocePart(mes) {
    this.mesPartic = mes;
    if (mes == 1) {
      this.anioPartic = this.siguienteAnio;
    }
    else {
      this.anioPartic = this.anio;
    }

    this.showDetallePart = true;
  }

  funcionMouseOver(event) {
    this.selectorEstilo = "estilo-cursor-sobre-elemento";
  }

  funcionMouseOut(event) {
    this.selectorEstilo = "estilo-cursor-fuera-elemento";
  }

  funcionMouseOverBono(event) {
    this.selectorEstiloBono = "estilo-cursor-sobre-label-bono";
  }

  funcionMouseOutBono(event) {
    this.selectorEstiloBono = "estilo-cursor-fuera-label-bono";
  }

}
