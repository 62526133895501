<!-- {{item | json}} -->
<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
        <span class="p-float-label">
            <p-dropdown inputId="dp-emp" [(ngModel)]="item.idEmpleado" [autoDisplayFirst]="false" [options]="empleados"
                filterBy="nombreCompleto" [filter]=true optionLabel="nombreEmpleado" optionValue="idEmpleado"
                [style]="{'width':'80%'}"></p-dropdown>
            <label for="dp-emp">Empleado</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
        <span class="p-float-label">
            <p-dropdown inputId="dp-year" [(ngModel)]="item.anio" [autoDisplayFirst]="false" [options]="rangeYears"
                optionLabel="label" optionValue="label" [style]="{'width':'80%'}"></p-dropdown>
            <label for="dp-year">Año</label>
        </span>
    </div>
</div>