import { Component, Input, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ColaboradorExterno, ColaboradorExternoReg } from 'src/app/api/colaborador-externo';
import { ColaboradorExternoPsService } from 'src/app/procs/colaborador/colaborador-externo-ps.service';

@Component({
  selector: 'axks-colaborador-externo',
  templateUrl: './colaborador-externo.component.html',
  styleUrls: ['./colaborador-externo.component.scss']
})
export class ColaboradorExternoComponent extends BaseMainComponent<ColaboradorExternoPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psClient: ColaboradorExternoPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);

  }

  profile = this.getProfile() as ProfileC;

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idTipoColabExterno : {}
      } as ColaboradorExterno,
      minimumItem: {
        idTipoColabExterno : {}
      } as ColaboradorExterno,
      registerItem: {
        idTipoColabExterno : {}
      } as ColaboradorExternoReg
    } as ComponentItem;

    return empty;
  }

  getService(): ColaboradorExternoPsService {
    return this.psClient;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colDefinition = [
      { field: 'id', header: 'Clave', inAll: true },
      { field: 'nombreCompleto', header: 'Nombre', inAll: true },
      { field: 'idTipoColabExterno.tipoColabExterno', header: 'Tipo de colaborador', inAll: true }
    ] as ColumnDefinition[];

    return colDefinition;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Colaborador Externo';
  }
}
