import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo } from 'src/app/api/anexo';

@Component({
  selector: 'axks-dialog-main',
  templateUrl: './dialog-main.component.html',
  styleUrls: ['./dialog-main.component.scss']
})
export class DialogMainComponent extends BaseView implements OnInit {

  @Input() profile : ProfileC;
  @Input() showDialog: boolean;
  @Input() operationMode: OperationMode;
  @Input() numeroBloque: number;
  @Input() item : any;
  @Input() soloEditaAnexo: boolean;
  @Input() idAnexo: number;

  mostrarFormEstacionalidades : boolean;
  mostrarBotonGuardar : boolean;

  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() recargarDetalle = new EventEmitter<boolean>();

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider) { 
    
      super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {};
    }
  }

  actualizarDetalle(event){
    this.recargarDetalle.emit(event);
  }

  ocultarCard(){
    this.hideDialog.emit(false);
  }

  mostrarBloqueEstac(event){
    this.mostrarFormEstacionalidades = event;
  }

  mostrarBotonGuardarF(event){
    this.mostrarBotonGuardar = event;
  }
}
