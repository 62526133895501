import { formatCurrency } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { GraficaAdmin } from 'src/app/api/grafica';
import { GraficaPsService } from 'src/app/procs/grafica/grafica-ps.service';
import { OrganigramaPsService } from 'src/app/procs/organigrama/organigrama-ps.service';

@Component({
  selector: 'axks-grafica-lideres',
  templateUrl: './grafica-lideres.component.html',
  styleUrls: ['./grafica-lideres.component.scss']
})
export class GraficaLideresComponent extends BaseMainComponent<GraficaPsService> implements OnInit {

  showGrafica: boolean;
  empleado: number;
  anio: number;
  nombreEmpleado: string;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientGrafica: GraficaPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    return {
      item: {
        anio: new Date().getFullYear(),
        quincena1: {},
        quincena2: {},
        sueldos: {},
        participaciones: {},
        totales: {},
        idLider: this.profile.idEmpleado
      } as GraficaAdmin,
      minimumItem: {
        anio: new Date().getFullYear(),
        quincena1: {},
        quincena2: {},
        sueldos: {},
        participaciones: {},
        totales: {},
        idLider: this.profile.idEmpleado
      } as GraficaAdmin,
      registerItem: {
        anio: new Date().getFullYear(),
        quincena1: {},
        quincena2: {},
        sueldos: {},
        participaciones: {},
        totales: {},
        idLider: this.profile.idEmpleado
      } as GraficaAdmin
    } as ComponentItem;
  }

  getService(): GraficaPsService {
    return this.clientGrafica;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { field: 'idEmpleado', header: 'ID Empleado', inResultList: true },
      { field: 'nombreCompleto', header: 'Empleado', inResultList: true },
      { field: 'metaAnual', header: 'Meta anual', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'metaActual', header: 'Meta actual', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'totalAnexos', header: 'Total anexos', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'porCubrir', header: 'Por Cubrir', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'excedente', header: 'Excedente', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'bono.porcBono', header: 'Porcentaje bono', inResultList:true, formatFunction: this.formatoPorcentaje }
    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.idEmpleado || this.item.item.idEmpleado;
  }
  
  getComponentTitle(): string {
    return 'Graficas de recursos'
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }

  formatoPorcentaje (val) {
    try {
        return Math.round(val) + "%";
    }catch (e){
        return val;
    }
  }

  rowSelect(event: any): void {
    this.empleado = event.idEmpleado;
    this.anio = event.anio;
    this.nombreEmpleado = event.nombreCompleto;
  }

}
