import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Conciliacion } from 'src/app/api/estacionalidad';

@Component({
  selector: 'axks-reversado-form',
  templateUrl: './reversado-form.component.html',
  styleUrls: ['./reversado-form.component.scss']
})
export class ReversadoFormComponent extends BaseView implements OnInit {

  @Input() item: Conciliacion;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        deposito: {},
        estacionalidades: []
      };
    }
  }


}
