import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { ColaboradorExternoRestcService } from 'src/app/client/colaborador/colaborador-externo-restc.service';

@Injectable({
  providedIn: 'root'
})
export class ColaboradorExternoPsService extends BaseProcess<ColaboradorExternoRestcService>{

  constructor(httpClient: HttpClient, crest: ColaboradorExternoRestcService) { 
    super(httpClient, crest);
  }
}
