<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('montoField')">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.monto" inputId="fl-monto" mode="currency" currency="MXN" locale="es-MX">
            </p-inputNumber>
            <label for="fl-monto">Monto</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('anioField')">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.anio" mode="decimal" inputId="fl-anio" [useGrouping]="false" maxlength="4">
            </p-inputNumber>
            <label for="fl-anio">Año</label>
        </span>
    </div>
</div>