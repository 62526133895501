<p-dialog header="Edicion Anexo" [(visible)]="showDialog" position="center" [modal]="true" [maximizable]="true"
    [style]="{width: '70vw'}" [contentStyleClass]="'dialog-content-height-' + numeroBloque" [baseZIndex]="10000"
    (onHide)="ocultarCard()" closeOnEscape="true">

    <div *ngIf="numeroBloque == 0"></div>

    <div *ngIf="numeroBloque == 1"
        [style]="{'padding-left':'30px', 'padding-right':'30px', 'padding-top':'30px', 'padding-bottom':'15px'}">
        <axks-edit-anexo-form [idAnexo]="idAnexo" [profile]="profile" (hideDialog)="ocultarCard()" [soloEdita]="soloEditaAnexo"
            (recargarDetalle)="actualizarDetalle($event)"></axks-edit-anexo-form>
    </div>

    <div *ngIf=" numeroBloque == 2"
        [style]="{'padding-left':'50px', 'padding-right':'50px', 'padding-top':'30px', 'padding-bottom':'15px'}">
        <axks-edit-cliente-form [idAnexo]="idAnexo" [profile]="profile" (hideDialog)="ocultarCard()"
            (recargarDetalle)="actualizarDetalle($event)"></axks-edit-cliente-form>
    </div>

    <div *ngIf="numeroBloque == 3"
        [style]="{'padding-left':'30px', 'padding-right':'30px', 'padding-top':'30px', 'padding-bottom':'15px'}">
        <axks-edit-estacionalidad-form [idAnexo]="idAnexo" [profile]="profile" (hideDialog)="ocultarCard()"
            (recargarDetalle)="actualizarDetalle($event)"></axks-edit-estacionalidad-form>
    </div>

    <div *ngIf="numeroBloque == 4"
        [style]="{'padding-left':'30px', 'padding-right':'30px', 'padding-top':'30px', 'padding-bottom':'15px'}">
        <axks-edit-participantes-form [idAnexo]="idAnexo" [profile]="profile" (hideDialog)="ocultarCard()"
            (recargarDetalle)="actualizarDetalle($event)"></axks-edit-participantes-form>
    </div>

</p-dialog>