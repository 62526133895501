import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, OperationScreenModeService, SessionClientProvider, ComponentItem, ColumnDefinition } from '@axks/components';
import { GraficaPsService } from '../../../../procs/grafica/grafica-ps.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { GraficaAdmin } from '../../../../api/grafica';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'axks-grafica-admin',
  templateUrl: './grafica-admin.component.html',
  styleUrls: ['./grafica-admin.component.scss']
})
export class GraficaAdminComponent extends BaseMainComponent<GraficaPsService> implements OnInit {

  showGrafica: boolean;
  empleado: number;
  anio: number;
  nombreEmpleado: string;

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientGrafica: GraficaPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.showGrafica = false;
  }

  initEmptyItem(): ComponentItem {
    return {
      item: {
        anio: new Date().getFullYear(),
        quincena1: {},
        quincena2: {},
        sueldos: {},
        participaciones: {},
        totales: {}
      } as GraficaAdmin,
      minimumItem: {
        anio: new Date().getFullYear(),
        quincena1: {},
        quincena2: {},
        sueldos: {},
        participaciones: {},
        totales: {}
      } as GraficaAdmin,
      registerItem: {
        anio: new Date().getFullYear(),
        quincena1: {},
        quincena2: {},
        sueldos: {},
        participaciones: {},
        totales: {}
      } as GraficaAdmin
    } as ComponentItem;
  }
  getService(): GraficaPsService {
    return this.clientGrafica;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { field: 'idEmpleado', header: 'ID Empleado', inResultList: true },
      { field: 'nombreCompleto', header: 'Empleado', inResultList: true },
      { field: 'metaAnual', header: 'Meta anual', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'metaActual', header: 'Meta actual', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'totalAnexos', header: 'Total anexos', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'porCubrir', header: 'Por Cubrir', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'excedente', header: 'Excedente', inResultList: true, formatFunction: this.formatoMoneda },
      { field: 'bono.porcBono', header: 'Porcentaje bono', inResultList:true, formatFunction: this.formatoPorcentaje }
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.idEmpleado || this.item.item.idEmpleado;
  }
  getComponentTitle(): string {
    return 'Graficas de rendimiento';
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }

  rowSelect(event: any): void {
    this.empleado = event.idEmpleado;
    this.anio = event.anio;
    this.nombreEmpleado = event.nombreCompleto;
  }

  // Agregado para mostrar el bono
  // jgodinez - 2022-08-01
  /**
   * Formatea el porcentaje
   * @param val Valor a formatear
   * @returns Valor formateado
   */
  formatoPorcentaje (val) {
    try {
        return Math.round(val) + "%";
    }catch (e){
        return val;
    }
  }
}
