import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { DepositosRestcService } from 'src/app/client/depositos/depositos-restc.service';

@Injectable({
  providedIn: 'root'
})
export class DepositosPsService extends BaseProcess<DepositosRestcService>{

  client: DepositosRestcService;

  constructor(httpClient: HttpClient, crest: DepositosRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  removeLogic(idDeposito: number, idUser: number): Observable<Message> {
    return this.client.removeLogic(idDeposito, idUser);
  }


}
