import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo, EstatusAnexo } from 'src/app/api/anexo';
import { EstatusAnexoPsService } from 'src/app/procs/estatus-anexo/estatus-anexo-ps.service';
import { Empresa } from '../../../api/cliente';
import { EmpresaClientePsService } from '../../../procs/cliente/empresa/empresa-cliente-ps.service';
import { EmpleadoPsService } from '../../../procs/empleado/empleado-ps.service';
import { Lider } from '../../../api/lider';

@Component({
  selector: 'axks-anexo-form',
  templateUrl: './anexo-form.component.html',
  styleUrls: ['./anexo-form.component.scss']
})
export class AnexoFormComponent extends BaseView implements OnInit {

  @Input() item: Anexo = {
    estacionalidades: [],
    estatusAnexo: {},
    participantes: [],
    idEmpresaCliente: {},
    idLiderReg: {}
  };

  yearRange: any;
  catEstatusAnexo: EstatusAnexo[];
  catEmpresasClientes: Empresa[];
  catLideres: Lider[] = [];

  @Input() profile: ProfileC;

  op = OperationMode;
  catEstadosDistribucion = ["Sin distribuir", "Parcialmente distribuido", "Distribuido"];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEstAnexos: EstatusAnexoPsService,
    private clientEmpCliente: EmpresaClientePsService,
    private clientEmpleado: EmpleadoPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {
        estacionalidades: [],
        estatusAnexo: {}
      } as Anexo
    }

    if (!this.catEstatusAnexo) {
      this.catEstatusAnexo = [];
    }

    this.initDates();
    this.initCatEstatusAnexo();
    this.initCatEmpresasClientes();
    this.initCatLideres();
  }

  initCatEmpresasClientes() {
    let filter = {} as Empresa;
    this.clientEmpCliente.find(filter).subscribe(
      (data) => {
        this.catEmpresasClientes = data;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error Empresas Clientes',
          detail: 'No se pudo obtener la lista de empresas clientes.',
          severity: 'error'
        });
      }
    );
  }

  initCatLideres() {
    this.clientEmpleado.catalogoLideres(this.profile.empresa).subscribe(
      (data) => {
        this.catLideres = data;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error Catalogo Lideres',
          detail: 'No se pudo obtener el catalogo de lideres',
          severity: 'error'
        });
      }
    );
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 10) + ':' + (year + 10);
  }

  initCatEstatusAnexo() {
    let filtro = {} as EstatusAnexo;
    this.clientEstAnexos.find(filtro).subscribe(
      (data) => {
        this.catEstatusAnexo = data;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo obtener el catalogo de estatus de anexo'
        })
      }
    );
  }

}
