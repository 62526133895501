import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeESMX from "@angular/common/locales/es-MX";
import { HttpClientModule } from '@angular/common/http';
import { CheckboxModule } from 'primeng/checkbox';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';

import { ActionsBarModule, CommonsModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';

import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressBarModule } from 'primeng/progressbar';
import { TreeTableModule } from 'primeng/treetable';

import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { HomeComponent } from './view/home/home.component';
import { AnexoFormComponent } from './view/anexo/anexo-form/anexo-form.component';
import { AnexoComponent } from './view/anexo/anexo/anexo.component';
import { RestClientModule } from '@axks/net';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { EstacionalidadFormComponent } from './view/estacionalidad/estacionalidad-form/estacionalidad-form.component';
import { ColaboradorExternoFormComponent } from './view/colaborador/colaborador-externo/colaborador-externo-form/colaborador-externo-form.component';
import { ColaboradorExternoComponent } from './view/colaborador/colaborador-externo/colaborador-externo/colaborador-externo.component';
import { DistribucionRegistroComponent } from './view/distribucion/distribucion-registro/distribucion-registro.component';
import { DialogMainComponent } from './view/dialog-edit-anexo/dialog-main/dialog-main.component';
import { EditAnexoFormComponent } from './view/dialog-edit-anexo/edit-anexo-form/edit-anexo-form.component';
import { EditClienteFormComponent } from './view/dialog-edit-anexo/edit-cliente-form/edit-cliente-form.component';
import { EditEstacionalidadFormComponent } from './view/dialog-edit-anexo/edit-estacionalidad-form/edit-estacionalidad-form.component';
import { ConciliacionFormComponent } from './view/conciliacion/conciliacion-form/conciliacion-form.component';
import { FormatoFechaPipe } from './pipe/formato-fecha.pipe';
import { FormatoMesPipe } from './pipe/formato-mes.pipe';
import { FormatoMesAnioPipe } from './pipe/formato-mes-anio.pipe';
import { ReversadoFormComponent } from './view/conciliacion/reversado-conciliacion/reversado-form/reversado-form.component';
import { ReversadoComponent } from './view/conciliacion/reversado-conciliacion/reversado/reversado.component';
import { ParticipanteFormComponent } from './view/participante/participante-form/participante-form.component';
import { GraficaFormComponent } from './view/grafica/grafica-form/grafica-form.component';
import { GraficaAdminFormComponent } from './view/grafica/grafica-administrativa/grafica-admin-form/grafica-admin-form.component';
import { GraficaAdminComponent } from './view/grafica/grafica-administrativa/grafica-admin/grafica-admin.component';
import { DialogDesgloceParticComponent } from './view/grafica/dialog-desgloce-partic/dialog-desgloce-partic.component';
import { BonoFormComponent } from './view/bono/bono-form/bono-form.component';
import { BonoComponent } from './view/bono/bono/bono.component';
import { MontoVirtualRegComponent } from './view/monto-virtual/monto-virtual-reg/monto-virtual-reg.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { PagoATercerosComponent } from './view/distribucion/pago-a-terceros/pago-a-terceros.component';
import { EditParticipantesFormComponent } from './view/dialog-edit-anexo/edit-participantes-form/edit-participantes-form.component';
import { EstacionalidadGestionFormComponent } from './view/estacionalidad-gestion/estacionalidad-gestion-form/estacionalidad-gestion-form.component';
import { EstacionalidadGestionComponent } from './view/estacionalidad-gestion/estacionalidad-gestion/estacionalidad-gestion.component';
import { EstacionalidadGestionTablaComponent } from './view/estacionalidad-gestion/estacionalidad-gestion-tabla/estacionalidad-gestion-tabla.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { MisAnexosFormComponent } from './view/anexo/mis-anexos-form/mis-anexos-form.component';
import { MisAnexosComponent } from './view/anexo/mis-anexos/mis-anexos.component';
import { GraficaLideresFormComponent } from './view/grafica/grafica-lideres/grafica-lideres-form/grafica-lideres-form.component';
import { GraficaLideresComponent } from './view/grafica/grafica-lideres/grafica-lideres/grafica-lideres.component';

registerLocaleData(localeESMX, "es-MX");

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AnexoFormComponent,
    AnexoComponent,
    EstacionalidadFormComponent,
    ColaboradorExternoFormComponent,
    ColaboradorExternoComponent,
    DistribucionRegistroComponent,
    DialogMainComponent,
    EditAnexoFormComponent,
    EditClienteFormComponent,
    EditEstacionalidadFormComponent,
    ConciliacionFormComponent,
    FormatoFechaPipe,
    FormatoMesPipe,
    FormatoMesAnioPipe,
    ReversadoFormComponent,
    ReversadoComponent,
    ParticipanteFormComponent,
    GraficaFormComponent,
    GraficaAdminFormComponent,
    GraficaAdminComponent,
    DialogDesgloceParticComponent,
    BonoFormComponent,
    BonoComponent,
    MontoVirtualRegComponent,
    PagoATercerosComponent,
    EditParticipantesFormComponent,
    EstacionalidadGestionFormComponent,
    EstacionalidadGestionComponent,
    EstacionalidadGestionTablaComponent,
    MisAnexosFormComponent,
    MisAnexosComponent,
    GraficaLideresFormComponent,
    GraficaLideresComponent
  ],
  imports: [
    DividerModule,
    OverlayPanelModule,
    TriStateCheckboxModule,
    TooltipModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    ScrollPanelModule,
    RadioButtonModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    InputTextModule,
    DropdownModule,
    HttpClientModule,
    TableModule,
    ToolbarModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    ToastModule,
    CardModule,
    PanelModule,
    InputNumberModule,
    CalendarModule,
    BrowserModule,
    RestClientModule,
    CommonsModule,
    ButtonModule,
    CheckboxModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ProgressBarModule,
    TreeTableModule,
    BrowserModule,
    ConfirmDialogModule
  ],
  providers: [
    MessageService, SessionClientProvider, ConfirmationService,
    { provide: "env", useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
