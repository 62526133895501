import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { ParticipanteRestcService } from '../../client/participante/participante-restc.service';
import { HttpClient } from '@angular/common/http';
import { Participante } from 'src/app/api/participante';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParticipantePsService extends BaseProcess<ParticipanteRestcService>{

  client: ParticipanteRestcService;

  constructor(httpClient: HttpClient, crest: ParticipanteRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  actualizarParticipantes(items: Participante[], idUserEmp: number) : Observable<Participante[]> {
    return this.client.actualizarParticipantes(items, idUserEmp);
  }
}
