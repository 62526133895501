import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseView, ColumnDefinition, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService, PrimeIcons } from 'primeng/api';
import { DepositosPsService } from 'src/app/procs/depositos/depositos-ps.service'
import { EstacionalidadPsService } from 'src/app/procs/estacionalidad/estacionalidad-ps.service';
import { Depositos } from 'src/app/api/depositos';
import { Conciliacion, Estacionalidad } from 'src/app/api/estacionalidad';
import { formatCurrency } from '@angular/common';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { estatusEstacionalidad } from 'src/app/api/constantes/estatus-estacionalidad';

@Component({
  selector: 'axks-conciliacion-form',
  templateUrl: './conciliacion-form.component.html',
  styleUrls: ['./conciliacion-form.component.scss']
})
export class ConciliacionFormComponent extends BaseView implements OnInit {

  @Input() depositos: Depositos[];
  @Input() estacionalidades: Estacionalidad[] = [
    {
      idFactura: {}
    }
  ];

  columnDep: any[];
  columnEst: any[];
  contenidoBalance: any[];

  loadDepositos: boolean = false;
  loadEstacionalidades: boolean = false;
  depositoSelect: Depositos;
  estacionalidadesSelect: Estacionalidad[];
  acumuladoEstacionalidades: number;
  profile: ProfileC;
  enableScrollEst: boolean = false;
  enableScrollDep: boolean = false;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientDepositos: DepositosPsService,
    private clientEstacionalidades: EstacionalidadPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.depositoSelect) {
      this.depositoSelect = {
        monto: 0
      };
    }

    if (!this.estacionalidadesSelect) {
      this.estacionalidadesSelect = [];
    }

    if (!this.acumuladoEstacionalidades) {
      this.acumuladoEstacionalidades = 0;
    }

    this.initDepositos();
    this.initEstacionalidades();
    this.initColumns();

    this.profile = super.getProfile();

  }

  initColumns() {
    this.columnDep = [
      { field: 'id', header: 'Clave', notFormat: true },
      { field: 'monto', header: 'Monto', isCurrency: true },
      { field: 'concepto', header: 'Concepto', notFormat: true },
      { field: 'fechaDeposito', header: 'Fecha de deposito', isDate: true }
    ];

    this.columnEst = [
      { field: 'id', header: 'Clave', notFormat: true },
      { field: 'numAnexo', header: 'Num. Anexo', isAnexo: true, sortName: 'anexo.numAnexo' },
      { field: 'serie', header: 'Serie', isFacturaNotFormat: true, sortName: 'idFactura.serie' },
      { field: 'numFactura', header: 'No. Factura', isFacturaNotFormat: true, sortName: 'idFactura.numFactura' },
      { field: 'subtotal', header: 'SubTotal', isCurrency: true },
      { field: 'porcIva', header: 'Porcentaje de IVA', isPercent: true },
      { field: 'total', header: 'Total', isCurrency: true },
      { field: 'fechaEst', header: 'Fecha de pago', isMesAnio: true },
      { field: 'estatusEstacionalidad', header: 'Estatus', notFormat: true }
    ];

    this.contenidoBalance = [
      { concepto: 'Deposito', monto: this.depositoSelect.monto, isDeposito: true },
      { concepto: 'Estacionalidades', monto: this.acumuladoEstacionalidades, isEstacionalidad: true }
    ];
  }

  initDepositos() {
    this.loadDepositos = false;

    let filtro: Depositos = {
      conciliado: false,
      regActivo: true,
      conciliable: true
    };
    this.clientDepositos.find(filtro).subscribe(
      (data) => {
        this.depositos = data;
        if (this.depositos.length > 8) {
          this.enableScrollDep = true;
        }
        this.loadDepositos = true;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo recuperar los depositos.', detail: '' });
        this.loadDepositos = true;
      }
    );
  }

  initEstacionalidades() {
    this.loadEstacionalidades = false;

    let filtro: Estacionalidad = {
      idEstatusEstacionalidad: estatusEstacionalidad.EN_PROCESO_DE_PAGO
    };
    this.clientEstacionalidades.find(filtro).subscribe(
      (data) => {
        this.estacionalidades = data;

        if (this.estacionalidades.length > 7) {
          this.enableScrollEst = true;
        }

        for (let estacionalidad of this.estacionalidades) {
          estacionalidad.porcIva = estacionalidad.porcIva / 100;
          let fechaEst = estacionalidad.mes + "/" + estacionalidad.anio;

          estacionalidad.fechaEst = fechaEst;
        }

        this.loadEstacionalidades = true;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo recuperar las estacionalidades.', detail: '' });
        this.loadEstacionalidades = true;
      }
    );
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }


  llenarDepositoSelect(event) {
    document.getElementById("depositoTd").innerText = this.formatoMoneda(event.data.monto);
  }

  vaciarDepositoSelect(event) {
    document.getElementById("depositoTd").innerText = this.formatoMoneda(0);
  }

  sumandoAcumulado(event) {
    this.acumuladoEstacionalidades += event.data.total;
    document.getElementById("estacionalidadTd").innerText = this.formatoMoneda(this.acumuladoEstacionalidades);
  }

  restandoAcumulado(event) {
    this.acumuladoEstacionalidades -= event.data.total;
    document.getElementById("estacionalidadTd").innerText = this.formatoMoneda(this.acumuladoEstacionalidades);
  }

  conciliar() {
    if (this.depositoSelect != null && this.estacionalidadesSelect.length != 0) {
      if (parseFloat(this.acumuladoEstacionalidades.toFixed(2)) == parseFloat(this.depositoSelect.monto.toFixed(2))) {

        this.showProgress = true;

        let conc = {
          deposito: this.depositoSelect,
          estacionalidades: this.estacionalidadesSelect,
          idUserEmp: this.profile.idEmpleado
        } as Conciliacion;

        this.clientEstacionalidades.conciliar(conc).subscribe(
          (data) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Conciliado',
              icon: PrimeIcons.CHECK,
              detail: 'Estacionalidades conciliadas.',
              life: 4000,
            });
            setTimeout(() => {
              this.showProgress = false;
              window.location.reload();
            }, 2500);
          },
          (error) => {
            this.showProgress = false;
            this.errorMessage("Error", error.error.message);
          }
        );
      }
      else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'La suma de los montos de las estacionalidades no cuadra con el deposito seleccionado, favor de revisarlo.',
          life: 10000,
        });
      }
    }
    else {
      this.messageService.add({
        severity: 'info',
        summary: 'Atención',
        detail: 'Favor de seleccionar un deposito y al menos una estacionalidad.',
        life: 5000,
      });
    }
  }

}
