<div class="progress container" *ngIf="!loadContent || showProgress || !showData">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<div *ngIf="showImage" class="contenedor-img">
    <i class="saldo-img"></i>
</div>

<ng-container *ngIf="loadContent && showData">
    <div>
        <h3>Participación en anexos</h3>
    </div>

    <axks-dialog-desgloce-partic [showDialog]="showDetallePart" (hideDialog)="showDetallePart = false"
        [idEmpleado]="(modoAdmin == true ? empleado : profile.idEmpleado)" [mes]="mesPartic" [anio]="anioPartic">
    </axks-dialog-desgloce-partic>

    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-6 p-xl-6 p-col-12">
            <p-table>
                <ng-template pTemplate="caption">
                    Empleado
                </ng-template>
                <ng-template pTemplate="header">
                    <tr *ngIf="modoAdmin">
                        <th style="text-align:right">Empleado:</th>
                        <td style="color: #0066FF;">&nbsp;&nbsp;{{nombreEmpleado}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:right">Sueldo actual:</th>
                        <td style="color: #0066FF;">&nbsp;&nbsp;{{item.sueldo | currency}}</td>
                    </tr>
                    <!-- Agregado para mostrar el bono - jgodinez -->
                    <tr>
                        <th style="text-align:right">Porcentaje bono:</th>
                        <td style="color: #0066FF;">&nbsp;&nbsp;{{item.bono.porcBono? (item.bono.porcBono |
                            number:'2.0-0') : '0'}}%</td>
                    </tr>

                    <tr *ngIf="item.bono.idBono && item.bono.montoBono > 0">
                        <th style="text-align:right">Monto bono:</th>
                        <td style="color: #0066FF;">&nbsp;&nbsp;
                            <span [class]="selectorEstiloBono" (click)="initImagenInicial()"
                                (mouseover)="funcionMouseOverBono($event)" (mouseout)="funcionMouseOutBono($event)"
                                pTooltip="Haz click para recibir tu bono">
                                {{item.bono.montoBono? (item.bono.montoBono | currency) : '0'}}
                            </span>
                        </td>
                    </tr>
                    <!-- Fin del agregado -jgodinez -->
                </ng-template>
            </p-table>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-6 p-xl-6 p-col-12">
            <p-table>
                <ng-template pTemplate="caption">
                    Información general
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="text-align:right">Meta anual:</th>
                        <td style="color: #0066FF;">&nbsp;&nbsp;{{item.metaAnual | currency}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:right">Meta actual:</th>
                        <td style="color: #0066FF;">&nbsp;&nbsp;{{item.metaActual | currency}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:right">Total de participaciones:</th>
                        <td [style]="{'color': colorTotalAnexos}">&nbsp;&nbsp;{{item.totalAnexos | currency}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:right">Por cubrir:</th>
                        <td [style]="{'color': colorPorCubrir}">&nbsp;&nbsp;{{item.porCubrir | currency}}</td>
                    </tr>
                    <tr>
                        <th style="text-align:right">Excedente:</th>
                        <td [style]="{'color': colorExcedente}">&nbsp;&nbsp;{{item.excedente | currency}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <p-table [value]="meses" styleClass="p-datatable-gridlines" [responsive]="true">
                <ng-template pTemplate="caption">
                    Meses - Primer semestre
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Febrero</th>
                        <th>Marzo</th>
                        <th>Abril</th>
                        <th>Mayo</th>
                        <th>Junio</th>
                        <th>Julio</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>
                            <span class="p-column-title" style="text-align:right">Febrero</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorFebreroQuin1}">{{item.quincena1.febrero |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorFebreroQuin2}">{{item.quincena2.febrero |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorFebreroSueldo}">{{item.sueldos.febrero |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(2)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.febrero | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorFebreroTotal}">{{item.totales.febrero | currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Marzo</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMarzoQuin1}">{{item.quincena1.marzo | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMarzoQuin2}">{{item.quincena2.marzo | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMarzoSueldo}">{{item.sueldos.marzo | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(3)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.marzo | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMarzoTotal}">{{item.totales.marzo | currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Abril</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAbrilQuin1}">{{item.quincena1.abril | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAbrilQuin2}">{{item.quincena2.abril | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAbrilSueldo}">{{item.sueldos.abril | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(4)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.abril | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAbrilTotal}">{{item.totales.abril | currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Mayo</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMayoQuin1}">{{item.quincena1.mayo | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMayoQuin2}">{{item.quincena2.mayo | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMayoSueldo}">{{item.sueldos.mayo | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(5)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.mayo | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorMayoTotal}">{{item.totales.mayo | currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Junio</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJunioQuin1}">{{item.quincena1.junio | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJunioQuin2}">{{item.quincena2.junio | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJunioSueldo}">{{item.sueldos.junio | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(6)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.junio | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJunioTotal}">{{item.totales.junio | currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Julio</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJulioQuin1}">{{item.quincena1.julio | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJulioQuin2}">{{item.quincena2.julio | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJulioSueldo}">{{item.sueldos.julio | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(7)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.julio | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorJulioTotal}">{{item.totales.julio | currency}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <p-table [value]="meses" styleClass="p-datatable-gridlines" [responsive]="true">
                <ng-template pTemplate="caption">
                    Meses - Segundo semestre
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Agosto</th>
                        <th>Septiembre</th>
                        <th>Octubre</th>
                        <th>Noviembre</th>
                        <th>Diciembre</th>
                        <th>Enero {{siguienteAnio}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>
                            <span class="p-column-title" style="text-align:right">Agosto</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAgostoQuin1}">{{item.quincena1.agosto | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAgostoQuin2}">{{item.quincena2.agosto | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAgostoSueldo}">{{item.sueldos.agosto | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(8)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.agosto | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorAgostoTotal}">{{item.totales.agosto | currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Septiembre</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorSeptiembreQuin1}">{{item.quincena1.septiembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorSeptiembreQuin2}">{{item.quincena2.septiembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorSeptiembreSueldo}">{{item.sueldos.septiembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(9)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.septiembre | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorSeptiembreTotal}">{{item.totales.septiembre |
                                        currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Octubre</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorOctubreQuin1}">{{item.quincena1.octubre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorOctubreQuin2}">{{item.quincena2.octubre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorOctubreSueldo}">{{item.sueldos.octubre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(10)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.octubre | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorOctubreTotal}">{{item.totales.octubre | currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Noviembre</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorNoviembreQuin1}">{{item.quincena1.noviembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorNoviembreQuin2}">{{item.quincena2.noviembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorNoviembreSueldo}">{{item.sueldos.noviembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(11)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.noviembre | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorNoviembreTotal}">{{item.totales.noviembre |
                                        currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Diciembre</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorDiciembreQuin1}">{{item.quincena1.diciembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorDiciembreQuin2}">{{item.quincena2.diciembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorDiciembreSueldo}">{{item.sueldos.diciembre |
                                        currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(12)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.diciembre | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorDiciembreTotal}">{{item.totales.diciembre |
                                        currency}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title" style="text-align:right">Enero {{siguienteAnio}}</span>
                            <div class="p-grid">
                                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                                    <span
                                        style="justify-content: center; display: flex; font-weight: bold;">Quincena:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Uno:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Dos:</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorEneroQuin1}">{{item.quincena1.enero | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorEneroQuin2}">{{item.quincena2.enero | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Sueldo
                                        Mensual: </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorEneroSueldo}">{{item.sueldos.enero | currency}}</span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Partic.:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                                    <span [class]="selectorEstilo" (click)="mostrarDesglocePart(1)"
                                        (mouseover)="funcionMouseOver($event)" (mouseout)="funcionMouseOut($event)">
                                        {{item.participaciones.enero | currency}}
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: left; display: flex; font-weight: bold;">Total:
                                    </span>
                                </div>
                                <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="border-top:outset 1px #CACFD2">
                                    <span style="justify-content: right; display: flex;"
                                        [style]="{'color':colorEneroTotal}">{{item.totales.enero | currency}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <br>
    <br>
    <div class="p-grid">
        <div class="p-sm-3 p-md-2 p-lg-1 p-lg-1 p-col-3">
            <span class="p-float-label">
                <p-dropdown inputId="dp-year" [autoDisplayFirst]="false" [options]="rangeYears" [(ngModel)]="anio"
                    optionLabel="label" optionValue="label" [style]="{'width':'100%'}"></p-dropdown>
                <label for="dp-year">Año</label>
            </span>
        </div>
        <div class="p-sm-9 p-md-10 p-lg-11 p-lg-11 p-col-9">
            <p-button label="Consultar" icon="pi pi-refresh" (onClick)="consultarGrafica()"></p-button>
        </div>
    </div>
    <br><br><br>
</ng-container>
<p-toast></p-toast>