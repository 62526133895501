import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoPsService } from '../../../../procs/empleado/empleado-ps.service';
import { EmpleadoNombre } from '../../../../api/cliente';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { Grafica, GraficaAdmin } from '../../../../api/grafica';

@Component({
  selector: 'axks-grafica-admin-form',
  templateUrl: './grafica-admin-form.component.html',
  styleUrls: ['./grafica-admin-form.component.scss']
})
export class GraficaAdminFormComponent extends BaseView implements OnInit {

  @Input() profile?: ProfileC;
  @Input() item: GraficaAdmin;

  empleados: EmpleadoNombre[];
  rangeYears: any[];

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmpleado: EmpleadoPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        anio: new Date().getFullYear(),
        sueldos: {},
        quincena1: {},
        quincena2: {},
        participaciones: {},
        totales: {}
      }
    }
    this.rangeYears = [];

    this.initCatEmpleados();
    this.initRangeYears();
  }

  initRangeYears() {
    let currentYear = new Date().getFullYear();
    for (let itemYear = currentYear; itemYear > currentYear - 5; itemYear--) {
      this.rangeYears.push({ label: itemYear });
    }
  }

  initCatEmpleados() {
    this.clientEmpleado.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.empleados = data;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error catalogo empleados',
          detail: 'No se pudo cargar la lista de empleados.'
        });
      }
    );
  }

}
