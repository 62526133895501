<p-progressSpinner *ngIf="!loadContent || showProgress"
    [style]="{width: '50px', height: '50px', 'z-index': '1000', 'position':'absolute'}"
    styleClass="axks-progress-spinner" strokeWidth="8">
</p-progressSpinner>

<p-dialog header="Distribucion" [(visible)]="showDialog" position="top" [modal]="true" [draggable]="false"
    [baseZIndex]="10000" (onHide)="ocultarDialog()">
    <!-- {{profile  | json}} -->
    <div class="contenedor-general">
        <div class="contenedor-desgloce">
            <div class="contenedor-fijado">
                <p-table styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align:right">Fecha:</th>
                            <td>&nbsp;&nbsp;{{mes}} - {{desgloce.anio}}</td>
                        </tr>
                        <tr>
                            <th style="text-align:right">Proyecto:</th>
                            <td>&nbsp;&nbsp;{{desgloce.nombreAnexo}}</td>
                        </tr>
                        <tr>
                            <th style="text-align:right">Anexo:</th>
                            <td>&nbsp;&nbsp;{{desgloce.numAnexo}}</td>
                        </tr>
                        <!-- <tr>
                            <th style="text-align:right">Lider de proyecto:</th>
                            <td>&nbsp;&nbsp;{{desgloce.liderProyecto}}</td>
                        </tr> -->
                        <tr>
                            <th style="text-align:right">Monto Estacionalidad:</th>
                            <td>&nbsp;&nbsp;{{montoEstacionalidad}}</td>
                        </tr>
                        <tr>
                            <th style="text-align:right">Comision a terceros:</th>
                            <td>&nbsp;&nbsp;{{desgloce.porcComision}}% - {{montoComision}}</td>
                        </tr>
                        <tr>
                            <th style="text-align:right">Impuestos:</th>
                            <td>&nbsp;&nbsp;{{desgloce.porcImpuestos}}% - {{montoImpuestos}}</td>
                        </tr>
                        <!-- <tr>
                            <th style="text-align:right">Monto de impuestos:</th>
                            <td>&nbsp;&nbsp;</td>
                        </tr> -->
                        <tr>
                            <th style="text-align:right">FEE:</th>
                            <td>&nbsp;&nbsp;{{desgloce.porcFEE}}% - {{montoFEE}}</td>
                        </tr>
                        <!-- <tr>
                            <th style="text-align:right">Monto FEE:</th>
                            <td>&nbsp;&nbsp;{{montoFEE}}</td>
                        </tr> -->
                        <tr>
                            <th style="text-align:right">Monto a distribuir:</th>
                            <td>&nbsp;&nbsp;{{montoDistribuir}}</td>
                        </tr>
                        <tr *ngIf="desgloce.montoReserva > 0">
                            <th style="text-align:right">Deuda en reserva:</th>
                            <td>&nbsp;&nbsp;<span style="color: red">{{montoReserva}}</span></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="contenedor-distribucion">
            <div class="p-grid">
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                    <!-- {{distExtReg | json}} -->
                    <h1>Distribucion Externa</h1>
                    <div style="display: flex; justify-content: right;">
                        <p-button label="¿Por qué no veo mi distribución?" styleClass="p-button-link"
                            (onClick)="avisoDist = true"></p-button>
                    </div>
                </div>
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                    <!-- {{distDerechPiso | json}} -->
                    <div class="p-grid">
                        <div class="p-sm-8 p-md-9 p-lg-10 p-xl-11 p-col-8">
                            <h3>Derecho de piso</h3>
                        </div>
                        <div class="p-sm-4 p-md-3 p-lg-2 p-xl-1 p-col-4">
                            <br>
                            <div class="contenedor-boton-agregar">
                                <p-button type="button" (click)="insertarDistDerechPiso()" icon="pi pi-user-plus"
                                    styleClass="p-button-lg" pTooltip="Agregar colaborador" tooltipPosition="left"
                                    [tooltipZIndex]="20000">
                                </p-button>
                            </div>
                        </div>
                        <ng-container *ngFor="let item of distDerechPiso">
                            <div class="p-sm-12 p-md-12 p-lg-3 p-xl-3 p-col-12">
                                <br>
                                <span class="p-float-label">
                                    <p-dropdown inputId="fl-colab-dp" [(ngModel)]="item.idColabExterno.id"
                                        [autoDisplayFirst]="false" [options]="colabDerechoPiso"
                                        filterBy="nombreCompleto" [filter]=true optionLabel="nombreCompleto"
                                        optionValue="id" appendTo="body" [baseZIndex]="10001" showClear="true"
                                        [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id && item.esReserva) ? true : false)">
                                    </p-dropdown>
                                    <label for="fl-colab-dp">Colaborador</label>
                                </span>
                            </div>
                            <div class="p-sm-12 p-md-12 p-lg-3 p-xl-3 p-col-12">
                                <br>

                                <div class="p-inputgroup"
                                    *ngIf="desgloce.montoReserva > 0 && ((!item.esNuevo && item.esReserva) || (item.esNuevo))">
                                    <span class="p-float-label">
                                        <p-inputNumber inputId="fl-total" [(ngModel)]="item.monto" mode="currency"
                                            currency="MXN" locale="es-MX" class="monto-con-check" [min]="1"
                                            [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)"
                                            (onInput)="modificarReserva($event, item.esReserva)">
                                        </p-inputNumber>
                                        <label for="inputgroup">Monto</label>
                                        <span class="p-inputgroup-addon">
                                            <p-checkbox [(ngModel)]="item.esReserva" [binary]="true"
                                                (onChange)="mostrarAdeudos($event, item, tipoColaborador.DERECHO_PISO)"
                                                pTooltip="Marcar el monto como parte del adeudo en reserva. Al marcarlo, el monto sera utilizado para saldar parte del adeudo vigente que se tiene"
                                                [tooltipZIndex]="20000"
                                                [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                            </p-checkbox>
                                        </span>
                                    </span>
                                </div>

                                <span class="p-float-label"
                                    *ngIf="desgloce.montoReserva == 0 || (!item.esNuevo && !item.esReserva)">
                                    <p-inputNumber inputId="fl-total" [(ngModel)]="item.monto" mode="currency"
                                        currency="MXN" locale="es-MX" [min]="1"
                                        [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                    </p-inputNumber>
                                    <label for="fl-total">Monto</label>
                                </span>

                            </div>
                            <div class="p-sm-12 p-md-12 p-lg-3 p-xl-3 p-col-12">
                                <br>
                                <div class="contenedor-estatus"
                                    *ngIf="(item.idReserva != null || item.idReserva != undefined) && item.esReserva == true">
                                    <span style="font-weight: bold;">
                                        MONTO PARA LA DEUDA NÚMERO: {{item.idReserva}}
                                    </span>
                                </div>
                                <span class="p-float-label" *ngIf="!item.esReserva">
                                    <p-dropdown inputId="fl-fecProgPago-dp" [(ngModel)]="item.fecPagoProg"
                                        [autoDisplayFirst]="false" [options]="listaFechasPago" appendTo="body"
                                        [baseZIndex]="10001"
                                        [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id && item.esReserva) ? true : false)">
                                    </p-dropdown>
                                    <label for="fl-fecProgPago-dp">Fecha de pago</label>
                                </span>
                            </div>
                            <div class="p-sm-8 p-md-6 p-lg-2 p-xl-2 p-col-8 contenedor-estatus">
                                <span *ngIf="item.id && !item.fecPago && !item.esReserva" style="font-weight: bold;">EN
                                    PROCESO DE
                                    PAGO</span>
                                <span
                                    *ngIf="(item.id && item.fecPago && !item.esReserva) || (item.id && item.esReserva)"
                                    style="font-weight: bold;">PAGADO</span>
                            </div>
                            <div class="p-sm-4 p-md-6 p-lg-1 p-xl-1 p-col-4">
                                <br>
                                <p-button (onClick)="quitarDistDerechPiso(item)" icon="pi pi-trash"
                                    styleClass="p-button-danger" pTooltip="Eliminar" tooltipPosition="bottom"
                                    [tooltipZIndex]="20000"
                                    [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                </p-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" [style]="{'border-top':'outset 1px #CACFD2'}">
                    <!-- {{distColabExt | json}} -->
                    <div class="p-grid">
                        <div class="p-sm-8 p-md-9 p-lg-10 p-xl-11 p-col-8">
                            <h3>Colaboradores Externos</h3>
                        </div>
                        <div class="p-sm-4 p-md-3 p-lg-2 p-xl-1 p-col-4">
                            <br>
                            <div class="contenedor-boton-agregar">
                                <p-button type="button" (click)="insertarDistColabExt()" icon="pi pi-user-plus"
                                    styleClass="p-button-lg" pTooltip="Agregar colaborador" tooltipPosition="left"
                                    [tooltipZIndex]="20000" appendTo="body">
                                </p-button>
                            </div>
                        </div>
                        <ng-container *ngFor="let item of distColabExt">
                            <div class="p-sm-12 p-md-12 p-lg-3 p-xl-3 p-col-12">
                                <br>
                                <span class="p-float-label">
                                    <p-dropdown inputId="fl-colab-ext" [(ngModel)]="item.idColabExterno.id"
                                        [autoDisplayFirst]="false" [options]="colabExternos" filterBy="nombreCompleto"
                                        [filter]=true optionLabel="nombreCompleto" optionValue="id" showClear="true"
                                        appendTo="body" [baseZIndex]="10001"
                                        [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                    </p-dropdown>
                                    <label for="fl-colab-ext">Colaborador</label>
                                </span>
                            </div>
                            <div class="p-sm-12 p-md-12 p-lg-3 p-xl-3 p-col-12">
                                <br>

                                <div class="p-inputgroup"
                                    *ngIf="desgloce.montoReserva > 0 && ((!item.esNuevo && item.esReserva) || (item.esNuevo))">
                                    <span class="p-float-label">
                                        <p-inputNumber inputId="fl-total" [(ngModel)]="item.monto" mode="currency"
                                            currency="MXN" locale="es-MX" class="monto-con-check" [min]="1"
                                            [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                        </p-inputNumber>
                                        <label for="inputgroup">Monto</label>
                                        <span class="p-inputgroup-addon">
                                            <p-checkbox [(ngModel)]="item.esReserva" [binary]="true"
                                                (onChange)="mostrarAdeudos($event, item, tipoColaborador.COLABORADOR_EXTERNO)"
                                                pTooltip="Marcar el monto como parte del adeudo en reserva. Al marcarlo, el monto sera utilizado para saldar parte del adeudo vigente que se tiene"
                                                [tooltipZIndex]="20000"
                                                [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                            </p-checkbox>
                                        </span>
                                    </span>
                                </div>

                                <span class="p-float-label"
                                    *ngIf="desgloce.montoReserva == 0 || (!item.esNuevo && !item.esReserva)">
                                    <p-inputNumber inputId="fl-total" [(ngModel)]="item.monto" mode="currency"
                                        currency="MXN" locale="es-MX" [min]="1"
                                        [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                    </p-inputNumber>
                                    <label for="fl-total">Monto</label>
                                </span>
                            </div>
                            <div class="p-sm-12 p-md-12 p-lg-3 p-xl-3 p-col-12">
                                <br>
                                <div class="contenedor-estatus"
                                    *ngIf="(item.idReserva != null || item.idReserva != undefined) && item.esReserva == true">
                                    <span style="font-weight: bold;">
                                        MONTO PARA LA DEUDA NÚMERO: {{item.idReserva}}
                                    </span>
                                </div>
                                <span class="p-float-label" *ngIf="!item.esReserva">
                                    <p-dropdown inputId="fl-fecProgPago-dp" [(ngModel)]="item.fecPagoProg"
                                        [autoDisplayFirst]="false" [options]="listaFechasPago" appendTo="body"
                                        [baseZIndex]="10001"
                                        [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                    </p-dropdown>
                                    <label for="fl-fecProgPago-dp">Fecha de pago</label>
                                </span>
                            </div>
                            <div class="p-sm-8 p-md-6 p-lg-2 p-xl-2 p-col-8 contenedor-estatus">
                                <span *ngIf="item.id && !item.fecPago && !item.esReserva" style="font-weight: bold;">EN
                                    PROCESO DE
                                    PAGO</span>
                                <span
                                    *ngIf="(item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva)"
                                    style="font-weight: bold;">PAGADO</span>
                            </div>
                            <div class="p-sm-4 p-md-6 p-lg-1 p-xl-1 p-col-4">
                                <br>
                                <p-button (onClick)="quitarDistColabExt(item)" icon="pi pi-trash"
                                    styleClass="p-button-danger" pTooltip="Eliminar" tooltipPosition="bottom"
                                    [tooltipZIndex]="20000"
                                    [disabled]="((item.id && item.fecPago && !item.esReserva) || (item.id != null && item.esReserva) ? true : false)">
                                </p-button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <br>
            <br>
            <div class="p-grid" [style]="{'border-top':'solid 1px #CACFD2'}">
                <div class="p-sm-8 p-md-9 p-lg-10 p-xl-11 p-col-8">
                    <h1>Distribucion Interna</h1>
                    <!-- {{distIntReg | json}} -->
                </div>

                <div class="p-sm-4 p-md-3 p-lg-2 p-xl-1 p-col-4">
                    <br>
                    <div class="contenedor-boton-agregar">
                        <p-button type="button" (click)="insertarDistInt()" icon="pi pi-user-plus"
                            styleClass="p-button-lg" pTooltip="Agregar participante" tooltipPosition="left"
                            [tooltipZIndex]="20000" appendTo="body">
                        </p-button>
                    </div>
                </div>

                <ng-container *ngFor="let item of distIntReg">
                    <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">
                        <br>
                        <span class="p-float-label">
                            <p-dropdown inputId="fl-colab-int" [(ngModel)]="item.idEmpleado" [autoDisplayFirst]="false"
                                [options]="empleados" filterBy="nombreCompleto" [filter]=true
                                optionLabel="nombreCompleto" optionValue="id" showClear="true" appendTo="body"
                                [baseZIndex]="10001">
                            </p-dropdown>
                            <label for="fl-colab-int">Participante</label>
                        </span>
                    </div>
                    <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">
                        <br>
                        <span class="p-float-label">
                            <p-inputNumber inputId="fl-total" [(ngModel)]="item.monto" mode="currency" currency="MXN"
                                locale="es-MX" [min]="1">
                            </p-inputNumber>
                            <label for="fl-total">Monto</label>
                        </span>
                    </div>
                    <div class="p-sm-12 p-md-2 p-lg-2 p-xl-2 p-col-12">
                        <br>
                        <div class="contenedor-boton-quitar-dist-int">
                            <p-button (onClick)="quitarDistInt(item)" icon="pi pi-trash" styleClass="p-button-danger"
                                pTooltip="Eliminar" tooltipPosition="bottom" [tooltipZIndex]="20000">
                            </p-button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button type="button" (click)="guardarDist()" label="Guardar" icon="pi pi-save"></p-button>&nbsp;
        <p-button type="button" *ngIf="existeDistExt == true || existeDistInt == true" (click)="borrarDistribuciones()"
            label="Eliminar distribución" icon="pi pi-trash" styleClass="p-button-danger"
            pTooltip="Elimina todos los registros de la distribución por completo" [tooltipZIndex]="20000"></p-button>
    </ng-template>

    <p-toast></p-toast>
    <p-toast key="msgSuma"></p-toast>
</p-dialog>

<p-confirmDialog [baseZIndex]="20000"></p-confirmDialog>

<p-dialog header="¿Por qué no veo mi distribución?" [(visible)]="avisoDist" [style]="{'max-width': '50vw', 'max-height':'45vh'}"
    [baseZIndex]="15000" (onHide)="avisoDist = false" [draggable]="false">
    <p style="font-size: 1rem;">
        Cuando la estacionalidad sufre una modificación o el proyecto se cancela y la estacionalidad no fue pagada
        dicha distribución es borrada. Los pagos a terceros 
        ya pagados se van a una reserva que será una deuda que el líder tendrá hasta 
        que los liquide, ya sea en la misma estacionalidad (si no ha sido cancelado el proyecto), otras estacionalidades del mismo proyecto o
        estacionalidades de otro proyecto.
        <br><br>
        El monto de la deuda se verá en el desglose de la estacionalidad, en la barra de la izquierda en numeros rojos. 
        Y para visualizar la lista de deudas que el líder tiene, al momento de realizar la distribución externa, se le 
        habilitará un cuadro de marcación a un lado del campo numérico para indicar a que deuda será destinado el monto.
    </p>
    <ng-template pTemplate="footer">
        <p-button (onClick)="avisoDist = false" label="Entiendo"></p-button>
    </ng-template>
</p-dialog>

<p-dialog class="dialog-adeudos" header="Adeudos" [(visible)]="showDialogAdeudos" position="center" [modal]="true"
    [draggable]="false" [baseZIndex]="15000" (onHide)="ocultarDialogAdeudos()">
    <br>
    <p-table [value]="listaAdeudos" selectionMode="single" [(selection)]="adeudoSelect" [scrollable]="true"
        (onRowSelect)="seleccionarAdeudo($event)" styleClass="result result-list p-datatable-gridlines">
        <ng-template pTemplate="caption">
            <span style="font-weight: bold;">Seleccionar el adeudo del que se desea liquidar parcial o
                totalmente</span>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>Num. Adeudo</th>
                <th>Proyecto</th>
                <th>Num. Anexo</th>
                <th>Estacionalidad</th>
                <th>Deuda total</th>
                <th>Monto abonado</th>
                <th>Monto en deuda</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item">
                <td>
                    <!-- <span class="p-column-title">Num. Adeudo</span> -->
                    {{item.id}}
                </td>
                <td>
                    <!-- <span class="p-column-title">Proyecto</span> -->
                    {{item.nombreProyecto}}
                </td>
                <td>
                    <!-- <span class="p-column-title">Proyecto</span> -->
                    {{item.numAnexo}}
                </td>
                <td>
                    <!-- <span class="p-column-title">Estacionalidad</span> -->
                    {{item.idEstacionalidad.mes | formatoMes}} -
                    {{item.idEstacionalidad.anio}}
                </td>
                <td>
                    <!-- <span class="p-column-title">Monto en deuda</span> -->
                    {{item.monto | currency}}
                </td>
                <td>
                    <!-- <span class="p-column-title">Monto abonado</span> -->
                    {{item.montoAbonado | currency}}
                </td>
                <td>
                    <!-- <span class="p-column-title">Monto abonado</span> -->
                    {{item.montoDeuda | currency}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>