import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { Anexo } from 'src/app/api/anexo';
import { AnexoRestcService } from 'src/app/client/anexo/anexo-restc.service';

@Injectable({
  providedIn: 'root'
})
export class AnexoPsService extends BaseProcess<AnexoRestcService>{

  client: AnexoRestcService;

  constructor(httpClient: HttpClient, crest: AnexoRestcService) {
    super(httpClient, crest);
    this.client = crest;
  }

  agregarContrato(item: Anexo): Observable<Anexo> {
    return this.client.agregarContrato(item);
  }

  cancelarProyecto(idAnexo: number, idUserEmp: number): Observable<Message> {
    return this.client.cancelarProyecto(idAnexo, idUserEmp);
  }
}
