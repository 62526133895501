<p-dialog #dialogMain header="Participante" [(visible)]="showDialog" position="top" [modal]="true" [maximizable]="true"
    [style]="{width: '70vw'}" [baseZIndex]="10000" (onHide)="ocultarCard()">
    <!-- {{items | json}} -->
    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
            [style]="{'justify-content': 'right', 'display': 'flex', 'align-items': 'center'}">
            <p-button type="button" (click)="insertar()" label="Agregar">
            </p-button>
        </div>
    </div>
    <div></div>
    <ng-container *ngFor="let item of items">
        <br>
        <p-card #card [style]="{'border-top': 'outset 1px #CACFD2', 
                        'border-left': 'outset 1px #CACFD2',
                        'border-right': 'outset 1px #e4e0e0',
                        'border-bottom': 'outset 1px #CACFD2'}">
            <div class="p-grid" [style]="{'padding-left':'20px', 'padding-right':'20px'}">

                <div class="p-sm-12 p-md-10 p-lg-10 p-xl-10 p-col-12">

                    <br>
                    <span class="p-float-label">
                        <p-dropdown #dpdd inputId="fl-partic" [(ngModel)]="item.id.idEmpleado.id"
                            [autoDisplayFirst]="false" [options]="catEmpleados" filterBy="nombreCompleto"
                            [style]="{'width':'100%'}" [filter]=true optionLabel="nombreCompleto" optionValue="id"
                            showClear="true" appendTo="body" [baseZIndex]="10001">
                        </p-dropdown>
                        <label for="fl-partic">Empleado</label>
                    </span>
                </div>
                <div class="p-sm-12 p-md-2 p-lg-2 p-xl-2 p-col-12"
                    [style]="{'justify-content': 'right', 'display': 'flex', 'align-items': 'center'}">

                    <br>
                    <p-button type="button" (click)="quitar(item)" icon="pi pi-times">
                    </p-button>
                </div>
            </div>
        </p-card>
    </ng-container>

    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
            [style]="{'justify-content': 'right', 'display': 'flex', 'margin-top': '50px'}">
            <br>
            <br>
            <p-button *ngIf="enableButtonGuardarPartic" id="btnGuardar" type="button" (click)="guardar()"
                label="Guardar">
            </p-button>
            <p-button *ngIf="enableButtonAgregarPartic" id="btnAgregarEstac" type="button"
                (click)="agregarParticipantes()" label="Agregar Participantes">
            </p-button>
        </div>
    </div>

    <p-toast key="msgSuma"></p-toast>
    <p-toast key="msgError"></p-toast>
</p-dialog>