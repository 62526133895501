import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Participante } from 'src/app/api/participante';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParticipanteRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msParticipante;
    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.remove = "";

    return paths;
  }

  actualizarParticipantes(items: Participante[], idUserEmp: number): Observable<Participante[]> {
    let path = this.getBaseEndpoint() + "actualizar";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .set("idUserEmp", idUserEmp.toString());

    return this.httpClient.post(path, items, { headers, params }) as Observable<Participante[]>;
  }
}
