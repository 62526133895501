import { formatCurrency, formatDate, formatPercent } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo, AnexoReg, EstatusAnexo } from 'src/app/api/anexo';
import { Cliente, EmpleadoNombre } from 'src/app/api/cliente';
import { AnexoPsService } from 'src/app/procs/anexo/anexo-ps.service';
import { estatusAnexo } from 'src/app/api/constantes/estatus-anexo';
import { rol } from 'src/app/api/constantes/roles';
import { Empresa } from '../../../api/cliente';

@Component({
  selector: 'axks-anexo',
  templateUrl: './anexo.component.html',
  styleUrls: ['./anexo.component.scss']
})
export class AnexoComponent extends BaseMainComponent<AnexoPsService> implements OnInit {

  operationModeRegister = OperationMode.ADDNEW;
  profile = this.getProfile() as ProfileC;
  showCard: boolean = false;
  showCardReg: boolean = false;
  showDialogDist: boolean = false;
  extraButtons: ButtonModel[];
  btnAgregarEst: ButtonModel;
  tabDefs: TabDefinition[];
  idEstacionalidad: number;
  showDialogMain: boolean;
  numeroBloque: number;
  soloEditaAnexo: boolean;
  enableAddCont: boolean = false;

  enableRegisterButton: boolean = true;
  enableButtonFinalEstac: boolean = false;

  showDialogPartic: boolean = false;
  enableButtonAgregarPartc: boolean = false;

  op = OperationMode;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private anexoPs: AnexoPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.profile) {
      this.profile = this.getProfile();
    }

    this.initTabsMenu(this.item.item);
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idCliente: {} as Cliente,
        idEmpresaCliente: {} as Empresa,
        idLiderReg: {} as EmpleadoNombre,
        estatusAnexo: {} as EstatusAnexo
      } as Anexo,
      minimumItem: {
        idCliente: {} as Cliente,
        idEmpresaCliente: {} as Empresa,
        idLiderReg: {} as EmpleadoNombre,
        estatusAnexo: {} as EstatusAnexo
      } as Anexo,
      registerItem: {
        idCliente: {} as Cliente,
        idEmpresaCliente: {} as Empresa,
        idLiderReg: {} as EmpleadoNombre,
        estacionalidades: [{}],
        participantes: [{
          id: {
            idEmpleado: {}
          }
        }]
      } as AnexoReg
    } as ComponentItem;
    return empty;
  }

  getService(): AnexoPsService {
    return this.anexoPs;
  }

  initHeadMenu(enableAddCont: boolean, enableCancelProy: boolean) {
    this.tabDefs = [
      {
        groupId: "Anexo",
        menu: [
          { label: 'Editar', command: this.mostrarDialogEdit.bind(this, 1, true), visible: true },
          { label: 'Agregar contrato', command: this.mostrarDialogEdit.bind(this, 1, false), visible: enableAddCont },
          { label: 'Cancelar proyecto', command: this.cancelarProyecto.bind(this), visible: enableCancelProy }
        ]
      },
      {
        groupId: "Cliente",
        menu: [
          { label: 'Editar', command: this.mostrarDialogEdit.bind(this, 2), visible: true }
        ]
      },
      {
        groupId: "Distribucion",
        showAsTable: true,
      },
      {
        groupId: "Estacionalidades",
        showAsTable: true,
        menu: [
          { label: 'Editar', command: this.mostrarDialogEdit.bind(this, 3), visible: true }
        ]
      },
      {
        groupId: "Participantes",
        showAsTable: true,
        menu: [
          { label: 'Editar', command: this.mostrarDialogEdit.bind(this, 4), visible: true }
        ]
      }
    ] as TabDefinition[];
  }

  initHeadSinMenu() {
    this.tabDefs = [
      {
        groupId: "Distribucion",
        showAsTable: true
      },
      {
        groupId: "Estacionalidades",
        showAsTable: true
      },
      {
        groupId: "Participantes",
        showAsTable: true
      }
    ] as TabDefinition[];
  }

  getColListDefinition(): ColumnDefinition[] {
    let colDefs = [
      // Columnas de anexo
      { field: "id", header: 'Clave', inAll: true, groupId: 'Anexo' },
      { field: 'nombre', header: 'Nombre', inAll: true, groupId: 'Anexo' },
      { field: 'monto', header: 'Monto', inAll: true, formatFunction: this.formatoMoneda, groupId: 'Anexo' },
      { field: 'numAnexo', header: 'Numero de anexo', inAll: true, groupId: 'Anexo' },
      { field: 'cvPresupuestal', header: 'Clave presupuestal', inAll: true, groupId: 'Anexo' },
      { field: 'estatusAnexo.estatusAnexo', header: 'Estatus', inAll: true, groupId: 'Anexo' },
      { field: 'idLiderReg.nombreCompleto', header: 'Lider', inDetail: true, groupId: 'Anexo' },
      { field: 'fecIni', header: 'Fecha inicial', inDetail: true, formatFunction: this.formatoFecha, groupId: 'Anexo' },
      { field: 'fecFin', header: 'Fecha final', inDetail: true, formatFunction: this.formatoFecha, groupId: 'Anexo' },
      { field: 'idLiderReg.nombreCompleto', header: 'Lider', inResultList: true },

      // Columnas de cliente
      { field: 'idEmpresaCliente.nombre', header: 'Cliente', inResultList: true },
      { field: 'idEmpresaCliente.nombre', header: 'Empresa', inDetail: true, groupId: 'Cliente' },
      { field: 'idEmpresaCliente.razonSocial', header: 'Razon Social', inDetail: true, groupId: 'Cliente' },
      { field: 'idEmpresaCliente.rfc', header: 'RFC Empresa', inDetail: true, groupId: 'Cliente' },
      { field: 'idEmpresaCliente.codigoPostal', header: 'Codigo Postal', inDetail: true, groupId: 'Cliente' },
      { field: '', header: 'CLIENTE SOLICITANTE', inDetail: true, groupId: 'Cliente' },
      { field: 'idCliente.idPersona.nombre', header: 'Nombre', inDetail: true, groupId: 'Cliente' },
      { field: 'idCliente.idPersona.apellidoPaterno', header: 'Apellido Paterno', inDetail: true, groupId: 'Cliente' },
      { field: 'idCliente.idPersona.apellidoMaterno', header: 'Apellido Materno', inDetail: true, groupId: 'Cliente' },

      // Columnas de estacionalidades
      { field: 'id', header: 'ID', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades' },
      { field: 'mes', header: 'Mes', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades', formatFunction: this.formatoMes },
      { field: 'anio', header: 'Año', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades' },
      { field: 'subtotal', header: 'SubTotal', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades', formatFunction: this.formatoMoneda },
      { field: 'porcIva', header: 'Porcentaje IVA', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades', formatFunction: this.formatoPorcIva },
      { field: 'total', header: 'Total', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades', formatFunction: this.formatoMoneda },
      { field: 'idFactura.numFactura', header: 'Numero de factura', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades' },
      { field: 'estatusEstacionalidad', header: 'Estatus', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades' },
      { field: 'distribuido', header: 'Estatus distribución', arrayFieldName: 'estacionalidades', inDetail: true, groupId: 'Estacionalidades', formatFunction: this.formatoDistribucion },

      // Columnas de distribucion
      { field: 'estadoDistribucion', header: 'Estado distribución', inResultList: true, groupId: 'Distribucion' },

      // Columnas de participantes
      { field: 'id.idEmpleado.id', header: 'ID Empleado', arrayFieldName: 'participantes', inDetail: true, groupId: 'Participantes' },
      { field: 'id.idEmpleado.nombreCompleto', header: 'Nombre del empleado', arrayFieldName: 'participantes', inDetail: true, groupId: 'Participantes' }
    ] as ColumnDefinition[];

    return colDefs;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Anexos';
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }

  formatoDistribucion(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Distribuido' : 'Sin distribuir');
    }
    else {
      return '';
    }
  }

  formatoFecha(date: string): string {
    let fechaDate = new Date(date);
    return formatDate(fechaDate, "dd 'de' MMMM 'de' yyyy", "es-MX", "America/Mexico_City");
  }

  formatoMes(item: number): string {
    let mes: string;
    switch (item) {
      case 1:
        return mes = 'Enero';
      case 2:
        return mes = 'Febrero';
      case 3:
        return mes = 'Marzo';
      case 4:
        return mes = 'Abril';
      case 5:
        return mes = 'Mayo';
      case 6:
        return mes = 'Junio';
      case 7:
        return mes = 'Julio';
      case 8:
        return mes = 'Agosto';
      case 9:
        return mes = 'Septiembre';
      case 10:
        return mes = 'Octubre';
      case 11:
        return mes = 'Noviembre';
      case 12:
        return mes = 'Diciembre';
      default:
        return mes = 'Error';
    }
  }

  formatoPorcIva(porcIva: number): string {
    porcIva = porcIva / 100;
    return formatPercent(porcIva, 'es-MX');
  }

  componentsFields(): void {
    super.componentsFields();
    this.formFields = ['nombreProyectoField', 'clienteField', 'montoField', 'fecIniField', 'fecFinField', 'numAnexoField', 'cvPresupuestalField'];
    this.formSearch = ['nombreProyectoField', 'numAnexoField', 'cvPresupuestalField', 'montoField', 'liderField', 'clienteField', 'fecIniField', 'fecFinField', 'estatusAnexoField', 'estadoDistribucionField'];
  }

  rowSelect(event) {
    this.tabDefs = [];
    super.rowSelect(event);
    this.initTabsMenu(event);
  }

  initTabsMenu(item: Anexo) {
    if (item.estatusAnexo.id == estatusAnexo.PROYECTO_ESTIMADO) {
      this.initHeadMenu(true, true);
    }
    else if (item.estatusAnexo.id == estatusAnexo.PROYECTO_CANCELADO) {
      this.initHeadSinMenu();
    }
    else {
      this.initHeadMenu(false, true);
    }
  }

  recargarDetalle(event) {
    if (event) {
      this.rowSelect(this.workItem());
    }
  }

  mostrarDialogEdit(numeroBloque: number, soloEditaAnexo: boolean) {
    if (!soloEditaAnexo) {
      if (this.workItem().estatusAnexo.id == estatusAnexo.PROYECTO_CON_ANEXO) {
        this.messageService.add({
          severity: 'warn',
          summary: 'No se puede ejecutar esta funcion',
          detail: 'No se puede agregar un anexo porque el proyecto ya cuenta con uno'
        })
      }
      else {
        this.showDialogMain = true;
        this.numeroBloque = numeroBloque;
        this.soloEditaAnexo = soloEditaAnexo;
      }
    }
    else {
      this.showDialogMain = true;
      this.numeroBloque = numeroBloque;
      this.soloEditaAnexo = soloEditaAnexo;
    }
  }

  disableDialogMain(event) {
    // numero de bloque se pone en 0 para asegurar que se vuelvan a inicializar los componentes internos del dialogo cuando se muestren de nuevo
    this.numeroBloque = 0;
    this.showDialogMain = event;
  }

  save(event: any): void {
    console.log("Función no disponible");
  }

  /**
   * Metodo para cancelar un proyecto
   */
  cancelarProyecto() {
    this.showProgress = true;
    this.anexoPs.cancelarProyecto(this.getIdOfSelectedItem(), this.profile.idEmpleado).subscribe(
      (data) => {
        this.successMessage("Proyecto cancelado", "El proyecto ha sido cancelado correctamente");
        this.showProgress = false;
        this.rowSelect(data);
      },
      (error) => {
        this.showProgress = false;
        setTimeout(() => {
          this.errorMessage("Error", error.error.message);
        }, 1000);
      }
    );
  }

}
