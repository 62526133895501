import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { EstatusEstacionalidadRestcService } from '../../client/estatus-estacionalidad/estatus-estacionalidad-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EstatusEstacionalidadPsService extends BaseProcess<EstatusEstacionalidadRestcService>{

  constructor(httpClient: HttpClient, crest: EstatusEstacionalidadRestcService) {
    super(httpClient, crest);
  }
}
