<!-- {{item | json}} -->

<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('nombreCompletoField')">
        <br>
        <span class="p-float-label">
            <input id="fl-nomCompleto" type="text" pInputText [(ngModel)]="item.nombreCompleto">
            <label for="fl-nomCompleto">Nombre del colaborador</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('idTipoColabExternoField')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tpColabExt" [(ngModel)]="item.idTipoColabExterno.id" [autoDisplayFirst]="false"
                [options]="catTipoColabExternos" filterBy="tipoColabExterno" [style]="{'width':'100%'}" [filter]=true
                optionLabel="tipoColabExterno" optionValue="id" showClear="true"></p-dropdown>
            <label for="fl-tpColabExt">Tipo de Colaborador</label>
        </span>
    </div>
</div>