<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">

    <div style="padding-left: 1rem; padding-right: 1rem;">
        <h3>Dinero virtual</h3>
    </div>
    <!-- {{itemMonto | json}} -->
    <div class="p-grid" style="padding-left: 1rem; padding-right: 1rem;">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <p-card [style]="{'width': '100%'}"
                [style]="{'border-top': 'outset 1px #CACFD2','border-left': 'outset 1px #CACFD2','border-right': 'outset 1px #CACFD2','border-bottom': 'outset 1px #CACFD2'}">
                <div class="p-grid" style="padding-left: 1rem; padding-right: 1rem; padding-top: 1rem;">
                    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-10 p-col-12"
                        [style]="{'display':'inline-grid', 'align-items': 'center'}">
                        <br>
                        <span class="p-float-label">
                            <p-inputNumber inputId="fl-monto-total" [(ngModel)]="itemMonto.monto" mode="currency"
                                currency="MXN" locale="es-MX" [style]="{'width': '100%'}" [disabled]="disabledMonto">
                            </p-inputNumber>
                            <label for="fl-monto-total">Monto total</label>
                        </span>
                    </div>
                    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-2 p-col-12"
                        [style]="{'justify-content': 'right', 'display': 'flex', 'align-items': 'center'}">
                        <br>
                        <br>
                        <p-button type="button" *ngIf="showAddButton" (click)="agregarMonto()" label="Agregar monto">
                        </p-button>
                        <p-button type="button" *ngIf="!showAddButton" (click)="editarMonto()" label="Editar monto">
                        </p-button>
                    </div>
                </div>
            </p-card>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="showTable">
            <h3>Distribucion del monto virtual</h3>
            <!-- {{distribucionesReg | json}} -->
            <p-table #dt [columns]="cols" [value]="distribucionesReg" [scrollable]="true"
                scrollHeight="400px" styleClass="p-datatable-gridlines result result-list" [paginator]="true"
                [showCurrentPageReport]="true" [rows]="25"
                currentPageReportTemplate="{first} - {last} de {totalRecords}"
                [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]">

                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center">
                        <span></span>
                        <button type="button" pButton pRipple icon="pi pi-plus" (click)="agregarDistribucion()"
                            class="p-button-info p-ml-auto" pTooltip="Agregar empleado" tooltipPosition="left"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dist>
                    <tr>
                        <td>
                            <p-dropdown #dpdd inputId="fl-partic" [(ngModel)]="dist.idEmpleado"
                                [autoDisplayFirst]="false" [options]="catEmpleados" filterBy="nombreCompleto"
                                [style]="{'width':'100%'}" [filter]=true optionLabel="nombreCompleto" optionValue="id"
                                showClear="true" appendTo="body" [baseZIndex]="10001" placeholder="Empleado">
                            </p-dropdown>
                        </td>
                        <td>
                            <p-inputNumber inputId="fl-monto-total" [(ngModel)]="dist.monto" mode="currency"
                                currency="MXN" locale="es-MX" [style]="{'width': '100%'}" placeholder="$0.00">
                            </p-inputNumber>
                        </td>
                        <td>
                            <textarea rows="1" cols="30" [(ngModel)]="dist.descripcion" [style]="{'width':'100%'}"
                                style="border-top: 1px; border-right: 1px; border-left: 1px; border-radius: 0px;"
                                autoResize="true" pInputTextarea placeholder="Descripción (Opcional)"></textarea>
                        </td>
                        <td>
                            <p-calendar (onSelect)="fecha($event, dist)" [(ngModel)]="dist.fecDist" [touchUI]="true"
                                [readonlyInput]="true" view="month" dateFormat="mm/yy" dataType="string"
                                [yearNavigator]="true" [yearRange]="yearRange" showIcon="true" placeholder="Fecha"
                                [style]="{'width':'100%'}" [panelStyle]="{'min-width':'30vw', 'min-height':'25vh'}"
                                [baseZIndex]="1001" appendTo="body" [minDate]="fechaMin" [maxDate]="fechaMax">
                            </p-calendar>
                        </td>
                        <td style="text-align: center;">
                            <button pButton pRipple icon="pi pi-trash" class="p-button-danger"
                                (click)="quitarDistribucion(dist)" pTooltip="Eliminar" tooltipPosition="right"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <!-- </div> -->
        </div>
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="showTable"
            style="display: flex; justify-content: right;">
            <button pButton pRipple icon="pi pi-save" label="Guardar cambios" (click)="guardarDistribucion()"></button>
        </div>
    </div>

</ng-container>

<p-toast></p-toast>