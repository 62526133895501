import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Grafica } from 'src/app/api/grafica';
import { ParticipacionDetalle } from '../../api/grafica';

@Injectable({
  providedIn: 'root'
})
export class GraficaRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msGrafica;
    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }
  processBody(res: any) {
    return res || {};
  }

  getGrafica(idEmpleado: number, anio: number): Observable<Grafica> {
    let path = this.getBaseEndpoint() + "detail/" + idEmpleado + "&" + anio;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<Grafica>;
  }

  getDetalleParticipaciones(idEmpleado: number, mes: number, anio: number): Observable<ParticipacionDetalle[]> {
    let path = this.getBaseEndpoint() + "detalle-participaciones/" + idEmpleado + "&" + mes + "&" + anio;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<ParticipacionDetalle[]>;
  }
}
