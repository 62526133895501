import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo } from 'src/app/api/anexo';
import { Estacionalidad } from 'src/app/api/estacionalidad';
import { AnexoPsService } from 'src/app/procs/anexo/anexo-ps.service';

@Component({
  selector: 'axks-edit-anexo-form',
  templateUrl: './edit-anexo-form.component.html',
  styleUrls: ['./edit-anexo-form.component.scss']
})
export class EditAnexoFormComponent extends BaseView implements OnInit {

  @Input() profile: ProfileC;
  @Input() soloEdita: boolean;
  @Input() idAnexo: number;

  item: Anexo;
  anexoOriginal: Anexo;
  montoAnexo: number;
  yearRange: string;
  mostrarFormEstacionalidades: boolean;
  mostrarBotonGuardar: boolean;
  isLoading: boolean;

  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() recargarDetalle = new EventEmitter<boolean>();
  @Output() emitAnexoOriginal = new EventEmitter<Anexo>();

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private crest: AnexoPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {
        estacionalidades: [],
        participantes: [],
        idCliente: {},
        idEmpresaCliente: {},
        idLiderReg: {},
        estatusAnexo: {}
      };
    }

    this.initDetalleAnexo();
    this.initDates();

    if (!this.profile) {
      this.profile = this.getProfile();
    }

  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 10) + ':' + (year + 10);
  }

  initDetalleAnexo() {
    this.showProgress = true;
    this.crest.detail(this.idAnexo).subscribe(
      (data) => {
        this.anexoOriginal = data as Anexo;
        this.item = this.anexoOriginal;

        if (this.item.estacionalidades && this.item.estacionalidades.length > 0) {
          for (let item of this.item.estacionalidades) {
            item.fechaEst = item.mes + "/" + item.anio;
            item.idUsrEmp = this.profile.idEmpleado;
          }
        }
        else {
          this.item.estacionalidades = [{
            idAnexo: this.item.id,
            idUsrEmp: this.profile.idEmpleado
          }];
        }


        this.montoAnexo = this.anexoOriginal.monto;

        if (this.montoAnexo != this.item.monto) {
          this.mostrarFormEstacionalidades = true;
        }
        else {
          this.mostrarFormEstacionalidades = false;
        }

        this.showProgress = false;
      },
      (error) => {
        this.showProgress = false;

        this.errorMessage("Error", "No se pudo recuperar los datos");
      }
    );
  }

  mostrarEstacionalidades(event) {
    if (this.montoAnexo == event.value) {
      this.mostrarFormEstacionalidades = false
      this.mostrarBotonGuardar = true;
    }
    else {
      // this.item.estacionalidades = [];
      this.mostrarFormEstacionalidades = true;
      this.mostrarBotonGuardar = false;
    }
  }

  editarAnexo() {
    let acumulado = 0;
    for (let item of this.item.estacionalidades) {
      acumulado += item.subtotal;
    }

    // Formatea el acumulado para redondear a dos decimales
    acumulado = parseFloat(acumulado.toFixed(2));

    if (this.item.monto == acumulado) {
      this.isLoading = true;
      this.crest.edit(this.item).subscribe(
        (data) => {
          this.messageService.add({ severity: 'success', summary: 'Anexo editado correctamente.', detail: '' });
          this.isLoading = false;
          this.hideDialog.emit(false);
          this.recargarDetalle.emit(true);
        },
        (error) => {
          this.isLoading = false;
          this.messageService.add({ key: "msgError", severity: 'error', summary: 'No se pudo editar el anexo.', detail: '' });
        }
      );
    }
    else {
      this.messageService.add({ key: 'msgSuma', severity: 'warn', summary: 'Atencion.', detail: 'La suma de las estacionalidades no coincide con el monto del anexo, favor de verificar.' });
    }
  }

  agregarContrato() {
    this.item.idUserEmp = this.profile.idEmpleado;
    let acumulado = 0;
    for (let item of this.item.estacionalidades) {
      acumulado += item.subtotal;
    }

    // Formatea el acumulado para redondear a dos decimales
    acumulado = parseFloat(acumulado.toFixed(2));

    if (this.item.monto == acumulado) {
      this.isLoading = true;
      this.crest.agregarContrato(this.item).subscribe(
        (data) => {
          this.messageService.add({ severity: 'success', summary: 'Anexo editado con los datos del contrato correctamente.', detail: '' });
          this.isLoading = false;
          this.hideDialog.emit(false);
          this.recargarDetalle.emit(true);
        },
        (error) => {
          this.isLoading = false;
          this.messageService.add({ key: "msgError", severity: 'error', summary: 'No se pudo editar el anexo.', detail: '' });
        }
      );
    }
    else {
      this.messageService.add({ key: 'msgSuma', severity: 'warn', summary: 'Atencion.', detail: 'La suma de las estacionalidades no coincide con el monto del anexo, favor de verificar.' });
    }
  }

  /**
  * Funcion para insertar en el arreglo de los items un nuevo elemento.
  */
  insertarEstac() {

    this.item.estacionalidades.push({
      idAnexo: this.item.id,
      idUsrEmp: this.profile.idEmpleado
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitarEstac(item: Estacionalidad) {
    this.item.estacionalidades.splice(this.item.estacionalidades.indexOf(item), 1);
  }

  /**
   * Funcion para dividir la fecha en mes y año e introducirlos dentro de los campos del item.
   * 
   * @param event Fecha obtenida del evento al seleccionar cierta fecha del calendar.
   * @param item Elemento del arreglo donde se almacenaran los datos obtenidos.
   */
  fecha(event, item: Estacionalidad) {

    let fechaT = event as Date;

    item.mes = fechaT.getMonth() + 1;
    item.anio = fechaT.getFullYear();
  }

}
