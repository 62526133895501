import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { ConciliacionRestcService } from 'src/app/client/conciliacion/conciliacion-restc.service';

@Injectable({
  providedIn: 'root'
})
export class ConciliacionPsService extends BaseProcess<ConciliacionRestcService>{

  client: ConciliacionRestcService;

  constructor(httpClient: HttpClient, crest: ConciliacionRestcService) {
    super(httpClient, crest);
    this.client = crest;
  }

  deleteConciliacion(id: number, idUserEmp: number): Observable<Message>{
    return this.client.deleteConciliacion(id, idUserEmp);
  }
}
