import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatoMesAnio'
})
export class FormatoMesAnioPipe implements PipeTransform {

  transform(value: string,): string {
    if(value == null) return null;
    let fechaDate = new Date(value);
    let itemsFecha = value.split("/");
    let mesValuar : number;
    mesValuar = +itemsFecha[0];
    let anio = itemsFecha[1];

    let mes: string;

    switch (mesValuar) {
      case 1: {
        mes = 'Enero';
        break;
      }
      case 2: {
        mes = 'Febrero';
        break;
      }
      case 3: {
        mes = 'Marzo';
        break;
      }
      case 4: {
        mes = 'Abril';
        break;
      }
      case 5: {
        mes = 'Mayo';
        break;
      }
      case 6: {
        mes = 'Junio';
        break;
      }
      case 7: {
        mes = 'Julio';
        break;
      }
      case 8: {
        mes = 'Agosto';
        break;
      }
      case 9: {
        mes = 'Septiembre';
        break;
      }
      case 10: {
        mes = 'Octubre';
        break;
      }
      case 11: {
        mes = 'Noviembre';
        break;
      }
      case 12: {
        mes = 'Diciembre';
        break;
      }
      default: {
        break;
      }
    }

    return mes + " de " + anio;
  }

}
