import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Participante, ParticipanteID } from '../../../api/participante';
import { EmpleadoNombre } from '../../../api/cliente';
import { EmpleadoPsService } from '../../../procs/empleado/empleado-ps.service';

/**
 * @author Pablo Cordero
 */
@Component({
  selector: 'axks-participante-form',
  templateUrl: './participante-form.component.html',
  styleUrls: ['./participante-form.component.scss']
})
export class ParticipanteFormComponent extends BaseView implements OnInit {

  @Input() items: Participante[];
  @Input() showDialog: boolean = true;
  @Input() idAnexo: number;
  @Input() profile;
  @Input() enableButtonGuardarPartic: boolean = false;
  @Input() enableButtonAgregarPartic: boolean = false;

  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() itemsOut = new EventEmitter<Participante[]>();

  catEmpleados: EmpleadoNombre[];

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.items) {
      this.items = [{
        id: {
          idEmpleado: {

          } as EmpleadoNombre,
          idAnexo: this.idAnexo
        } as ParticipanteID
      }];
    }

    this.initCatEmpleados();
  }

  initCatEmpleados() {
    this.clientEmp.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data
      },
      (error) => {
        this.messageService.add({
          summary: 'Error catalogo empleados',
          detail: 'No se pudo obtener el catalogo de empleados con contrato activo',
          severity: 'error'
        });
      }
    );
  }

  /**
   * Funcion para insertar en el arreglo de los items un nuevo elemento.
   */
  insertar() {

    this.items.push({
      id: {
        idAnexo: this.idAnexo,
        idEmpleado: {}
      }
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitar(item: Participante) {
    this.items.splice(this.items.indexOf(item), 1);
  }

  /**
   * Funcion que emite una bandera en falso para ocultar la carta desde el componente principal
   */
  ocultarCard() {
    this.hideDialog.emit(false);
  }

  /**
   * Funcion que emite a los componentes padres un arreglo de elementos con la informacion de los participantes
   */
  agregarParticipantes() {
    this.itemsOut.emit(this.items);
  }

  guardar() {

  }

}
