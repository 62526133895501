import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoNombre } from 'src/app/api/cliente';
import { GraficaAdmin } from 'src/app/api/grafica';
import { OrganigramaNom } from 'src/app/api/lider';
import { OrganigramaPsService } from '../../../../procs/organigrama/organigrama-ps.service';

@Component({
  selector: 'axks-grafica-lideres-form',
  templateUrl: './grafica-lideres-form.component.html',
  styleUrls: ['./grafica-lideres-form.component.scss']
})
export class GraficaLideresFormComponent extends BaseView implements OnInit {

  @Input() profile?: ProfileC;
  @Input() item: GraficaAdmin;

  empleados: OrganigramaNom[];
  rangeYears: any[];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmpleado: OrganigramaPsService
    ) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        anio: new Date().getFullYear(),
        sueldos: {},
        quincena1: {},
        quincena2: {},
        participaciones: {},
        totales: {}
      }
    }
    this.rangeYears = [];

    this.initCatEmpleados();
    this.initRangeYears();
  }

  initRangeYears() {
    let currentYear = new Date().getFullYear();
    for (let itemYear = currentYear; itemYear > currentYear - 5; itemYear--) {
      this.rangeYears.push({ label: itemYear });
    }
  }

  initCatEmpleados() {
    this.clientEmpleado.empleadosACargo(this.profile.idEmpleado).subscribe(
      (data) => {
        this.empleados = data;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error catalogo empleados',
          detail: 'No se pudo cargar la lista de empleados.'
        });
      }
    );
  }

}
