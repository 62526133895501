import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatoMes'
})
export class FormatoMesPipe implements PipeTransform {

  transform(value: number): string {
    let mes: string;
    switch (value) {
      case 1: {
        return mes = 'Enero';
      }
      case 2: {
        return mes = 'Febrero';
      }
      case 3: {
        return mes = 'Marzo';
      }
      case 4: {
        return mes = 'Abril';
      }
      case 5: {
        return mes = 'Mayo';
      }
      case 6: {
        return mes = 'Junio';
      }
      case 7: {
        return mes = 'Julio';
      }
      case 8: {
        return mes = 'Agosto';
      }
      case 9: {
        return mes = 'Septiembre';
      }
      case 10: {
        return mes = 'Octubre';
      }
      case 11: {
        return mes = 'Noviembre';
      }
      case 12: {
        return mes = 'Diciembre';
      }
      default: {
        return mes = 'Error';
      }
    }
  }

}
