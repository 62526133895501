import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Reserva } from 'src/app/api/distribucion';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReservaRestcService extends ARestClient {

  constructor(httpclient: HttpClient) {
    super(httpclient);
  }

  getBaseEndpoint(): string {
    let path = environment.msReserva;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  adeudosPorLider(idEmpleado: number): Observable<Reserva[]> {
    let path = this.getBaseEndpoint() + "por-lider/" + idEmpleado;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<Reserva[]>;
  }
}
