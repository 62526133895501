<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <!-- {{item.item | json}} -->
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar " [addNewButton]="false" searchLabel="Buscar "
        [searchButton]="false" [sectionTitle]="getComponentTitle()">
    </axks-operations-bar>

    <axks-detail-tab *ngIf="showDetail()" class="axks-detail-tab-80" [data]="workItem()" [tabDefs]="tabDefs"
        [colsDefinition]="colDefinition" [detailFields]="detailFields">
    </axks-detail-tab>

    <axks-estacionalidad-gestion-form #cmpForm *ngIf="showForm()" [item]="workItem()" [profile]="profile"
        [formSearch]="formSearch" [formFields]="formFields" [operationMode]="operationMode"
        [class]="'cmp-form-' + operationMode" [catProyectos]="catProyectos" [catEmpresasClientes]="catEmpresasClientes"
        [catLideres]="catLideres" [catEstatusAnexo]="catEstatusAnexo" [catEstatusEstacionalidad]="catEstatusEstacionalidad" 
        [sumadoSubTotal]="sumadoSubTotal" [sumadoTotal]="sumadoTotal">
    </axks-estacionalidad-gestion-form>

    <axks-actions-bar (save)="guardar($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" [printButton]="false" [editButton]="false" [deleteButton]="false" saveLabel="Guardar"
        cancelLabel="Cancelar" cleanLabel="Limpiar" [extraButtons]="extraButtons">
    </axks-actions-bar>

    <!-- <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list> -->

    <axks-estacionalidad-gestion-tabla *ngIf="showResultList()" [estacionalidades]="resultList"
        (emitEstacSelect)="rowSelect($event)" (emitirSubTotal)="sumadoSubTotal = $event"
        (emitirTotal)="sumadoTotal = $event">
    </axks-estacionalidad-gestion-tabla>
</ng-container>

<p-toast></p-toast>