import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Message } from '@axks/components';
import { DistribucionExterna, FiltroPagoTerceros } from '../../api/distribucion';

@Injectable({
  providedIn: 'root'
})
export class DistribucionRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msDistribucion;

    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  deleteByIdEstacionalidad(idEstacionalidad: number): Observable<Message> {
    let path = this.getBaseEndpoint() + "delete-by-id-estacionalidad/" + idEstacionalidad;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.delete(path, { headers });
  }

  obtenerPagoTerceros(filtro: FiltroPagoTerceros): Observable<DistribucionExterna[]> {
    let path = this.getBaseEndpoint() + "obtener-pago-terceros";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, filtro, { headers }) as Observable<DistribucionExterna[]>;
  }

  aplicarPagoTercero(idDistExterna: number, idUserEmp: number, refPago: string): Observable<DistribucionExterna> {
    let path = this.getBaseEndpoint() + "aplicar-pago";

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("id-distribucion-externa", idDistExterna.toString())
      .append("id-user-emp", idUserEmp.toString())
      .append("ref-pago", refPago);

    return this.httpClient.get(path, { headers, params }) as Observable<DistribucionExterna>;
  }
}
