<div class="p-grid">
    <!-- {{selectEstacionalidad | json}} -->
    <div class="p-col-12">
        <p-table #dt [columns]="cols" [value]="estacionalidades" selectionMode="single"
            [scrollable]="true" scrollHeight="auto" [(selection)]="selectEstacionalidad"
            styleClass="result result-list" [scrollable]="true"
            (onRowSelect)="rowSelect($event)" [paginator]="true" [showCurrentPageReport]="true" [rows]="25"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]"
            [globalFilterFields]="['idEstacionalidad','anexo.nombre','anexo.numAnexo','anexo.nombreEstatus','anexo.cliente.nombre','subtotal','iva','total','numFactura','fecEstac','estatusEstacionalidad']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center">
                    <span style="font-size: 1.2rem;">Lista de resultados</span>
                    <!-- <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Busqueda global"/>
                    </span> -->
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th [pSortableColumn]="col.sortName ? col.sortName : col.field" *ngFor="let col of columns"> 
                        {{col.header}}<p-sortIcon [field]="col.sortName ? col.sortName : col.field"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th colspan="7" class="p-text-right">Total</th>
                    <th>{{sumadoSubTotal | currency}}</th>
                    <th colspan="1"></th>
                    <th>{{sumadoTotal | currency}}</th>
                    <th colspan="2"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-estacionalidad let-columns="columns" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="estacionalidad" [pSelectableRowIndex]="rowIndex">
                    <td *ngFor="let col of columns">
                        <span *ngIf="col.sinFormato == true">{{estacionalidad[col.field]}}</span>
                        <span *ngIf="col.formatoMoneda == true">{{estacionalidad[col.field] | currency}}</span>
                        <span *ngIf="col.formatoPorcentaje == true">{{estacionalidad[col.field]}}%</span>
                        <span *ngIf="col.formatoMesAnio == true">{{estacionalidad[col.field] | date: 'MMMM yyyy':'America/Mexico_City':'es-MX' }}</span>
                        <span
                            *ngIf="col.sinFormato == true && col.esDatoAnexo == true">{{estacionalidad.anexo[col.field]}}</span>
                        <span
                            *ngIf="col.sinFormato == true && col.esEstatusAnexo == true">{{estacionalidad.anexo[col.field]}}</span>
                        <span
                            *ngIf="col.sinFormato == true && col.esDatoEmpresaCliente == true">{{estacionalidad.anexo.idEmpresaCliente[col.field]}}</span>
                        <span
                            *ngIf="col.sinFormato == true && col.esDatoLiderReg == true">{{estacionalidad.anexo.idLiderReg[col.field]}}</span>
                        <span
                            *ngIf="col.sinFormato == true && col.esDatoEstatusAnexo == true">{{estacionalidad.anexo.estatusAnexo[col.field]}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-toast></p-toast>