import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { GraficaRestcService } from '../../client/grafica/grafica-restc.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Grafica, ParticipacionDetalle } from '../../api/grafica';

@Injectable({
  providedIn: 'root'
})
export class GraficaPsService extends BaseProcess<GraficaRestcService> {

  client: GraficaRestcService;

  constructor(httpClient: HttpClient, crest: GraficaRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  getGrafica(idEmpleado: number, anio: number): Observable<Grafica> {
    return this.client.getGrafica(idEmpleado, anio);
  }

  getDetalleParticipaciones(idEmpleado: number, mes: number, anio: number): Observable<ParticipacionDetalle[]> {
    return this.client.getDetalleParticipaciones(idEmpleado, mes, anio);
  }
}
