<div class="contenedor-progress-spinner" *ngIf="showProgress">
    <p-progressSpinner strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="!showProgress">
    <div class="p-grid frm-part">
        <!-- {{item | json}} -->
        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
            <br>
            <span class="p-float-label">
                <input id="fl-nombreP" type="text" pInputText [(ngModel)]="item.nombre">
                <label for="fl-nombreP">Nombre del proyecto</label>
            </span>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
            <br>
            <span class="p-float-label">
                <input id="fl-numAnx" type="text" pInputText [(ngModel)]="item.numAnexo">
                <label for="fl-numAnx">Numero de anexo</label>
            </span>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
            <br>
            <span class="p-float-label">
                <input id="fl-cvPr" type="text" pInputText [(ngModel)]="item.cvPresupuestal">
                <label for="fl-cvPr">Clave presupuestal</label>
            </span>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
            <br>
            <span class="p-float-label">
                <p-inputNumber [(ngModel)]="item.monto" mode="currency" currency="MXN" locale="es-MX"
                    [style]="{'width':'50%'}" (onInput)="mostrarEstacionalidades($event)">
                </p-inputNumber>
                <label for="fl-monto">Monto</label>
            </span>
        </div>
        <div class="p-sm-6 p-md-6 p-lg-4 p-xl-4 p-col-12">
            <br>
            <span class="p-float-label">
                <p-calendar [(ngModel)]="item.fecIni" [showIcon]="true" dateFormat="yy/mm/dd" dataType="string"
                    [baseZIndex]="20000" appendTo="body" inputId="fl-fecIni" [yearNavigator]="true"
                    [yearRange]="yearRange" [monthNavigator]="true" [style]="{'width':'70%'}"></p-calendar>
                <label for="fl-fecIni">Fecha inicial</label>
            </span>
        </div>
        <div class="p-sm-6 p-md-6 p-lg-4 p-xl-4 p-col-12">
            <br>
            <span class="p-float-label">
                <p-calendar [(ngModel)]="item.fecFin" [showIcon]="true" dateFormat="yy/mm/dd" dataType="string"
                    [baseZIndex]="20000" appendTo="body" inputId="fl-fecIni" [yearNavigator]="true"
                    [yearRange]="yearRange" [monthNavigator]="true" [style]="{'width':'70%'}"></p-calendar>
                <label for="fl-fecIni">Fecha final</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="mostrarFormEstacionalidades"
            [style]="{'padding-left':'30px', 'padding-right':'30px', 'padding-top':'30px', 'padding-bottom':'15px'}">
            <div class="p-grid">
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
                    [style]="{'justify-content': 'right', 'display': 'flex', 'align-items': 'center'}">
                    <p-button type="button" (click)="insertarEstac()" label="Agregar">
                    </p-button>
                </div>
            </div>
            <div></div>
            <ng-container *ngFor="let item of item.estacionalidades">
                <br>
                <p-card [style]="{'border-top': 'outset 1px #CACFD2', 
                            'border-left': 'outset 1px #CACFD2',
                            'border-right': 'outset 1px #e4e0e0',
                            'border-bottom': 'outset 1px #CACFD2'}">
                    <div class="p-grid" [style]="{'padding-left':'20px', 'padding-right':'20px'}">

                        <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">
                            <br>
                            <span class="p-float-label">
                                <p-calendar view="month" dateFormat="mm/yy" dataType="string" [yearNavigator]="true"
                                    [(ngModel)]="item.fechaEst" [yearRange]="yearRange" [readonlyInput]="true"
                                    inputId="fl-cal" showIcon="true" [style]="{'width':'90%'}"
                                    (onSelect)="fecha($event, item)" [baseZIndex]="20000" appendTo="body">
                                </p-calendar>
                                <label for="fl-cal">Fecha</label>
                            </span>
                        </div>

                        <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">
                            <br>
                            <span class="p-float-label">
                                <p-inputNumber inputId="fl-subtotal" [(ngModel)]="item.subtotal" mode="currency"
                                    currency="MXN" locale="es-MX" [style]="{'width':'80%'}">
                                </p-inputNumber>
                                <label for="fl-subtotal">Monto</label>
                            </span>
                        </div>

                        <div class="p-sm-12 p-md-2 p-lg-2 p-xl-2 p-col-12"
                            [style]="{'justify-content': 'right', 'display': 'flex', 'align-items': 'center'}">

                            <br>
                            <p-button (onClick)="quitarEstac(item)" icon="pi pi-trash" styleClass="p-button-danger">
                            </p-button>
                        </div>
                    </div>
                </p-card>
            </ng-container>
        </div>


        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="soloEdita == true"
            [style]="{'justify-content': 'right', 'display': 'flex', 'margin-top': '50px'}">
            <br>
            <br>
            <p-button (onClick)="editarAnexo()" [loading]="isLoading" label="Editar anexo" icon="pi pi-save">
            </p-button>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="soloEdita == false"
            [style]="{'justify-content': 'right', 'display': 'flex', 'margin-top': '50px'}">
            <br>
            <br>
            <p-button (onClick)="agregarContrato()" [loading]="isLoading" label="Agregar contrato" icon="pi pi-file">
            </p-button>
        </div>
    </div>

    <p-toast key="msgError"></p-toast>
    <p-toast key="msgSuma"></p-toast>
</ng-container>