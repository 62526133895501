import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Estacionalidad } from 'src/app/api/estacionalidad';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-estacionalidad-gestion-tabla',
  templateUrl: './estacionalidad-gestion-tabla.component.html',
  styleUrls: ['./estacionalidad-gestion-tabla.component.scss']
})
export class EstacionalidadGestionTablaComponent extends BaseView implements OnInit {

  @Input() estacionalidades: Estacionalidad[];
  @Input() vaciarSeleccionados: boolean = false;

  @Output() emitEstacSelect = new EventEmitter<Estacionalidad>();
  @Output() emitirSubTotal = new EventEmitter<number>();
  @Output() emitirTotal = new EventEmitter<number>();

  selectEstacionalidad: Estacionalidad;
  idAnexoSel: number;
  cols: any[];
  sumadoSubTotal: number;
  sumadoTotal: number;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.estacionalidades) {

      if (this.estacionalidades.length == 0) {
        this.sumadoSubTotal = 0;
        this.sumadoTotal = 0;
      }
      else {
        for (let item of this.estacionalidades) {
          this.sumadoSubTotal = this.sumadoSubTotal + item.subtotal;
          // this.sumadoTotal = this.sumadoTotal + item.total;
        }

        this.sumadoTotal = this.sumadoSubTotal * (1 + (environment.porcentajeIva / 100));
      }
    }

    if (this.vaciarSeleccionados == true) {
      this.vaciarSeleccionados = false;
    }

    this.emitirCantitades();

  }

  ngOnInit(): void {

    if (!this.estacionalidades) {
      this.estacionalidades = [];
    }

    this.cols = [
      { field: 'id', header: 'Num. Estacionalidad', sinFormato: true },
      { field: 'nombre', header: 'Proyecto', sinFormato: true, esDatoAnexo: true, sortName: 'anexo.nombre' },
      { field: 'numAnexo', header: 'No. Anexo', sinFormato: true, esDatoAnexo: true, sortName: 'anexo.numAnexo' },
      { field: 'cvPresupuestal', header: 'Clave presupuestal', esDatoAnexo: true, sinFormato: true, sortName: 'anexo.cvPresupuestal' },
      { field: 'estatusAnexo', header: 'Estatus proyecto', sinFormato: true, esDatoEstatusAnexo: true, sortName: 'anexo.estatusAnexo.estatusAnexo' },
      { field: 'nombre', header: 'Cliente', sinFormato: true, esDatoEmpresaCliente: true, sortName: 'anexo.idEmpresaCliente.nombre' },
      { field: 'nombreCompleto', header: 'Lider', esDatoLiderReg: true, sinFormato: true, sortName: 'anexo.idLiderReg.nombreCompleto' },
      { field: 'subtotal', header: 'SubTotal', formatoMoneda: true },
      { field: 'porcIva', header: 'IVA', formatoPorcentaje: true },
      { field: 'total', header: 'Total', formatoMoneda: true },
      { field: 'fechaEstacionalidad', header: 'Fecha de cobro', formatoMesAnio: true },
      { field: 'estatusEstacionalidad', header: 'Estatus Estacionalidad', sinFormato: true }
    ];

    this.selectEstacionalidad = {
      anexo: {
        idCliente: {},
        idEmpresaCliente: {},
        estatusAnexo: {},
        idLiderReg: {}
      },
      idFactura: {}
    };
    this.sumadoSubTotal = 0;
    this.sumadoTotal = 0;

    this.emitirCantitades();

  }

  emitirCantitades() {
    this.emitirSubTotal.emit(this.sumadoSubTotal);
    this.emitirTotal.emit(this.sumadoTotal);
  }

  rowSelect(event) {
    if (this.operationMode != OperationMode.SELECTED) {
      this.lastMode = this.operationMode;
    }
    this.screenModeService.screenMode.next(OperationMode.SELECTED);
    this.emitEstacSelect.emit(event.data);
  }

  /* exportExcel() {
    let requestExcel: EstacionalidadExcel[] = [];
    for (let itemEstac of this.estacionalidades) {
      requestExcel.push({
        idEstacionalidad: itemEstac.idEstacionalidad,
        proyecto: itemEstac.anexo.nombre,
        noAnexo: itemEstac.anexo.numAnexo,
        estatusProyecto: itemEstac.anexo.nombreEstatus,
        cliente: itemEstac.anexo.cliente.nombre,
        subtotal: itemEstac.subtotal,
        iva: itemEstac.iva,
        total: itemEstac.total,
        noFactura: itemEstac.numFactura,
        fechaCobro: this.formatoMesAnio(itemEstac.fecEstac),
        estatusEstacionalidad: itemEstac.estatusEstacionalidad
      });
    }
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(requestExcel);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Estacionalidades");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '_export_' + now.getFullYear() + "-" + (mes < 10 ? "0" + mes : mes) + "-" + now.getDate() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  } */

  formatoMesAnio(value: string): string {
    let fecha = new Date(value);
    let mesValuar = fecha.getMonth() + 1;
    let anio = fecha.getFullYear();
    let mes: string;

    switch (mesValuar) {
      case 1: {
        mes = 'Enero';
        break;
      }
      case 2: {
        mes = 'Febrero';
        break;
      }
      case 3: {
        mes = 'Marzo';
        break;
      }
      case 4: {
        mes = 'Abril';
        break;
      }
      case 5: {
        mes = 'Mayo';
        break;
      }
      case 6: {
        mes = 'Junio';
        break;
      }
      case 7: {
        mes = 'Julio';
        break;
      }
      case 8: {
        mes = 'Agosto';
        break;
      }
      case 9: {
        mes = 'Septiembre';
        break;
      }
      case 10: {
        mes = 'Octubre';
        break;
      }
      case 11: {
        mes = 'Noviembre';
        break;
      }
      case 12: {
        mes = 'Diciembre';
        break;
      }
      default: {
        break;
      }
    }

    return mes + " de " + anio;
  }
}
