import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, Message, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { ConciliacionPsService } from 'src/app/procs/conciliacion/conciliacion-ps.service';
import { Conciliacion } from 'src/app/api/conciliacion';
import { FormatoFechaPipe } from 'src/app/pipe/formato-fecha.pipe';
import { CurrencyPipe, formatCurrency, formatPercent } from '@angular/common';
import { Estacionalidad } from 'src/app/api/estacionalidad';

@Component({
  selector: 'axks-reversado',
  templateUrl: './reversado.component.html',
  styleUrls: ['./reversado.component.scss']
})
export class ReversadoComponent extends BaseMainComponent<ConciliacionPsService> implements OnInit {

  tabDefs: TabDefinition[];

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private client: ConciliacionPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initHeadMenu();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as Conciliacion,
      minimumItem: {} as Conciliacion,
      registerItem: {} as Conciliacion
    } as ComponentItem;
    return empty;
  }

  getService(): ConciliacionPsService {
    return this.client;
  }

  initHeadMenu() {
    this.tabDefs = [
      {
        groupId: 'Estacionalidades',
        showAsTable: true
      }
    ];
  }

  getColListDefinition(): ColumnDefinition[] {
    let columns = [
      { field: 'deposito.id', header: 'Clave deposito', inAll: true },
      { field: 'deposito.monto', header: 'Monto deposito', inAll: true, formatFunction: this.formatoMoneda },
      { field: 'deposito.fechaDeposito', header: 'Fecha conciliacion', inAll: true, formatFunction: this.formatoFecha },

      { field: 'id', header: 'Clave estacionalidad', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true },
      { field: 'subtotal', header: 'SubTotal', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true, formatFunction: this.formatoMoneda },
      { field: 'porcIva', header: 'Porcentaje de IVA', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true, formatFunction: this.formatoPorcIva },
      { field: 'total', header: 'Total', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true, formatFunction: this.formatoMoneda },
      { field: 'idFactura.numFactura', header: 'Numero de factura', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true },
      { field: 'mes', header: 'Mes', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true, formatFunction: this.formatoMes },
      { field: 'anio', header: 'Año', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true },
      { field: 'idFactura.fechaEmision', header: 'Fecha emision de factura', arrayFieldName: 'estacionalidades', groupId: 'Estacionalidades', inDetail: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return columns;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.deposito.id || this.item.item.deposito.id;
  }
  getComponentTitle(): string {
    return 'Conciliacion';
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }

  formatoFecha(date: string): string {
    let fechaDate = new Date(date);
    let mes: string;
    switch (fechaDate.getMonth()) {
      case 0: {
        mes = 'Enero';
        break;
      }
      case 1: {
        mes = 'Febrero';
        break;
      }
      case 2: {
        mes = 'Marzo';
        break;
      }
      case 3: {
        mes = 'Abril';
        break;
      }
      case 4: {
        mes = 'Mayo';
        break;
      }
      case 5: {
        mes = 'Junio';
        break;
      }
      case 6: {
        mes = 'Julio';
        break;
      }
      case 7: {
        mes = 'Agosto';
        break;
      }
      case 8: {
        mes = 'Septiembre';
        break;
      }
      case 9: {
        mes = 'Octubre';
        break;
      }
      case 10: {
        mes = 'Noviembre';
        break;
      }
      case 11: {
        mes = 'Diciembre';
        break;
      }
      default: {
        break;
      }
    }

    return (fechaDate.getDate() < 10 ? "0" + fechaDate.getDate() : fechaDate.getDate()) + " de " + mes + " de " + fechaDate.getFullYear();
  }

  formatoMes(item: number): string {
    let mes: string;
    switch (item) {
      case 1: {
        return mes = 'Enero';
      }
      case 2: {
        return mes = 'Febrero';
      }
      case 3: {
        return mes = 'Marzo';
      }
      case 4: {
        return mes = 'Abril';
      }
      case 5: {
        return mes = 'Mayo';
      }
      case 6: {
        return mes = 'Junio';
      }
      case 7: {
        return mes = 'Julio';
      }
      case 8: {
        return mes = 'Agosto';
      }
      case 9: {
        return mes = 'Septiembre';
      }
      case 10: {
        return mes = 'Octubre';
      }
      case 11: {
        return mes = 'Noviembre';
      }
      case 12: {
        return mes = 'Diciembre';
      }
      default: {
        return mes = 'Error';
      }
    }
  }

  formatoPorcIva(porcIva: number): string {
    porcIva = porcIva / 100;
    return formatPercent(porcIva, 'es-MX');
  }

  delete(event){
    this.client.deleteConciliacion(this.getIdOfSelectedItem(), this.profile.idEmpleado).subscribe(
      (data) => {
        let msg = data as Message;
        this.messageService.add({
          severity: 'success',
          summary: msg.message,
          detail: msg.details
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo procesar la solicitud de borrado de la conciliacion'
        })
      }
    );
  }
}
