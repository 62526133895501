import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { OrganigramaNom } from 'src/app/api/lider';
import { OrganigramaRestcService } from 'src/app/client/organigrama/organigrama-restc.service';

@Injectable({
  providedIn: 'root'
})
export class OrganigramaPsService extends BaseProcess<OrganigramaRestcService> {

  client: OrganigramaRestcService;

  constructor(httpClient: HttpClient, crest: OrganigramaRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  empleadosACargo(idLider: number): Observable<OrganigramaNom[]> {
    return this.client.empleadosACargo(idLider);
  }
}
