<div class="progress container" *ngIf="!loadDepositos || !loadEstacionalidades">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<ng-container *ngIf="loadDepositos && loadEstacionalidades">
    <div>
        <h3>Conciliacion</h3>
    </div>
    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-4 p-xl-4 p-col-12">
            <p-table [value]="depositos" [columns]="columnDep" styleClass="result result-list"
                [scrollable]="true" scrollHeight="35vh" selectionMode="single" [(selection)]="depositoSelect"
                (onRowSelect)="llenarDepositoSelect($event)" (onRowUnselect)="vaciarDepositoSelect($event)">
                <ng-template pTemplate="caption">
                    Depositos
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                            {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-deposito let-columns="columns">
                    <tr [pSelectableRow]="deposito">
                        <td *ngFor="let col of columns">
                            <!-- <span class="p-column-title">{{col.header}}</span> -->
                            <span *ngIf="col.isDate == true">{{deposito[col.field] | formatoFecha}}</span>
                            <span *ngIf="col.isCurrency == true">{{deposito[col.field] | currency}}</span>
                            <span *ngIf="col.notFormat == true">{{deposito[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-8 p-xl-8 p-col-12">
            <p-table [value]="estacionalidades" [columns]="columnEst" styleClass="result result-list"
                [scrollable]="true" scrollHeight="35vh" [virtualScroll]="false" selectionMode="multiple"
                [(selection)]="estacionalidadesSelect" (onRowSelect)="sumandoAcumulado($event)"
                (onRowUnselect)="restandoAcumulado($event)">
                <ng-template pTemplate="caption">
                    Estacionalidades
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.sortName ? col.sortName : col.field">
                            {{col.header}}<p-sortIcon [field]="col.sortName ? col.sortName : col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-estacionalidad let-columns="columns" let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="estacionalidad" [pSelectableRowIndex]="rowIndex">
                        <td *ngFor="let col of columns">
                            <!-- <span class="p-column-title">{{col.header}}</span> -->
                            <span *ngIf="col.isCurrency == true">{{estacionalidad[col.field] | currency}}</span>
                            <span *ngIf="col.isPercent == true">{{estacionalidad[col.field] | percent}}</span>
                            <span *ngIf="col.isMesAnio == true">{{estacionalidad[col.field] | formatoMesAnio}}</span>
                            <span *ngIf="col.notFormat == true">{{estacionalidad[col.field]}}</span>

                            <span
                                *ngIf="col.isFacturaNotFormat == true">{{estacionalidad['idFactura'][col.field]}}</span>
                            <span *ngIf="col.isFacturaCurrency == true">{{estacionalidad['idFactura'][col.field] |
                                currency}}</span>
                            <span *ngIf="col.isFacturaDate == true">{{estacionalidad['idFactura'][col.field] |
                                formatoFecha}}</span>
                            <span *ngIf="col.isAnexo == true">{{estacionalidad['anexo'][col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <br>
    <br>
    <div class="p-grid">
        <div class="p-col-12">
            <p-table [value]="contenidoBalance">
                <ng-template pTemplate="caption">
                    Balance
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            Concepto
                        </th>
                        <th>
                            Monto
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contenido>
                    <tr>
                        <td>
                            {{contenido.concepto}}
                        </td>
                        <td *ngIf="contenido.isDeposito == true" id="depositoTd">
                            {{contenido.monto | currency}}
                        </td>
                        <td *ngIf="contenido.isEstacionalidad == true" id="estacionalidadTd">
                            {{contenido.monto | currency}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
            [style]="{'padding-left':'20px', 'padding-right':'20px', 'justify-content': 'right', 'display': 'flex', 'margin-top': '50px'}">
            <br>
            <br>
            <p-button (onClick)="conciliar()" label="Conciliar" icon="pi pi-link" [loading]="showProgress">
            </p-button>
        </div>
    </div>

</ng-container>

<p-toast></p-toast>