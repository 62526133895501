import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { Depositos } from 'src/app/api/depositos';
import { Conciliacion, Estacionalidad, EstacionalidadDesgloce } from 'src/app/api/estacionalidad';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstacionalidadRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msEstacionalidad;
    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.detail = "";
    paths.remove = "";
    paths["porcentIva"] = "porcIva";
    paths["desgloce"] = "desgloce";
    paths["conciliar"] = "conciliar";
    paths["actualizarEstacionalidades"] = "actualizar-estacionalidades";

    return paths;
  }

  porcentajeIva(): Observable<number> {
    let path = this.getBaseEndpoint() + this.getRestPaths()["porcentIva"];

    if (!path.endsWith("/")) {
      path += "/"
    }
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<number>;
  }

  desgloce(idEstacionalidad: number): Observable<EstacionalidadDesgloce> {
    let path = this.getBaseEndpoint() + this.getRestPaths()["desgloce"] + "/" + idEstacionalidad;

    if (!path.endsWith("/")) {
      path += "/";
    }
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<EstacionalidadDesgloce>;
  }

  conciliar(item: Conciliacion): Observable<Estacionalidad[]> {
    let path = this.getBaseEndpoint() + this.getRestPaths()["conciliar"] + "/";

    if (!path.endsWith("/")) {
      path += "/";
    }

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, item) as Observable<Estacionalidad[]>;
  }

  actualizarEstacionalidades(items: Estacionalidad[]): Observable<Estacionalidad[]> {
    let path = this.getBaseEndpoint() + this.getRestPaths()["actualizarEstacionalidades"] + "/";

    if (!path.endsWith("/")) {
      path += "/";
    }

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, items) as Observable<Estacionalidad[]>;
  }
}
