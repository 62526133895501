import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { EmpresaClienteRestcService } from 'src/app/client/cliente/empresa-cliente-restc.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresaClientePsService extends BaseProcess<EmpresaClienteRestcService>{

  constructor(httpClient: HttpClient,
    crest: EmpresaClienteRestcService) {

    super(httpClient, crest);

  }
}
