<p-dialog header="Estacionalidades" [(visible)]="showDialog" position="top" [modal]="true" [maximizable]="true"
    [style]="{width: '70vw'}" [baseZIndex]="10000" (onHide)="ocultarCard()">
    <!-- {{items | json}} -->
    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
            [style]="{'justify-content': 'right', 'display': 'flex', 'align-items': 'center'}">
            <p-button type="button" (click)="insertar()" label="Agregar">
            </p-button>
        </div>
    </div>
    <div></div>
    <ng-container *ngFor="let item of items">
        <br>
        <p-card [style]="{'border-top': 'outset 1px #CACFD2', 
                        'border-left': 'outset 1px #CACFD2',
                        'border-right': 'outset 1px #e4e0e0',
                        'border-bottom': 'outset 1px #CACFD2'}">
            <div class="p-grid" [style]="{'padding-left':'20px', 'padding-right':'20px'}">

                <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">

                    <br>
                    <span class="p-float-label">
                        <p-calendar view="month" dateFormat="mm/yy" dataType="string" [yearNavigator]="true"
                            [yearRange]="yearRange" [readonlyInput]="true" inputId="fl-cal" showIcon="true"
                            [style]="{'width':'90%'}" (onSelect)="fecha($event, item)" [touchUI]="true"
                            [panelStyle]="{'min-width':'30vw', 'min-height':'25vh'}"></p-calendar>
                        <label for="fl-cal">Fecha</label>
                    </span>
                </div>
                <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">

                    <br>
                    <span class="p-float-label">
                        <p-inputNumber inputId="fl-subtotal" [(ngModel)]="item.subtotal" mode="currency" currency="MXN"
                            locale="es-MX" [style]="{'width':'80%'}">
                        </p-inputNumber>
                        <label for="fl-subtotal">Monto</label>
                    </span>
                </div>
                <div class="p-sm-12 p-md-2 p-lg-2 p-xl-2 p-col-12"
                    [style]="{'justify-content': 'right', 'display': 'flex', 'align-items': 'center'}">

                    <br>
                    <p-button type="button" (click)="quitar(item)" icon="pi pi-times">
                    </p-button>
                </div>
            </div>
        </p-card>
    </ng-container>

    <div class="p-grid">
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
            [style]="{'justify-content': 'right', 'display': 'flex', 'margin-top': '50px'}">
            <br>
            <br>
            <p-button *ngIf="enableButtonGuardarEstac" id="btnGuardar" type="button" (click)="guardar()" label="Guardar">
            </p-button>
            <p-button *ngIf="enableButtonAgregarEstac" id="btnAgregarEstac" type="button" (click)="agregarEstacionalidades()" label="Agregar Estacionalidades">
            </p-button>
        </div>
    </div>

    <p-toast key="msgSuma"></p-toast>
    <p-toast key="msgError"></p-toast>
</p-dialog>