import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo } from 'src/app/api/anexo';
import { Empresa } from 'src/app/api/cliente';
import { Estacionalidad } from 'src/app/api/estacionalidad';
import { EmpleadoNombre } from '../../../api/cliente';
import { EstatusAnexo } from '../../../api/anexo';
import { EstatusEstacionalidad } from '../../../api/estacionalidad';

@Component({
  selector: 'axks-estacionalidad-gestion-form',
  templateUrl: './estacionalidad-gestion-form.component.html',
  styleUrls: ['./estacionalidad-gestion-form.component.scss']
})
export class EstacionalidadGestionFormComponent extends BaseView implements OnInit {

  @Input() item: Estacionalidad;
  @Input() catProyectos: Anexo[];
  @Input() catEmpresasClientes: Empresa[];
  @Input() catLideres: EmpleadoNombre[];
  @Input() catEstatusAnexo: EstatusAnexo[];
  @Input() catEstatusEstacionalidad: EstatusEstacionalidad[];

  @Input() sumadoSubTotal: number;
  @Input() sumadoTotal: number;

  mostrarFondoOverPanel: boolean;

  yearRange: string;
  
  OP_MODE = OperationMode;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        anexo: {
          idEmpresaCliente: {},
          estatusAnexo: {},
          idLiderReg: {},
          idCliente: {}
        },
        idFactura: {}
      };
    }

    this.mostrarFondoOverPanel = false;

    this.initDates();
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 10) + ':' + (year + 10);
  }

  switchFondoOverPanel(value: boolean) {
    this.mostrarFondoOverPanel = value;
  }

}
