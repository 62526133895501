import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { DistMontoVirtualRestcService } from '../../client/dist-monto-virtual/dist-monto-virtual-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DistMontoVirtualPsService extends BaseProcess<DistMontoVirtualRestcService>{

  constructor(httpClient: HttpClient, crest: DistMontoVirtualRestcService) {
    super(httpClient, crest);
  }
}
