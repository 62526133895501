<!-- {{item | json}} -->

<div class="contenedor-boton-filtro">
    <button type="text" pButton icon="pi pi-filter" (click)="op.toggle($event)" pTooltip="Filtros de busqueda" [style]="{'z-index':'10000'}"></button>
</div>
<br>
<div class="contenedor-cantidades">
    <div class="p-grid grid-cantidades">
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12 contenedor-monto-subtotal">
            <span class="formato-label">SubTotal</span>
            <span class="formato-monto">{{sumadoSubTotal | currency}}</span>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12 contenedor-monto-total">
            <span class="formato-label">Total</span>
            <span class="formato-monto">{{sumadoTotal | currency}}</span>
        </div>
    </div>
</div>

<div *ngIf="mostrarFondoOverPanel == true" class="fondo-overpanel"></div>

<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="false" (onShow)="switchFondoOverPanel(true)" (onHide)="switchFondoOverPanel(false)">
    <ng-template pTemplate>
        <div class="p-grid">
            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('clienteField')">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="fl-proyecto" [(ngModel)]="item.idAnexo" [autoDisplayFirst]="false"
                        [options]="catProyectos" filterBy="nombre" [filter]=true optionLabel="nombre" optionValue="id"
                        showClear="true" [style]="{'width':'80%'}"></p-dropdown>
                    <label for="fl-proyecto">
                        <span *ngIf="operationMode != OP_MODE.SEARCH" style="color: red; font-weight: bold;">*
                        </span>
                        Proyecto
                    </label>
                </span>
            </div>

            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('numAnexoField')">
                <br>
                <span class="p-float-label">
                    <input id="fl-numAnx" type="text" pInputText [(ngModel)]="item.anexo.numAnexo"
                        [style]="{'width':'80%'}">
                    <label for="fl-numAnx">Numero de anexo</label>
                </span>
            </div>

            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('cvPresupuestalField')">
                <br>
                <span class="p-float-label">
                    <input id="fl-cvPr" type="text" pInputText [(ngModel)]="item.anexo.cvPresupuestal"
                        [style]="{'width':'80%'}">
                    <label for="fl-cvPr">Clave presupuestal</label>
                </span>
            </div>

            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('estatusAnexoField')">
                <br>
                <span class="p-float-label">
                    <p-dropdown id="fl-estAnx" [options]="catEstatusAnexo" [(ngModel)]="item.anexo.estatusAnexo.id"
                        [showClear]="true" optionLabel="estatusAnexo" optionValue="id" [autoDisplayFirst]="false"
                        [filter]="true" filterBy="estatusAnexo" [style]="{'width': '80%'}"></p-dropdown>
                    <label for="fl-estAnx">Estatus proyecto</label>
                </span>
            </div>

            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('montoField')">
                <br>
                <span class="p-float-label">
                    <p-inputNumber [(ngModel)]="item.subtotal" mode="currency" currency="MXN" locale="es-MX" [min]="1"
                        [style]="{'width':'80%'}">
                    </p-inputNumber>
                    <label for="fl-monto">
                        <span *ngIf="operationMode != OP_MODE.SEARCH" style="color: red; font-weight: bold;">*
                        </span>
                        Monto
                    </label>
                </span>
            </div>

            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('clienteField')">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="fl-cliente" [(ngModel)]="item.anexo.idEmpresaCliente.id"
                        [autoDisplayFirst]="false" [options]="catEmpresasClientes" filterBy="nombre" [filter]=true
                        optionLabel="nombre" optionValue="id" showClear="true" [style]="{'width':'80%'}">
                    </p-dropdown>
                    <label for="fl-cliente">
                        <span *ngIf="operationMode != OP_MODE.SEARCH" style="color: red; font-weight: bold;">*
                        </span>
                        Empresa cliente
                    </label>
                </span>
            </div>

            <div class="p-sm-6 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecIniField')">
                <br>
                <span class="p-float-label">
                    <p-calendar [(ngModel)]="item.fechaInicio" [showIcon]="true" dateFormat="mm/yy" inputId="fl-fecIni"
                        [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true" [style]="{'width':'80%'}"
                        view="month" [readonlyInput]="true">
                    </p-calendar>
                    <label for="fl-fecIni">
                        <span *ngIf="operationMode != OP_MODE.SEARCH" style="color: red; font-weight: bold;">*
                        </span>
                        Fecha inicial
                    </label>
                </span>
            </div>

            <div class="p-sm-6 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecFinField')">
                <br>
                <span class="p-float-label">
                    <p-calendar [(ngModel)]="item.fechaFin" [showIcon]="true" dateFormat="mm/yy" inputId="fl-fecIni"
                        [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true" [style]="{'width':'80%'}"
                        view="month" [readonlyInput]="true">
                    </p-calendar>
                    <label for="fl-fecIni">
                        Fecha final
                    </label>
                </span>
            </div>

            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('liderField')">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="fl-lider" [(ngModel)]="item.anexo.idLiderReg.id" [autoDisplayFirst]="false"
                        [options]="catLideres" filterBy="nombre" [filter]=true optionLabel="nombre"
                        optionValue="idEmpleado" showClear="true" [style]="{'width':'80%'}"></p-dropdown>
                    <label for="fl-lider">Lideres</label>
                </span>
            </div>

            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('estatusAnexoField')">
                <br>
                <span class="p-float-label">
                    <p-dropdown id="fl-estAnx" [options]="catEstatusEstacionalidad"
                        [(ngModel)]="item.idEstatusEstacionalidad" [showClear]="true"
                        optionLabel="estatusEstacionalidad" optionValue="id" [autoDisplayFirst]="false" [filter]="true"
                        filterBy="estatusEstacionalidad" [style]="{'width': '80%'}"></p-dropdown>
                    <label for="fl-estAnx">Estatus estacionalidad</label>
                </span>
            </div>

            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
                *ngIf="operationMode == OP_MODE.ADDNEW || operationMode == OP_MODE.EDIT"
                style="display: flex; align-items: normal;">
                <span style="color:red">*</span>&nbsp;<span style="color: red; font-size: 0.7rem;"> Campos
                    requeridos</span>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>