import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColaboradorExternoRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msColaboradorExterno;
    if(!path.endsWith("/")){
      path+="/";
    }
    return path;
  }

  getRestPaths(): RestBasicPaths {
      let paths = super.getRestPaths();
      paths.detail = "";
      paths.remove = "";

      return paths;
  }

  processBody(res: any) {
    return res || {};
  }
}
