import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConciliacionRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msConciliacion;
    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.detail = "";
    paths.remove = "";
    return paths;
  }

  deleteConciliacion(id: number, idUserEmp: number): Observable<Message> {
    let path = this.getBaseEndpoint() + id + "&" + idUserEmp;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.delete(path, { headers }) as Observable<Message>;
  }
}
