import { Component, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { DistMontoVirtualReg, MontoVirtualReg, MontoVirtual } from '../../../api/monto-virtual';
import { EmpleadoNombre } from '../../../api/cliente';
import { EmpleadoPsService } from '../../../procs/empleado/empleado-ps.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { MontoVirtualPsService } from '../../../procs/monto-virtual/monto-virtual-ps.service';
import { DistMontoVirtualPsService } from '../../../procs/dist-monto-virtual/dist-monto-virtual-ps.service';

@Component({
  selector: 'axks-monto-virtual-reg',
  templateUrl: './monto-virtual-reg.component.html',
  styleUrls: ['./monto-virtual-reg.component.scss']
})
export class MontoVirtualRegComponent extends BaseView implements OnInit {

  itemMonto: MontoVirtualReg;
  itemMontoResp: MontoVirtual;
  distribucionesReg: DistMontoVirtualReg[];
  catEmpleados: EmpleadoNombre[];
  profile = this.getProfile() as ProfileC;
  cols: any[];
  yearRange: string;
  showTable: boolean = false;
  disabledMonto: boolean = false;
  labelMonto: string;
  showAddButton: boolean = true;
  fechaMin: Date;
  fechaMax: Date;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoPsService,
    private clientMontoVirtual: MontoVirtualPsService,
    private clientDistMontoVirtual: DistMontoVirtualPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.itemMonto) {
      this.itemMonto = {
        idEmpReg: this.profile.idEmpleado
      };
    }

    if (!this.distribucionesReg) {
      this.distribucionesReg = [];
    }

    if (!this.showTable) {
      this.showTable = false;
    }

    if (!this.disabledMonto) {
      this.disabledMonto = false;
    }

    if (!this.labelMonto) {
      this.labelMonto = "Agregar monto";
    }

    if (!this.showAddButton) {
      this.showAddButton = true;
    }

    this.initCatEmpleados();

    this.cols = [
      { header: 'Empleado' },
      { header: 'Monto' },
      { header: 'Concepto' },
      { header: 'Fecha' },
      { header: '' }
    ];

    this.initDates();
  }

  initCatEmpleados() {
    this.clientEmp.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data
      },
      (error) => {
        this.messageService.add({
          summary: 'Error catalogo empleados',
          detail: 'No se pudo obtener el catalogo de empleados con contrato activo',
          severity: 'error'
        });
      }
    );
  }


  agregarMonto() {
    if (!this.itemMonto.monto || this.itemMonto.monto <= 0) {
      this.infoMessage("Monto Virtual", "Favor de agregar un monto valido.");
    } else {
      this.showTable = true;
      this.disabledMonto = true;
      this.showAddButton = false;
    }
  }

  editarMonto() {
    this.disabledMonto = false;
    this.showAddButton = true;
    this.showTable = false;
  }

  agregarDistribucion() {
    this.distribucionesReg.push({
      idMontoVirtual: {},
      empReg: this.profile.idEmpleado
    });
  }

  quitarDistribucion(item: DistMontoVirtualReg) {
    this.distribucionesReg.splice(this.distribucionesReg.indexOf(item), 1);
  }

  initDates() {
    let fechaActual = new Date();
    let fechaIni: Date;
    let fechaFin: Date;
    let anioActual = fechaActual.getFullYear();

    if (fechaActual < new Date(anioActual + "/02/01")) {
      this.fechaMin = new Date(anioActual - 1 + "/02/01");
      this.fechaMax = new Date(anioActual - 1 + "/02/01");
      this.fechaMax.setFullYear(this.fechaMax.getFullYear() + 1);
      this.fechaMax.setDate(this.fechaMax.getDate() - 1);

      this.yearRange = (anioActual - 1) + ":" + (anioActual);
    }
    else {
      this.fechaMin = new Date(anioActual + "/02/01");
      this.fechaMax = new Date(anioActual + "/02/01");
      this.fechaMax.setFullYear(this.fechaMax.getFullYear() + 1);
      this.fechaMax.setDate(this.fechaMax.getDate() - 1);

      this.yearRange = (anioActual) + ':' + (anioActual + 1);
    }
  }

  fecha(event, item: DistMontoVirtualReg) {
    let fechaT = event as Date;

    item.mes = fechaT.getMonth() + 1;
    item.anio = fechaT.getFullYear();
  }

  initPantalla() {
    this.itemMonto = {
      idEmpReg: this.profile.idEmpleado
    };

    this.itemMontoResp = {};

    this.distribucionesReg = [];
    this.disabledMonto = false;
    this.showAddButton = true;
    this.showTable = false;
  }

  guardarDistribucion() {
    let acumulado = 0;
    for (let distItem of this.distribucionesReg) {
      acumulado += distItem.monto;
    }
    if (this.distribucionesReg.length > 0) {
      if (acumulado == this.itemMonto.monto) {
        this.showProgress = true;
        this.clientMontoVirtual.register(this.itemMonto).subscribe(
          (data) => {
            this.itemMontoResp = data;
            for (let itemDist of this.distribucionesReg) {
              itemDist.idMontoVirtual.id = this.itemMontoResp.id;
              this.clientDistMontoVirtual.register(itemDist).subscribe(
                (data) => {
                },
                (error) => {
                  this.errorMessage("Error de registro", "Ocurrio un error al intentar registrar la distribucion del monto virtual. Verifique que esten todos los datos necesarios.");
                }
              );
            }
            this.showProgress = false;
            this.initPantalla();
            this.successMessage("Registro exitoso", "Se registro de manera correcta la distribucion del monto virtual");
          },
          (error) => {
            this.showProgress = false;
            this.errorMessage("Error de registro", "Ocurrio un error al intentar registrar el monto virtual total. Verifique que esten todos los datos necesarios.");
          }
        );
      }
      else {
        this.warningMessage("Error montos", "La suma de las distribuciones no coincide con el monto virtual total. Favor de verificar que cuadre.");
      }
    }
    else {
      this.infoMessage("Monto Virtual", "Favor de agregar al menos un registro para la distribucion del monto virtual.")
    }
  }

}
