<!-- {{item | json}} -->
<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('nombreProyectoField')">
        <br>
        <span class="p-float-label">
            <input id="fl-nombreP" type="text" pInputText [(ngModel)]="item.nombre">
            <label for="fl-nombreP">
                <span *ngIf="operationMode != op.SEARCH" style="color: red; font-weight: bold;">* </span>
                Nombre del proyecto
            </label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('numAnexoField')">
        <br>
        <span class="p-float-label">
            <input id="fl-numAnx" type="text" pInputText [(ngModel)]="item.numAnexo">
            <label for="fl-numAnx">Numero de anexo</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('cvPresupuestalField')">
        <br>
        <span class="p-float-label">
            <input id="fl-cvPr" type="text" pInputText [(ngModel)]="item.cvPresupuestal">
            <label for="fl-cvPr">Clave presupuestal</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('montoField')">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.monto" mode="currency" currency="MXN" locale="es-MX" [min]="1">
            </p-inputNumber>
            <label for="fl-monto">
                <span *ngIf="operationMode != op.SEARCH" style="color: red; font-weight: bold;">* </span>
                Monto
            </label>
        </span>
    </div>
    <div class="p-sm-12p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('clienteField')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-cliente" [(ngModel)]="item.idEmpresaCliente.id" [autoDisplayFirst]="false"
                [options]="catEmpresasClientes" filterBy="nombre" [filter]=true optionLabel="nombre" optionValue="id"
                showClear="true" [style]="{'width':'80%'}"></p-dropdown>
            <label for="fl-cliente">
                <span *ngIf="operationMode != op.SEARCH" style="color: red; font-weight: bold;">* </span>
                Empresa cliente
            </label>
        </span>
    </div>
    <div class="p-sm-12p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('liderField')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-lider" [(ngModel)]="item.idLiderReg.id" [autoDisplayFirst]="false"
                [options]="catLideres" filterBy="nombre" [filter]=true optionLabel="nombre" optionValue="idEmpleado"
                showClear="true" [style]="{'width':'80%'}"></p-dropdown>
            <label for="fl-lider">Lideres</label>
        </span>
    </div>
    <div class="p-sm-6 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecIniField')">
        <br>
        <span class="p-float-label">
            <p-calendar [(ngModel)]="item.fecIni" [showIcon]="true" dateFormat="yy/mm/dd" dataType="string"
                inputId="fl-fecIni" [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true"></p-calendar>
            <label for="fl-fecIni">
                <span *ngIf="operationMode != op.SEARCH" style="color: red; font-weight: bold;">* </span>
                Fecha inicial
            </label>
        </span>
    </div>
    <div class="p-sm-6 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('fecFinField')">
        <br>
        <span class="p-float-label">
            <p-calendar [(ngModel)]="item.fecFin" [showIcon]="true" dateFormat="yy/mm/dd" dataType="string"
                inputId="fl-fecIni" [yearNavigator]="true" [yearRange]="yearRange" [monthNavigator]="true"></p-calendar>
            <label for="fl-fecIni">
                Fecha final
            </label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('porcComisionField')">
        <br>
        <span class="p-float-label">
            <p-inputNumber inputId="fl-porc" [(ngModel)]="item.porcComision" suffix=" %">
            </p-inputNumber>
            <label for="fl-porc">Porcentaje</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('estatusAnexoField')">
        <br>
        <span class="p-float-label">
            <p-dropdown id="fl-estAnx" [options]="catEstatusAnexo" [(ngModel)]="item.estatusAnexo.id" [showClear]="true"
                optionLabel="estatusAnexo" optionValue="id" [autoDisplayFirst]="false" [filter]="true"
                filterBy="estatusAnexo" [style]="{'width': '80%'}"></p-dropdown>
            <label for="fl-estAnx">Estatus</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('estadoDistribucionField')">
        <br>
        <span class="p-float-label">
            <p-dropdown id="fl-estAnx" [options]="catEstadosDistribucion" [(ngModel)]="item.estadoDistribucion"
                [showClear]="true" [autoDisplayFirst]="false" [style]="{'width': '80%'}"></p-dropdown>
            <label for="fl-estAnx">Estado distribución</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == op.ADDNEW || operationMode == op.EDIT"
        style="display: flex; align-items: normal;">
        <span style="color:red">*</span>&nbsp;<span style="color: red; font-size: 0.7rem;"> Campos requeridos</span>
    </div>
</div>