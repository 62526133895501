import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo } from 'src/app/api/anexo';
import { Estacionalidad } from 'src/app/api/estacionalidad';
import { EstacionalidadPsService } from 'src/app/procs/estacionalidad/estacionalidad-ps.service';
import { AnexoPsService } from '../../../procs/anexo/anexo-ps.service';

@Component({
  selector: 'axks-edit-estacionalidad-form',
  templateUrl: './edit-estacionalidad-form.component.html',
  styleUrls: ['./edit-estacionalidad-form.component.scss']
})
export class EditEstacionalidadFormComponent extends BaseView implements OnInit {

  @Input() idAnexo: number;
  @Input() profile: ProfileC;
  @Input() mostrarBotonGuardar: boolean = true;

  item: Anexo;
  anexoOriginal: Anexo;
  montoAnexo: number;
  isLoading: boolean;

  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() recargarDetalle = new EventEmitter<boolean>();

  /** Bandera para definir rango de fechas en el calendario */
  yearRange: string;

  constructor(screenModeService: OperationScreenModeService,
    deviceService: DeviceDetectorService,
    messageService: MessageService,
    sessionProvier: SessionClientProvider,
    private psAnexo: AnexoPsService,
    private ps: EstacionalidadPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);

  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {
        estacionalidades: [],
        participantes: [],
        idCliente: {},
        idEmpresaCliente: {},
        idLiderReg: {},
        estatusAnexo: {}
      };
    }

    this.profile = this.getProfile();
    this.initDetalleAnexo();
    this.initDates();
  }

  initDetalleAnexo() {
    this.showProgress = true;
    this.psAnexo.detail(this.idAnexo).subscribe(
      (data) => {
        this.anexoOriginal = data as Anexo;
        this.item = this.anexoOriginal;

        if (this.item.estacionalidades && this.item.estacionalidades.length > 0) {
          for (let item of this.item.estacionalidades) {
            item.fechaEst = item.mes + "/" + item.anio;
            item.idUsrEmp = this.profile.idEmpleado;
          }
        }
        else {
          this.item.estacionalidades = [{
            idAnexo: this.item.id,
            idUsrEmp: this.profile.idEmpleado
          }];
        }

        this.montoAnexo = this.anexoOriginal.monto;
        this.showProgress = false;
      },
      (error) => {
        this.showProgress = false;

        this.errorMessage("Error", "No se pudo recuperar los datos");
      }
    );
  }


  /** 
   * Inicializa el rango de años que sera llenado en this.yearRange.
   */
  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 10) + ':' + (year + 10);
  }

  /**
   * Funcion para insertar en el arreglo de los items un nuevo elemento.
   */
  insertar() {

    this.item.estacionalidades.push({
      idAnexo: this.item.id,
      idUsrEmp: this.profile.idEmpleado
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitar(item: Estacionalidad) {
    this.item.estacionalidades.splice(this.item.estacionalidades.indexOf(item), 1);
  }

  /**
   * Funcion para dividir la fecha en mes y año e introducirlos dentro de los campos del item.
   * 
   * @param event Fecha obtenida del evento al seleccionar cierta fecha del calendar.
   * @param item Elemento del arreglo donde se almacenaran los datos obtenidos.
   */
  fecha(event, item: Estacionalidad) {

    let fechaT = event as Date;

    item.mes = fechaT.getMonth() + 1;
    item.anio = fechaT.getFullYear();

  }

  /**
   * Funcion que manda a llamar el servicio de guardado de las estacionalidades.
   * 
   */
  guardar() {

    // Se valida que la suma del total de las estacionalidades sea igual al monto del anexo
    let acumulado = 0 as number;
    for (let item of this.item.estacionalidades) {
      acumulado += item.subtotal;
    }

    // Redondeamos a dos decimales
    acumulado = parseFloat(acumulado.toFixed(2));

    if (this.montoAnexo == acumulado) {
      // Se manda a llamar el servicio para registar las estacionalidades
      this.isLoading = true;
      this.ps.actualizarEstacionalidades(this.item.estacionalidades).subscribe(

        // En caso de que el servicio proceda correctamente
        (data) => {
          this.messageService.add({ severity: 'success', summary: 'Estacionalidades guardadas correctamente.', detail: '' });
          this.isLoading = false;
          this.hideDialog.emit(false);
          this.recargarDetalle.emit(true);
        },
        // En caso de que el servicio mande algun error de respuesta
        (error) => {
          this.messageService.add({ key: "msgError", severity: 'error', summary: 'No se pudo registrar las estacionalidades.', detail: '' });
          this.isLoading = false;
        }
      );
    }
    else {
      this.messageService.add({ key: 'msgSuma', severity: 'warn', summary: 'Atencion.', detail: 'La suma de las estacionalidades no coincide con el monto del anexo, favor de verificar.' });
    }
  }

  /**
   * Funcion que emite una bandera en falso para ocultar la carta desde el componente principal
   */
  ocultarCard() {
    this.hideDialog.emit(false);
  }
}
