import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { BonoRestcService } from '../../client/bono/bono-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BonoPsService extends BaseProcess<BonoRestcService>{

  constructor(httpClient: HttpClient, crest: BonoRestcService) {
    super(httpClient, crest);
  }
}
