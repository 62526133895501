import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { BonoPsService } from '../../../procs/bono/bono-ps.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { BonoMonto } from '../../../api/bono';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'axks-bono',
  templateUrl: './bono.component.html',
  styleUrls: ['./bono.component.scss']
})
export class BonoComponent extends BaseMainComponent<BonoPsService> implements OnInit {

  profile?: ProfileC = this.getProfile();

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientBono: BonoPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.profile) {
      this.profile = this.getProfile();
    }
  }

  initEmptyItem(): ComponentItem {
    let emptyItem = {
      item: {} as BonoMonto,
      minimumItem: {} as BonoMonto,
      registerItem: {
        empReg: this.profile.idEmpleado
      } as BonoMonto
    } as ComponentItem;

    return emptyItem;
  }

  getService(): BonoPsService {
    return this.clientBono;
  }

  getColListDefinition(): ColumnDefinition[] {
    let cols = [
      { field: 'id', header: 'ID', inAll: true },
      { field: 'monto', header: 'Monto', inAll: true, formatFunction: this.formatoMoneda },
      { field: 'anio', header: 'Año', inAll: true },
      { field: 'fecReg', header: 'Fecha de registro', inAll: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return cols;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Bonos';
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }

  formatoFecha(date: string): string {
    let fechaDate = new Date(date);
    let mes: string;
    switch (fechaDate.getMonth()) {
      case 0: {
        mes = 'Enero';
        break;
      }
      case 1: {
        mes = 'Febrero';
        break;
      }
      case 2: {
        mes = 'Marzo';
        break;
      }
      case 3: {
        mes = 'Abril';
        break;
      }
      case 4: {
        mes = 'Mayo';
        break;
      }
      case 5: {
        mes = 'Junio';
        break;
      }
      case 6: {
        mes = 'Julio';
        break;
      }
      case 7: {
        mes = 'Agosto';
        break;
      }
      case 8: {
        mes = 'Septiembre';
        break;
      }
      case 9: {
        mes = 'Octubre';
        break;
      }
      case 10: {
        mes = 'Noviembre';
        break;
      }
      case 11: {
        mes = 'Diciembre';
        break;
      }
      default: {
        break;
      }
    }

    return (fechaDate.getDate() < 10 ? "0" + fechaDate.getDate() : fechaDate.getDate()) + " de " + mes + " de " + fechaDate.getFullYear();
  }
}
