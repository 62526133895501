import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoColaboradorExternoPsService } from 'src/app/procs/colaborador/tipo-colaborador-externo-ps.service';
import { ColaboradorExterno, TipoColabExterno } from 'src/app/api/colaborador-externo';
import { TipoColaboradorExternoRestcService } from 'src/app/client/colaborador/tipo-colaborador-externo-restc.service';

@Component({
  selector: 'axks-colaborador-externo-form',
  templateUrl: './colaborador-externo-form.component.html',
  styleUrls: ['./colaborador-externo-form.component.scss']
})
export class ColaboradorExternoFormComponent extends BaseView implements OnInit {

  @Input() item : ColaboradorExterno;
  catTipoColabExternos: TipoColabExterno[];

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider,
    private clientPS: TipoColaboradorExternoRestcService) { 
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {    
    if(!this.item){
      this.item = {};
    }
    
    this.initTipoColabExterno();
  }

  initTipoColabExterno() {
    let filtro = {} as TipoColabExterno;
    this.clientPS.search(filtro).subscribe(
      (data) => {
        this.catTipoColabExternos = data;
      },
      (erro) => {
        this.messageService.add({ severity: 'error', summary: 'No se pudo encontrar los tipos de colaboradores externos.', detail: '' })
      }
    );
  }

}
