import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { Anexo } from 'src/app/api/anexo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnexoRestcService extends ARestClient {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msAnexo + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let restPaths = super.getRestPaths();
    restPaths.detail = "";
    restPaths.remove = "";
    return restPaths;
  }

  agregarContrato(item: Anexo): Observable<Anexo> {
    let path = this.getBaseEndpoint() + "agregar-contrato";
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, item, { headers }) as Observable<Anexo>;
  }

  cancelarProyecto(idAnexo: number, idUserEmp: number): Observable<Message> {
    
    let path = this.getBaseEndpoint() + "cancelar-proyecto";
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    const params = new HttpParams()
      .append("idAnexo", idAnexo.toString())
      .append("idUserEmp", idUserEmp.toString());

    return this.httpClient.get(path, { headers, params }) as Observable<Message>;
  }
}
