import { formatDate } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseView, Message, MessageCodes, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Estacionalidad } from 'src/app/api/estacionalidad';
import { EstacionalidadPsService } from 'src/app/procs/estacionalidad/estacionalidad-ps.service';

/**
 * @author Pablo Cordero
 */
@Component({
  selector: 'axks-estacionalidad-form',
  templateUrl: './estacionalidad-form.component.html',
  styleUrls: ['./estacionalidad-form.component.scss']
})
export class EstacionalidadFormComponent extends BaseView implements OnInit {

  @Input() items: Estacionalidad[];
  @Input() showDialog: boolean = true;
  @Input() idAnexo: number;
  @Input() montoTotal: number;
  @Input() profile: ProfileC;
  @Input() enableButtonGuardarEstac: boolean = false;
  @Input() enableButtonAgregarEstac: boolean = false;

  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() recargarDetalle = new EventEmitter<boolean>();
  @Output() itemsOut = new EventEmitter<Estacionalidad[]>();

  /** Bandera para definir rango de fechas en el calendario */
  yearRange: string;

  constructor(screenModeService: OperationScreenModeService,
    deviceService: DeviceDetectorService,
    messageService: MessageService,
    sessionProvier: SessionClientProvider,
    private ps: EstacionalidadPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);

  }

  ngOnInit(): void {

    this.profile = this.getProfile();

    setTimeout(() => {
      this.initDates();
      if(!this.items){
        this.items = [
          {
            idAnexo: this.idAnexo,
            idUsrEmp: this.profile.idEmpleado,
          }
        ]
      }
    }, 2000);
  }

  /** 
   * Inicializa el rango de años que sera llenado en this.yearRange.
   */
  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 10) + ':' + (year + 10);
  }

  /**
   * Funcion para insertar en el arreglo de los items un nuevo elemento.
   */
  insertar() {

    this.items.push({
      idAnexo: this.idAnexo,
      idUsrEmp: this.profile.idEmpleado
    });
  }

  /**
   * Funcion para quitar un elemento del arreglo de items.
   * 
   * @param item Elemento a quitar del arreglo
   */
  quitar(item: Estacionalidad) {
    this.items.splice(this.items.indexOf(item), 1);
  }

  /**
   * Funcion para calcular el total del formulario conforme se llena el subtotal agregando el porcentaje de IVA establecido.
   * NOTA: Metodo no usado hasta que se establezca lo contrario
   * 
   * @param event Valor introducido del formulario.
   * @param item Elemento del arreglo donde sera calculado el total.
   */
  llenarTotal(event, item: Estacionalidad) {
    let porc = item.porcIva / 100;
    item.total = event.value * (1 + porc);
  }

  /**
   * Funcion para dividir la fecha en mes y año e introducirlos dentro de los campos del item.
   * 
   * @param event Fecha obtenida del evento al seleccionar cierta fecha del calendar.
   * @param item Elemento del arreglo donde se almacenaran los datos obtenidos.
   */
  fecha(event, item: Estacionalidad) {

    let fechaT = event as Date;

    item.mes = fechaT.getMonth() + 1;
    item.anio = fechaT.getFullYear();

  }

  /**
   * Funcion que manda a llamar el servicio de guardado de las estacionalidades.
   * 
   */
  guardar() {

    // Se valida que la suma del total de las estacionalidades sea igual al monto del anexo
    let acumulado = 0 as number;
    for (let item of this.items) {
      acumulado += item.subtotal;
    }

    acumulado = parseFloat(acumulado.toFixed(2));

    if (this.montoTotal == acumulado) {
      // Se manda a llamar el servicio para registar las estacionalidades
      this.ps.register(this.items).subscribe(

        // En caso de que el servicio proceda correctamente
        (data) => {
          this.messageService.add({ severity: 'success', summary: 'Estacionalidades guardadas correctamente.', detail: '' });
          this.hideDialog.emit(false);
          this.recargarDetalle.emit(true);
        },
        // En caso de que el servicio mande algun error de respuesta
        (error) => {
          this.messageService.add({ key: "msgError", severity: 'error', summary: 'No se pudo registrar las estacionalidades.', detail: '' });
        }
      )
    }
    else {
      this.messageService.add({ key: 'msgSuma', severity: 'warn', summary: 'Atencion.', detail: 'La suma de las estacionalidades no coincide con el monto del anexo, favor de verificar.' });
    }
  }

  /**
   * Funcion para agregar estacionalidades y mandarlas al componente padre
   */
  agregarEstacionalidades(){
    this.itemsOut.emit(this.items);
  }

  /**
   * Funcion que emite una bandera en falso para ocultar la carta desde el componente principal
   */
  ocultarCard() {
    this.hideDialog.emit(false);
  }

}
