<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
    </p-progressSpinner>
</div>

<div>
    <h3>Pago a colaboradores externos</h3>
</div>
<!-- {{filtro | json}} -->
<div class="cmp-form-1">

    <div class="p-grid frm-part">
        <div class="p-sm-6 p-md-6 p-lg-4 p-xl-3 p-col-6">
            <br>
            <span class="p-float-label">
                <p-calendar view="month" dateFormat="mm/yy" [yearNavigator]="true" [yearRange]="yearRange"
                    [readonlyInput]="true" inputId="fec-ini" [showIcon]="true" (onSelect)="configFechaInicio($event)"
                    pTooltip="El periodo de tiempo con el que se realice la busqueda traera resultados que coincidan con la fecha de pago programada y la fecha de pago realizada">
                </p-calendar>
                <label for="fec-ini">Mes inicio</label>
            </span>
        </div>

        <div class="p-sm-6 p-md-6 p-lg-4 p-xl-3 p-col-6">
            <br>
            <span class="p-float-label">
                <p-calendar view="month" dateFormat="mm/yy" [yearNavigator]="true" [yearRange]="yearRange"
                    [readonlyInput]="true" inputId="fec-fin" [showIcon]="true" (onSelect)="configFechaFin($event)"
                    pTooltip="El periodo de tiempo con el que se realice la busqueda traera resultados que coincidan con la fecha de pago programada y la fecha de pago realizada">
                </p-calendar>
                <label for="fec-fin">Mes fin</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
            <br>
            <span class="p-float-label">
                <p-dropdown inputId="fl-lider" [(ngModel)]="filtro.idLider" [autoDisplayFirst]="false"
                    [options]="lideres" filterBy="nombre" [filter]=true optionLabel="nombre" optionValue="idEmpleado"
                    showClear="true"></p-dropdown>
                <label for="fl-lider">Lider</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
            <br>
            <span class="p-float-label">
                <p-dropdown inputId="fl-estatus" [(ngModel)]="filtro.idEstadoPago" 
                    [autoDisplayFirst]="false" [options]="estadosPago" 
                    filterBy="nombreEstadoPago" [filter]=true
                    optionLabel="nombreEstadoPago" optionValue="idEstadoPago"
                    showClear="true"></p-dropdown>
                <label>Estatus</label>
            </span>
        </div>

        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
            <button pButton type="button" label="Buscar" icon="pi pi-search" (click)="obtenerPagoTerceros()"></button>
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
        <br>
        <p-table #tpt [value]="items" [paginator]="true" styleClass="result result-list" [scrollable]="true"
            [showCurrentPageReport]="true" [rows]="25" currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]"
            [globalFilterFields]="['nombreLider','idColabExterno.nombreCompleto', 'monto']">

            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <span class="p-mx-2 p-mr-sm-2 p-mr-md-auto p-mr-lg-auto p-ml-xl-2">
                        Pagos para
                        {{this.filtro.fechaInicio | date: "MMMM 'de' yyyy" : "America/Mexico_City" : "es-MX"}}
                        -
                        {{this.filtro.fechaFin | date: "MMMM 'de' yyyy" : "America/Mexico_City" : "es-MX"}}
                    </span>
                    <span class="p-mx-2 p-ml-sm-2 p-mr-lg-auto p-mr-xl-0 p-input-icon-left p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tpt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar" [style]="{'width':'100%'}" />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="idColabExterno.nombreCompleto">Colaborador externo<p-sortIcon
                            field="idColabExterno.nombreCompleto"></p-sortIcon>
                    </th>
                    <th pSortableColumn="monto">Monto<p-sortIcon field="monto"></p-sortIcon>
                    </th>
                    <th pSortableColumn="fecPagoProg">Fecha programada de pago<p-sortIcon field="fecPagoProg">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="fecPago">Fecha pago realizado<p-sortIcon field="fecPago"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nombreLider">Lider<p-sortIcon field="nombreLider"></p-sortIcon>
                    </th>
                    <th>Estatus</th>
                    <th>Acción</th>
                </tr>
                <tr>
                    <th colspan="1" class="p-text-right">Total</th>
                    <th>{{sumadoTotal | currency}}</th>
                    <th colspan="5"></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-value>
                <tr [pSelectableRow]="value">
                    <td>
                        <!-- <span class="p-column-title">Colaborador externo</span> -->
                        {{value.idColabExterno.nombreCompleto}}
                    </td>
                    <td>
                        <!-- <span class="p-column-title">Monto</span> -->
                        {{value.monto | currency}}
                    </td>
                    <td>
                        <!-- <span class="p-column-title">Fecha programada de pago</span> -->
                        {{value.fecPagoProg | date: "dd 'de' MMMM 'de' yyyy" : "America/Mexico_City" : "es-MX"}}
                    </td>
                    <td>
                        <!-- <span class="p-column-title">Fecha pago realizado</span> -->
                        {{value.fecPago | date: "dd 'de' MMMM 'de' yyyy" : "America/Mexico_City" : "es-MX"}}
                    </td>
                    <td>
                        <!-- <span class="p-column-title">Lider</span> -->
                        {{value.nombreLider}}
                    </td>
                    <td>
                        <!-- <span class="p-column-title">Estatus</span> -->
                        <ng-container *ngIf="value.fecPago != null; else elseEstatus">
                            Pagado
                        </ng-container>
                        <ng-template #elseEstatus>
                            Por pagar
                        </ng-template>
                    </td>
                    <td>
                        <!-- <span class="p-column-title" style="text-align: left;">Acción</span> -->
                        <ng-container *ngIf="value.fecPago != null; else elseAccion">
                            <div style="text-align: center;">
                                <button pButton type="button" label="Pagar" [disabled]="true"></button>
                            </div>
                        </ng-container>
                        <ng-template #elseAccion>
                            <div style="text-align: center;">
                                <button pButton type="button" label="Pagar" [disabled]="false"
                                    (click)="mostrarDialog(value)"></button>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<p-dialog [(visible)]="showDialog" header="Referencia de pago" [baseZIndex]="10000" [closable]="true" [modal]="true"
    [closeOnEscape]="true" (onHide)="showDialog = false" [draggable]="false" [style]="{width: '25%'}"
    [breakpoints]="{'1200px': '50vw', '992px': '50vw', '768px': '50vw', '576px': '100vw'}"
    [contentStyle]="{'height':'15vh'}" position="center">

    <div class="contenedor-progress-spinner" *ngIf="showProgress">
        <p-progressSpinner strokeWidth="8"></p-progressSpinner>
    </div>

    <div class="p-grid frm-resume" *ngIf="!showProgress">
        <div class="p-col-12">
            <div class="wz-preview-label">Referencia del depósito</div>
            <input type="text" class="wz-preview-value" inputId="fl-refPago" pInputText [(ngModel)]="referenciaPago"
                [style]="{'width':'100%'}" placeholder="Escribir la referencia de pago">
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (onClick)="aplicarPago(itemSelect.id)" label="Aplicar pago"></p-button>
    </ng-template>
</p-dialog>

<p-toast [baseZIndex]="20000"></p-toast>