<p-dialog header="Detalle Participaciones" [(visible)]="showDialog" position="center" [modal]="true" [maximizable]="true"
    [baseZIndex]="10000" (onHide)="ocultarCard()" [style]="{width: '40vw'}" [contentStyle]="{'min-height':'12vh'}"
    [breakpoints]="{'1200px': '60vw', '992px': '70vw', '768px': '80vw', '576px': '100vw'}">
    <!-- {{itemsVirtuales | json}} -->
    <div class="contenedor-progress-spinner" *ngIf="loadAnexos || loadVirtuales">
        <p-progressSpinner strokeWidth="8"></p-progressSpinner>
    </div>

    <ng-container *ngIf="showAnexos || showMontosVirtuales">
        <ng-container *ngFor="let item of items">
            <br>
            <p-card class="border-radius" [style]="{'border-top': 'outset 1px #CACFD2', 
                    'border-left': 'outset 1px #CACFD2',
                    'border-right': 'outset 1px #e4e0e0',
                    'border-bottom': 'outset 1px #CACFD2'}" header="Participación en proyecto">
                <div class="p-grid" [style]="{'padding-left':'20px', 'padding-right':'20px'}">
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-header">Nombre del proyecto: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-content">{{item.anexo.nombre}}</span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-header">Fecha de inicio: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-content">{{item.anexo.fecIni | formatoFecha}}</span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-header">Fecha de termino: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-content">{{item.anexo.fecFin | formatoFecha}}</span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-header">Cliente: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-content">{{item.anexo.idEmpresaCliente.nombre}}</span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-header">Monto de participación: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-content">{{item.participacion.monto | currency}}</span>
                    </div>
                </div>
            </p-card>
        </ng-container>
        <ng-container *ngFor="let itemVirt of itemsVirtuales">
            <br>
            <p-card class="border-radius" [style]="{'border-top': 'outset 1px #CACFD2', 
                    'border-left': 'outset 1px #CACFD2',
                    'border-right': 'outset 1px #e4e0e0',
                    'border-bottom': 'outset 1px #CACFD2'}" header="Participación virtual">
                <div class="p-grid" [style]="{'padding-left':'20px', 'padding-right':'20px'}">
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" *ngIf="itemVirt.descripcion">
                        <span class="span-header">Concepto: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" *ngIf="itemVirt.descripcion">
                        <span class="span-content">{{itemVirt.descripcion}}</span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-header">Fecha participación virtual: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-content">{{itemVirt.fecDist | formatoMesAnio}}</span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-header">Monto de participación: </span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6">
                        <span class="span-content">{{itemVirt.monto | currency}}</span>
                    </div>
                </div>
            </p-card>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!showAnexos && !showMontosVirtuales && !loadAnexos && !loadVirtuales">
        <p-card>
            <h1 style="display: flex; justify-content: center; text-align: center;">No hay registro de participaciones
                en este mes</h1>
        </p-card>
    </ng-container>

</p-dialog>