<!-- <p-progressSpinner *ngIf="!contentReady"
    [style]="{width: '50px', height: '50px', 'z-index': '1000', 'position':'absolute', 'left':'50%', 'top':'50%'}"
    styleClass="axks-progress-spinner" strokeWidth="8">
</p-progressSpinner> -->
<div class="progress container" *ngIf="!contentReady">
  <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner" strokeWidth="8">
  </p-progressSpinner>
</div>

<ng-container *ngIf="contentReady">
  <router-outlet></router-outlet>
</ng-container>