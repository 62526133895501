import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Anexo } from 'src/app/api/anexo';
import { Cliente, Empresa, ClienteCat } from 'src/app/api/cliente';
import { rol } from 'src/app/api/constantes/roles';
import { AnexoPsService } from 'src/app/procs/anexo/anexo-ps.service';
import { ClientePsService } from 'src/app/procs/cliente/cliente-ps.service';
import { EmpresaClientePsService } from 'src/app/procs/cliente/empresa/empresa-cliente-ps.service';

@Component({
  selector: 'axks-edit-cliente-form',
  templateUrl: './edit-cliente-form.component.html',
  styleUrls: ['./edit-cliente-form.component.scss']
})
export class EditClienteFormComponent extends BaseView implements OnInit {

  @Input() idAnexo: number;
  @Input() profile: ProfileC;
  @Input() catEmpClientes: Empresa[];
  @Input() clientes: Cliente[];
  @Input() catClientes: ClienteCat[];

  item: Anexo;
  clienteModel: ClienteCat;
  isLoading: boolean;

  @Output() hideDialog = new EventEmitter<boolean>();
  @Output() recargarDetalle = new EventEmitter<boolean>();

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientePs: ClientePsService,
    private empresaPs: EmpresaClientePsService,
    private anexoPs: AnexoPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);

  }

  ngOnInit(): void {
    if (!this.catClientes) {
      this.catClientes = [];
    }

    if (!this.item) {
      this.item = {
        estacionalidades: [],
        participantes: [],
        idCliente: {},
        idEmpresaCliente: {},
        idLiderReg: {},
        estatusAnexo: {}
      };
    }

    this.initCatalogoEmpresasClientes();
    this.initDetalleAnexo();

    this.clienteModel = {};
  }

  initDetalleAnexo() {
    this.showProgress = true;
    this.anexoPs.detail(this.idAnexo).subscribe(
      (data) => {
        this.item = data as Anexo;
        this.initCatalogoClientes(this.item.idEmpresaCliente.id);
        this.showProgress = false;
      },
      (error) => {
        this.showProgress = false;
        this.errorMessage("Error", "No se pudo recuperar los datos");
      }
    );
  }

  initCatalogoEmpresasClientes() {
    let filter = {} as Empresa;
    this.empresaPs.find(filter).subscribe(
      (data) => {
        this.catEmpClientes = data;
      },
      (error) => {
        this.messageService.add({ key: 'msgErrorEmp', severity: 'error', summary: 'No se pudo encontrar las empresas clientes', detail: '' })
      }
    );
  }

  initCatalogoClientes(idEmpresa: number) {
    let esLider = false;
    for(let rolItem of this.profile.rol){
      if(rolItem.idRol == rol.LIDER) {
        esLider = true;
      }
    }
    let filter = {
      idEmpresa: {
        id: idEmpresa
      },
      idEmpleado: {
        id: (esLider == true ? this.profile.idEmpleado : this.item.idLiderReg.id)
      }
    } as Cliente;
    this.clientePs.find(filter).subscribe(
      (data) => {
        this.clientes = data;
        for (let item of this.clientes) {
          this.catClientes.push({
            id: item.id,
            nombre: item.idPersona.nombre + " " + item.idPersona.apellidoPaterno + " " + item.idPersona.apellidoMaterno
          });
        }

        for (let itemCat of this.catClientes) {
          if (itemCat.id == this.item.idCliente.id) {
            this.clienteModel = itemCat
          }
        }
      },
      (error) => {
        this.messageService.add({ key: 'msgErrorClient', severity: 'error', summary: 'No se pudo encontrar los clientes', detail: '' });
      }
    );
  }

  llamarCatalogoClientes(idEmpresa: number) {
    let esLider = false;
    for(let rolItem of this.profile.rol){
      if(rolItem.idRol == rol.LIDER) {
        esLider = true;
      }
    }
    let filter = {
      idEmpresa: {
        id: idEmpresa
      },
      idEmpleado: {
        id: (esLider ? this.profile.idEmpleado : this.item.idLiderReg.id)
      }
    } as Cliente;
    this.clientePs.find(filter).subscribe(
      (data) => {
        this.clientes = data;
        for (let item of this.clientes) {
          this.catClientes.push({
            id: item.id,
            nombre: item.idPersona.nombre + " " + item.idPersona.apellidoPaterno + " " + item.idPersona.apellidoMaterno
          });
        }
      },
      (error) => {
        this.messageService.add({ key: 'msgErrorClient', severity: 'error', summary: 'No se pudo encontrar los clientes', detail: '' });
      }
    );
  }

  guardarIdEmpresa(event) {
    this.catClientes = [];
    this.clienteModel = {};
    this.llamarCatalogoClientes(event.value);
  }

  editarCliente() {
    if (this.clienteModel != null) {
      this.item.idCliente = {
        id: this.clienteModel.id
      }
    }
    this.item.idUserEmp = this.profile.idEmpleado;
    this.isLoading = true;
    this.anexoPs.edit(this.item).subscribe(
      (data) => {
        this.messageService.add({ severity: 'success', summary: 'Anexo editado correctamente.', detail: '' });
        this.isLoading = false;
        this.hideDialog.emit(false);
        this.recargarDetalle.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.messageService.add({ key: "msgError", severity: 'error', summary: 'No se pudo editar el cliente del anexo.', detail: '' });
      }
    )
  }

}
